import React, { useEffect, useRef, useState } from "react";
import { useGlobal } from "reactn";
import styled from "styled-components";
import axios from "axios";
import onClickOutside from "react-onclickoutside";
import apiRoot from "../apiRoot";

const TagBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const AddTagBox = styled.div`
  background: #413448;
  width: 250px;
  position: absolute;
  top: 30px;
  left: 0;
  display: inline-block;
  z-index: 10;
  border-radius: 6px;
  min-height: 200px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
`;

const AddTagInput = styled.input`
  background: rgba(0, 0, 0, 0.15);
  font-size: 12px;
  padding: 8px 12px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.8);
  border: none;
  outline: none;
  width: 100%;
  box-sizing: border-box;
  ::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
`;

const AddableTag = styled.div`
  width: 100%;
  padding: 8px 12px;
  transition: background 0.2s;
  font-weight: 500;
  font-size: 12px;
  box-sizing: border-box;
  cursor: pointer;
  :hover {
    background: rgba(255, 255, 255, 0.05);
  }
`;

const Info = styled.div`
  padding: 12px;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.8);
  font-weight: 500;
`;

const Tag = styled.div`
  background: rgba(255, 255, 255, 0.1);
  border-radius: 100px;
  padding: 3px 10px;
  display: inline-flex;
  margin-right: 10px;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.9);
  font-weight: 500;
  align-items: center;
  svg {
    padding-left: 5px;
  }
`;

const AddButtonLocation = styled.div`
  position: relative;
  display: inline-flex;
`;

const AddButton = styled.div`
  display: inline-flex;
  padding: 3px 11px 3px 6px;
  border-radius: 100px;
  background: rgba(255, 255, 255, 0.1);
  font-size: 12px;
  color: rgba(255, 255, 255, 0.9);
  align-items: center;
  font-weight: 500;
  cursor: pointer;
  svg {
    padding-right: 5px;
  }
`;

const CreateDescription = styled.div`
  font-size: 10px;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  color: rgba(255, 255, 255, 0.7);
  svg {
    margin-right: 5px;
  }
`;

const Remove = styled.span`
  cursor: pointer;
  display: inline-flex;
`;

const Scrollable = styled.div`
  max-height: 200px;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 12px;
    height: 18px;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }
  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    height: 12px;
    border: 8px solid transparent;
    border-width: 4px;
    border-radius: 40px;
    background-clip: padding-box;
    background-color: rgba(255, 255, 255, 0.2);
  }
`;

const TagAdd = function (props) {
  let [tagSearch, setTagSearch] = useState("");

  TagAdd.handleClickOutside = props.close;

  const filterTags = props.tags
    .filter((i) => props.addedTags.filter((x) => x.id === i.id).length === 0)
    .filter((i) => i.tag.toLowerCase().includes(tagSearch.toLowerCase()));

  const i = useRef();

  useEffect(() => {
    i.current?.focus();
  }, [i]);

  return (
    <AddTagBox>
      <AddTagInput
        ref={i}
        value={tagSearch}
        onChange={(e) => setTagSearch(e.target.value.substr(0, 50))}
        placeholder="Search or create a tag..."
      />
      <Scrollable>
        {!tagSearch && filterTags.length === 0 && (
          <Info>
            You don't have any tags to be added! Start typing above to create a
            new tag.
          </Info>
        )}
        {tagSearch && (
          <AddableTag
            onClick={() => {
              props.createTag({ tag: tagSearch });
              setTagSearch("");
              props.close();
            }}
          >
            <CreateDescription>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="2 2 20 20"
                width="11"
                height="11"
              >
                <path
                  fill="rgba(255, 255, 255, 0.7)"
                  d="M13 11h4a1 1 0 0 1 0 2h-4v4a1 1 0 0 1-2 0v-4H7a1 1 0 0 1 0-2h4V7a1 1 0 0 1 2 0v4z"
                />
              </svg>{" "}
              CREATE NEW TAG
            </CreateDescription>
            {tagSearch}
          </AddableTag>
        )}
        {filterTags.map((i) => (
          <AddableTag
            onClick={() => {
              props.createTag({ id: i.id, tag: i.tag });
              setTagSearch("");
              props.close();
            }}
          >
            {i.tag}
          </AddableTag>
        ))}
      </Scrollable>
    </AddTagBox>
  );
};

const clickOutsideConfig = {
  handleClickOutside: () => TagAdd.handleClickOutside,
};

const TagAddWrapped = onClickOutside(TagAdd, clickOutsideConfig);

const TagArray = (props) => {
  const [myTags, setMyTags] = useGlobal("myTags");
  let [authState] = useGlobal("authState");
  let [addingTag, setAddingTag] = useState(false);

  async function getTags() {
    if (!authState?.publishingAccount?.id) return;
    const response = await axios.post(
      apiRoot,
      {
        query: `
          query getTags($id: Int!) {
            publishingAccount(id: $id) {
              tags {
                id
                tag
              }
            }
          }`,
        variables: { id: authState.publishingAccount.id },
      },
      { withCredentials: true }
    );
    setMyTags(response.data.data?.publishingAccount?.tags);
  }

  useEffect(() => {
    getTags();
    // eslint-disable-next-line
  }, [authState?.publishingAccount?.id]);

  return (
    <TagBox>
      {props.tags?.map((tag) => (
        <Tag>
          {tag.tag}{" "}
          {props.removeTag && (
            <Remove onClick={() => props.removeTag(tag)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="2 2 20 20"
                width="16"
                height="16"
              >
                <path
                  fill="rgba(255,255,255,0.8)"
                  fill-rule="evenodd"
                  d="M15.78 14.36a1 1 0 0 1-1.42 1.42l-2.82-2.83-2.83 2.83a1 1 0 1 1-1.42-1.42l2.83-2.82L7.3 8.7a1 1 0 0 1 1.42-1.42l2.83 2.83 2.82-2.83a1 1 0 0 1 1.42 1.42l-2.83 2.83 2.83 2.82z"
                />
              </svg>
            </Remove>
          )}
        </Tag>
      ))}
      {props.addTag && (
        <AddButtonLocation>
          <AddButton
            className="ignore-react-onclickoutside"
            onClick={() => setAddingTag(!addingTag)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="2 2 20 20"
              class="icon-add-circle"
              width="16"
              height="16"
            >
              <path
                fill="rgba(255,255,255,0.8)"
                d="M13 11h4a1 1 0 0 1 0 2h-4v4a1 1 0 0 1-2 0v-4H7a1 1 0 0 1 0-2h4V7a1 1 0 0 1 2 0v4z"
              />
            </svg>{" "}
            Add tag
          </AddButton>
          {addingTag && (
            <TagAddWrapped
              tags={myTags || []}
              addedTags={props.tags}
              createTag={props.addTag}
              close={() => setAddingTag(false)}
            />
          )}
        </AddButtonLocation>
      )}
    </TagBox>
  );
};

export default TagArray;
