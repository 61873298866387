import React, { useState } from "react";
import { useGlobal } from "reactn";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import apiRoot from "../../apiRoot";
import Tooltip from "../../components/Tooltip";
import SocietySelect from "../../components/SocietySelect";
import ReviewCreateWriter from "./ReviewCreateWriter";
import getAuthState from "../../getAuthState";
import { Helmet } from "react-helmet";

const Title = styled.h1`
  font-size: 28px;
  margin-bottom: 10px;
  @media screen and (max-width: 600px) {
    font-size: 20px;
  }
`;

const Subtitle = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.6);
  a {
    color: inherit;
  }
  @media screen and (max-width: 400px) {
    font-size: 12px;
  }
`;

const InputGroup = styled.div`
  flex: 1;
  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

const Label = styled.label`
  font-weight: 500;
  color: rgba(255, 255, 255, 0.6);
  font-size: 12px;
`;

const Divider = styled.div`
  margin-right: 20px;
  @media screen and (max-width: 600px) {
    margin-bottom: 10px;
  }
`;

const Input = styled.input`
  background: transparent;
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-radius: 2px;
  width: calc(100% - 24px);
  padding: 6px 10px;
  outline: none;
  transition: border-color 0.2s;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;
  display: block;
  :focus {
    border-color: rgba(255, 255, 255, 0.3);
  }
  ::placeholder {
    color: rgba(255, 255, 255, 0.4);
  }
`;

const InputRow = styled.div`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  margin-bottom: 10px;
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

const Button = styled.button`
  padding: 6px 25px;
  background: linear-gradient(135deg, hsl(160, 95%, 35%), hsl(220, 92%, 45%));
  color: white;
  font-weight: 600;
  outline: none;
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  transition: filter 0.2s;
  :hover {
    filter: brightness(110%);
  }
  svg {
    margin-left: 10px;
    margin-right: -5px;
  }
`;

const ErrorMsg = styled.div`
  color: hsl(5, 85%, 75%);
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  svg {
    margin-right: 10px;
    min-width: 24px;
  }
  @media screen and (max-width: 600px) {
    margin-bottom: 10px;
  }
`;

const filterName = (name) => {
  const charArray =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789 !#$%&'()+-./?@`".split(
      ""
    );
  return name
    .split("")
    .filter((i) => charArray.includes(i))
    .join("");
};

const filterIpi = (ipi) => {
  const charArray = "0123456789".split("");
  return ipi
    .split("")
    .filter((i) => charArray.includes(i))
    .join("");
};

const CreateSongwriter = () => {
  let [authState, setAuthState] = useGlobal("authState");
  let [, setSocieties] = useGlobal("societies");
  let [firstName, setFirstName] = useState("");
  let [middleName, setMiddleName] = useState("");
  let [lastName, setLastName] = useState("");
  let [society, setSociety] = useState("");
  let [ipi, setIpi] = useState("");
  let [error, setError] = useState("");
  let [match, setMatch] = useState(null);
  let [warn, setWarn] = useState(false);
  let [working, setWorking] = useState(false);
  let [done, setDone] = useState(false);

  const preCreateWriter = async (e) => {
    e.preventDefault();
    if (!lastName) return setError("Last name is required!");
    if ((society && !ipi) || (ipi && !society))
      return setError(
        "If an IPI or society is specified, both must be specified"
      );
    if (ipi && ipi.length < 8)
      return setError("IPI must be at least 8 characters");
    if (middleName && middleName.length + firstName.length > 29)
      return setError(
        "The combined length of the writer's middle and first name must be 30 characters or less"
      );
    if (!ipi && !warn) {
      setError(
        "You should affiliate with a PRO - without this info, you can't collect half your performance royalties. If you really want to continue without a PRO affiliation, just click the button again."
      );
      return setWarn(true);
    }
    let writerInfo = { last_name: lastName };
    if (firstName) writerInfo.first_name = firstName;
    if (middleName) writerInfo.middle_name = middleName;
    if (ipi) writerInfo.ipi = ipi;
    if (society) writerInfo.society = society;
    setWorking(true);
    const response = await axios.post(
      apiRoot,
      {
        query: `mutation preCreateSongwriter($writer: SongwriterInput!) {
          preCreateSongwriter(writer: $writer) {
            match_type
            matches {
              id
              ipi
              society
              first_name
              middle_name
              last_name
              exampleWorks
            }
          }
        }`,
        variables: {
          writer: writerInfo,
        },
      },
      { withCredentials: true }
    );
    setWorking(false);
    if (response.data.errors) return setError(response.data.errors[0].message);
    setError("");
    if (
      response.data.data.preCreateSongwriter &&
      response.data.data.preCreateSongwriter.match_type !== "auto"
    ) {
      setMatch(response.data.data.preCreateSongwriter);
    } else if (response.data.data.preCreateSongwriter?.match_type === "auto") {
      submitMatch(response.data.data.preCreateSongwriter.matches[0]);
    } else {
      await createWriter();
    }
  };

  const submitMatch = async (match) => {
    if (ipi && society && !match.ipi && !match.society) {
      await axios.post(
        apiRoot,
        {
          query: `mutation addSocietyInfo($writerId: Int!, $society: PerformanceSociety!, $ipi: IPI!, $middleName: Name) {
            addSocietyInfo(writerId: $writerId, society: $society, ipi: $ipi, middleName: $middleName)
          }`,
          variables: {
            writerId: match.id,
            society,
            ipi,
            middleName: middleName || null,
          },
        },
        { withCredentials: true }
      );
    }
    const response = await axios.post(
      apiRoot,
      {
        query: `mutation matchSongwriter($id: Int!, $pubAccId: Int!) {
          matchInitialSongwriter(writerId: $id, publishingAccount: $pubAccId)
        }`,
        variables: {
          id: match.id,
          pubAccId: authState.publishingAccount.id,
        },
      },
      { withCredentials: true }
    );
    if (response.data.errors) return setError(response.data.errors[0].message);
    await getAuthState(setAuthState, setSocieties);
    setDone(true);
  };

  const createWriter = async () => {
    let writerInfo = { last_name: lastName };
    if (firstName) writerInfo.first_name = firstName;
    if (middleName) writerInfo.middle_name = middleName;
    if (ipi) writerInfo.ipi = ipi;
    if (society) writerInfo.society = society;
    const response = await axios.post(
      apiRoot,
      {
        query: `mutation createSongwriter($writer: SongwriterInput!, $pubAccId: Int!) {
          createInitialSongwriter(writer: $writer, publishingAccount: $pubAccId) {
            id
            ipi
            society
            first_name
            middle_name
            last_name
          }
        }`,
        variables: {
          writer: writerInfo,
          pubAccId: authState.publishingAccount.id,
        },
      },
      { withCredentials: true }
    );
    if (response.data.errors) return setError(response.data.errors[0].message);
    await getAuthState(setAuthState, setSocieties);
    setDone(true);
  };

  if (authState && !authState.loading && !authState.authenticated)
    return <Redirect to="/login" />;

  if (
    authState &&
    authState.publishingAccount &&
    authState.publishingAccount.setupStatus !== "needs_songwriter"
  )
    return <Redirect to="/dashboard" />;

  if (match)
    return (
      <ReviewCreateWriter
        match={match}
        createWriter={createWriter}
        submitMatch={submitMatch}
        back={() => setMatch(null)}
        metadata={{ firstName, middleName, lastName, ipi, society }}
        error={error}
      />
    );

  return (
    <div>
      <Helmet>
        <title>Add Songwriter Details | Music Inc. Client Portal</title>
      </Helmet>
      {done && <Redirect to="/dashboard" />}
      <Title>Add your songwriter details</Title>
      <Subtitle>
        To collect your writer's share of performance royalties, you need to be
        affiliated with a performing rights organization (PRO) like{" "}
        <a href="https://bmi.com">BMI</a> (our recommendation - it's free!),{" "}
        <a href="https://ascap.com">ASCAP</a>, or a local organization in your
        country. They'll give you an IPI number, which ensures your royalties
        are matched to you correctly. Add your PRO information below (your name
        is required, but you can click "add details" to move on without
        affiliating). If you aren't already affiliated with a PRO, do that and
        wait for them to assign you an IPI before moving forward.
      </Subtitle>
      <InputRow>
        <InputGroup>
          <Label>First name</Label>
          <Input
            value={firstName}
            onChange={(e) =>
              setFirstName(filterName(e.target.value).substr(0, 45))
            }
          />
        </InputGroup>
        <Divider />
        <InputGroup>
          <Label>Middle name</Label>
          <Input
            value={middleName}
            onChange={(e) =>
              setMiddleName(filterName(e.target.value).substr(0, 30))
            }
            placeholder="(optional)"
          />
        </InputGroup>
        <Divider />
        <InputGroup>
          <Label>Last name</Label>
          <Input
            value={lastName}
            onChange={(e) =>
              setLastName(filterName(e.target.value).substr(0, 30))
            }
          />
        </InputGroup>
      </InputRow>
      <InputRow>
        <InputGroup>
          <Label>
            PRO/CMO affiliation
            <Tooltip>
              If you're affiliated with a performing rights organization, select
              the relevant PRO to ensure your shares are matched properly.
            </Tooltip>
          </Label>
          <SocietySelect
            background="#33293a"
            value={society}
            onChange={(society) => setSociety(society)}
          />
        </InputGroup>
        <Divider />
        <InputGroup>
          <Label>
            IPI name number
            <Tooltip>
              The Interested Party Identifier is a number used to uniquely
              identify songwriters and publishers. If you're affiliated with a
              PRO, you should have one of these - ask your PRO or check your
              account if you're not sure what it is. Some societies assign an
              internal identifier that isn't an IPI, so make sure you're getting
              your IPI and not that society identifier.
            </Tooltip>
          </Label>
          <Input
            value={ipi}
            onChange={(e) => setIpi(filterIpi(e.target.value).substr(0, 11))}
          />
        </InputGroup>
      </InputRow>
      <ButtonContainer>
        {error ? (
          <ErrorMsg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path
                fill="hsl(5, 100%, 70%)"
                d="M12 2a10 10 0 1 1 0 20 10 10 0 0 1 0-20zm0 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16zm0 9a1 1 0 0 1-1-1V8a1 1 0 0 1 2 0v4a1 1 0 0 1-1 1zm0 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
              />
            </svg>
            {error}
          </ErrorMsg>
        ) : (
          <div />
        )}
        <Button onClick={preCreateWriter} disabled={working}>
          Add details
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            height="24"
            width="24"
          >
            <path
              fill="white"
              d="M14.59 13H7a1 1 0 0 1 0-2h7.59l-2.3-2.3a1 1 0 1 1 1.42-1.4l4 4a1 1 0 0 1 0 1.4l-4 4a1 1 0 0 1-1.42-1.4l2.3-2.3z"
            />
          </svg>
        </Button>
      </ButtonContainer>
    </div>
  );
};

export default CreateSongwriter;
