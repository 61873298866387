import React, { useState } from "react";
import { useGlobal } from "reactn";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import apiRoot from "../../apiRoot";
import Checkbox from "../../components/Checkbox";
import ContractText from "./Contract";
import getAuthState from "../../getAuthState";
import { Helmet } from "react-helmet";

const Title = styled.h1`
  font-size: 28px;
  margin-bottom: 10px;
  @media screen and (max-width: 600px) {
    font-size: 20px;
  }
`;

const Subtitle = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.6);
  @media screen and (max-width: 600px) {
    font-size: 12px;
  }
`;

const InputGroup = styled.div`
  flex: 1;
`;

const Label = styled.label`
  font-weight: 500;
  color: rgba(255, 255, 255, 0.6);
  font-size: 12px;
`;

const Input = styled.input`
  background: transparent;
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-radius: 2px;
  width: calc(100% - 24px);
  padding: 6px 10px;
  outline: none;
  transition: border-color 0.2s;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;
  display: block;
  :focus {
    border-color: rgba(255, 255, 255, 0.3);
  }
  ::placeholder {
    color: rgba(255, 255, 255, 0.4);
  }
`;

const InputRow = styled.div`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  margin-bottom: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const Button = styled.button`
  padding: 6px 25px;
  background: linear-gradient(135deg, hsl(160, 95%, 35%), hsl(220, 92%, 45%));
  color: white;
  font-weight: 600;
  outline: none;
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  transition: filter 0.2s;
  :hover {
    filter: brightness(110%);
  }
  svg {
    margin-left: 10px;
    margin-right: -5px;
  }
  &:disabled {
    background: rgba(255, 255, 255, 0.2);
    color: rgba(255, 255, 255, 0.5);
  }
`;

const ErrorMsg = styled.div`
  color: hsl(5, 85%, 75%);
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  svg {
    margin-right: 10px;
    min-width: 24px;
  }
`;

const VerticalDivider = styled.div`
  margin-bottom: 15px;
`;

const Segment = styled.div`
  margin-bottom: 10px;
  display: flex;
`;

const Content = styled.div`
  > h3 {
    margin-top: 0;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    color: rgba(255, 255, 255, 0.8);
    font-size: 18px;
    font-weight: 600;
    svg {
      margin-right: 15px;
    }
    @media screen and (max-width: 600px) {
      font-size: 16px;
    }
  }
  > p {
    font-size: 12px;
    margin-left: 55px;
    color: rgba(255, 255, 255, 0.6);
    margin-top: 0;
    @media screen and (max-width: 600px) {
      margin-left: 0;
    }
  }
`;

const Icon = styled.svg`
  width: 30px;
  height: 30px;
  padding: 5px;
  border-radius: 100px;
  background: rgba(255, 255, 255, 0.1);
  filter: drop-shadow(0 2px 1px rgba(0, 0, 0, 0.5));
  min-width: 30px;
`;

const Contract = styled.div`
  max-height: 600px;
  overflow-y: auto;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
  margin-top: 30px;
  padding-right: 10px;
  margin-bottom: 30px;
  p {
    margin-top: 0;
  }
  ::-webkit-scrollbar {
    width: 14px;
    height: 18px;
  }
  ::-webkit-scrollbar-thumb {
    min-height: 30px;
    border: 4px solid transparent;
    background-clip: padding-box;
    -webkit-border-radius: 7px;
    background-color: rgba(255, 255, 255, 0.2);
  }
  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }
  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
`;

const SignPublishingContract = (props) => {
  let [authState, setAuthState] = useGlobal("authState");
  let [, setSocieties] = useGlobal("societies");
  let [name, setName] = useState("");
  let [sign, setSign] = useState(false);
  let [minor, setMinor] = useState(false);
  let [parent, setParent] = useState("");
  let [working, setWorking] = useState(false);
  let [error, setError] = useState("");
  let [done, setDone] = useState(false);

  let disabled = !sign || (minor && !parent) || working;
  if (props.code && !name) disabled = true;

  const submit = async (e) => {
    e.preventDefault();
    setWorking(true);
    let send = {
      query: `mutation activatePublishingInvite${
        minor ? "($parent: GenericString)" : ""
      } {
        activatePublishingInvite${minor ? "(parentSignature: $parent)" : ""}
      }`,
    };
    if (minor) send.variables = { parent };
    if (props.code) {
      send = {
        query: `mutation activatePublishingInvite($name: GenericString,$code: GenericString${
          minor ? ",$parent: GenericString)" : ")"
        } {
        activatePublishingInvite${
          minor
            ? "(name:$name,code:$code,parentSignature: $parent)"
            : "(name: $name,code:$code)"
        }
      }`,
      };
      send.variables = minor
        ? { name, code: props.code, parent }
        : { name, code: props.code };
    }
    const response = await axios.post(apiRoot, send, { withCredentials: true });
    if (response.data.errors) return setError(response.data.errors[0].message);
    const ast = await getAuthState(setAuthState, setSocieties);
    if (props.type === "add_songwriter" || props.type === "add_publisher") {
      localStorage.setItem(
        "active_publishing_accounts",
        JSON.stringify({
          ...JSON.parse(localStorage.getItem("active_publishing_accounts")),
          [authState.user.id]:
            ast.publishingAccounts[ast.publishingAccounts.length - 1].id,
        })
      );
      await getAuthState(setAuthState, setSocieties);
    }
    setDone(true);
    setWorking(false);
  };

  if (authState && !authState.loading && !authState.authenticated)
    return <Redirect to="/login" />;

  if (
    authState &&
    authState.setupStatus !== "needs_publishing_account" &&
    props.type !== "add_songwriter" &&
    props.type !== "add_publisher" &&
    !working
  )
    return <Redirect to="/dashboard" />;

  if (done && props.type !== "add_publisher" && props.type !== "publisher")
    return <Redirect to="/setup/songwriter" />;
  else if (done) return <Redirect to="/dashboard" />;

  return (
    <div>
      <Helmet>
        <title>
          Accept Administration Agreement | Music Inc. Client Portal
        </title>
      </Helmet>
      <Title>Accept publishing administration agreement</Title>
      {props.code && (
        <Subtitle>
          You're adding a new publishing account to an existing Music Inc. user
          account. Set a name for your publishing account below. This can be
          whatever you want - it's only used in our interface, and has no effect
          on your registrations!
        </Subtitle>
      )}
      {props.code && (
        <InputRow>
          <InputGroup>
            <Label>Publishing account name</Label>
            <Input value={name} onChange={(e) => setName(e.target.value)} />
          </InputGroup>
        </InputRow>
      )}
      <Subtitle>
        You'll need to sign the publishing administration contract below before
        getting started registering your works to ensure we have all the rights
        necessary to register and collect on your compositions globally. To
        review, here are the basic points you should be aware of in the
        contract, but we advise reading it in its entirety.
      </Subtitle>
      <Segment>
        <Content>
          <h3>
            <Icon
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              viewBox="0 0 100 100"
            >
              <g transform="translate(1 0)">
                <path
                  fill="#fff"
                  d="M62.728,63.272c-3.352,3.105-7.716,4.816-12.289,4.816c-9.974,0-18.088-8.115-18.088-18.088  s8.114-18.088,18.088-18.088c4.568,0,8.929,1.708,12.28,4.807c0.812,0.75,2.077,0.7,2.827-0.11s0.701-2.076-0.11-2.826  c-4.093-3.786-9.418-5.871-14.997-5.871C38.259,27.912,28.35,37.82,28.35,50s9.909,22.088,22.088,22.088  c5.584,0,10.914-2.088,15.008-5.881c0.81-0.75,0.858-2.016,0.108-2.826C64.803,62.57,63.538,62.522,62.728,63.272z"
                />
                <path
                  fill="#fff"
                  d="M50,8C27.234,8,8,27.233,8,50s19.234,42,42,42s42-19.233,42-42S72.766,8,50,8z M50,88  c-20.598,0-38-17.402-38-38s17.402-38,38-38s38,17.402,38,38S70.598,88,50,88z"
                />
              </g>
            </Icon>
            You keep your copyrights, and grant us only administrative rights
          </h3>
          <p>
            As a publishing administrator, we don't take any permanent ownership
            over your work. You retain complete control over what can be done
            with your compositions, and remain the sole copyright owner. We take
            only the right to collect public performance, mechanical, and
            limited other types of royalties from uses that you have already
            allowed or that are allowed by statute. We'll collect on these uses
            of your work through our global partners and pay out the royalties
            we receive. You're also not required to register your entire catalog
            - you can use Music Inc. for only some of your compositions, and
            register others with another publisher or administrator.
          </p>
        </Content>
      </Segment>
      <Segment>
        <Content>
          <h3>
            <Icon
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              viewBox="5 5 40 40"
            >
              <path
                fill="white"
                d="M25.1,39.3c0.7,0,1.2-0.6,1.2-1.2v-2.9c3.2-0.4,5.5-2.4,5.5-5.1c0-4-3.5-5.2-6.2-6.2c-2.9-1-4.3-1.7-4.3-3.4  c0-0.9,0.3-1.7,1-2.2c0.7-0.5,1.7-0.9,2.8-0.9c0,0,0,0,0,0c1.5,0,3.4,0.5,3.8,2.7c0.1,0.7,0.8,1.1,1.5,1c0.7-0.1,1.1-0.8,1-1.5  c-0.5-2.5-2.4-4.2-5-4.6V12c0-0.7-0.6-1.2-1.2-1.2s-1.2,0.6-1.2,1.2v2.9c-1.2,0.2-2.3,0.6-3.1,1.3c-1.3,1-1.9,2.5-1.9,4.2  c0,3.6,3.3,4.8,5.9,5.8c2.8,1,4.6,1.7,4.6,3.8c0,1.6-1.8,2.7-4.2,2.7c-2.2,0-3.9-1.1-4.4-3c-0.2-0.7-0.9-1.1-1.5-0.9  c-0.7,0.2-1.1,0.9-0.9,1.5c0.7,2.6,2.8,4.4,5.5,4.8V38C23.8,38.7,24.4,39.3,25.1,39.3z"
              />
            </Icon>
            We take a {props.code ? props.fee * 100 : authState?.adminFee * 100}
            % administration fee, and nothing else
          </h3>
          <p>
            We calculate all of our royalties at the point we receive them -
            after collection societies take their fee, but before any costs we
            incur in getting your royalties to you. Our costs come out of the
            fee we're taking, not out of your pocket.
          </p>
        </Content>
      </Segment>
      <Segment>
        <Content>
          <h3>
            <Icon
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              viewBox="0 0 48 48"
            >
              <g transform="translate(0.5 0)">
                <path
                  fill="white"
                  d="M24,45.1875A21.18726,21.18726,0,1,1,45.187,24,21.21134,21.21134,0,0,1,24,45.1875ZM24,4.813A19.18726,19.18726,0,1,0,43.187,24,19.209,19.209,0,0,0,24,4.813Z"
                />
                <path
                  fill="white"
                  d="M29.76758,30.64746a.99753.99753,0,0,1-.69971-.28516l-5.76758-5.64794A1,1,0,0,1,23,24V12.58447a1,1,0,1,1,2,0V23.57959l5.46729,5.353a1.00048,1.00048,0,0,1-.69971,1.71484Z"
                />
              </g>
            </Icon>
            6 months, then terminate anytime
          </h3>
          <p>
            Collecting publishing royalties is a slow process, so there's an
            initial 6-month period after you sign this contract during which we
            retain the exclusive right to register your songs and collect
            royalties. After that, this contract continues until you terminate
            it, which you can do at any time if you want to move your catalog to
            another publisher or administrator.
          </p>
        </Content>
      </Segment>
      <Contract>
        <ContractText
          fee={props.code ? props.fee * 100 : authState?.adminFee * 100}
        />
      </Contract>
      <Checkbox active={sign} setActive={setSign}>
        I agree to the terms stated above
      </Checkbox>
      <VerticalDivider />
      <Checkbox active={minor} setActive={setMinor}>
        I am under 18 years of age, or the age of majority in my jurisdiction if
        different
      </Checkbox>
      {minor && <VerticalDivider />}
      {minor && (
        <InputRow>
          <InputGroup>
            <Label>Parent/guardian signature</Label>
            <Input value={parent} onChange={(e) => setParent(e.target.value)} />
          </InputGroup>
        </InputRow>
      )}
      {error && (
        <ErrorMsg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path
              fill="hsl(5, 100%, 70%)"
              d="M12 2a10 10 0 1 1 0 20 10 10 0 0 1 0-20zm0 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16zm0 9a1 1 0 0 1-1-1V8a1 1 0 0 1 2 0v4a1 1 0 0 1-1 1zm0 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
            />
          </svg>
          {error}
        </ErrorMsg>
      )}
      <ButtonContainer>
        <Button disabled={disabled} onClick={submit}>
          Sign contract
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            height="24"
            width="24"
          >
            <path
              fill={disabled ? "rgba(255,255,255,0.5)" : "white"}
              d="M14.59 13H7a1 1 0 0 1 0-2h7.59l-2.3-2.3a1 1 0 1 1 1.42-1.4l4 4a1 1 0 0 1 0 1.4l-4 4a1 1 0 0 1-1.42-1.4l2.3-2.3z"
            />
          </svg>
        </Button>
      </ButtonContainer>
    </div>
  );
};

export default SignPublishingContract;
