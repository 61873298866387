import React, { useEffect } from "react";
import { useGlobal } from "reactn";
import { Route as BaseRoute, Switch, withRouter } from "react-router-dom";
import styled from "styled-components";

import Header from "./components/Header";
import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import Dashboard from "./pages/dashboard/Dashboard";
import Publishing from "./pages/dashboard/Publishing";
import Settings from "./pages/settings/Settings";
import getAuthState from "./getAuthState";
import ConfirmOrChangeEmail from "./pages/ConfirmOrChangeEmail";
import StartResetPassword from "./pages/login/ResetPassword";
import ResetPassword from "./pages/ResetPassword";
import ViewWork from "./pages/work/ViewWork";
import NotFound from "./pages/NotFound";
import CreateComposition from "./pages/create/CreateComposition";
import ViewSubmissionRequest from "./pages/work/ViewSubmissionRequest";
import UserSetup from "./pages/userSetup/UserSetup";
import Royalties from "./pages/royalties/Royalties";
import Unsubscribe from "./pages/Unsubscribe";
import Writer from "./pages/writer/Writer";

class ScrollToTopRoute extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { component: Component, ...rest } = this.props;

    return <BaseRoute {...rest} render={(props) => <Component {...props} />} />;
  }
}

const Route = withRouter(ScrollToTopRoute);

const AppFlex = styled.div`
  display: flex;
  flex-direction: column;
`;

const MainFlex = styled.main`
  padding-top: 80px;
  flex: 1;
  display: flex;
  min-height: calc(100vh - 80px);
`;

const cce = (op) => (props) =>
  <ConfirmOrChangeEmail operation={op} {...props} />;

const us = (tab) => (props) => <UserSetup tab={tab} {...props} />;

function App() {
  // Determine auth status on app load
  // Set user and publishing account as a global variable
  // authState { loading (boolean), authenticated (boolean), setupStatus, user, publishingAccounts, publishingAccount }
  let [, setAuthState] = useGlobal("authState");
  let [, setSocieties] = useGlobal("societies");
  useEffect(() => {
    getAuthState(setAuthState, setSocieties);
    // eslint-disable-next-line
  }, []);

  return (
    <AppFlex>
      <Header />
      <MainFlex>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/login/:redirect" component={Login} />
          <Route path="/login" component={Login} />
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/publishing/:tab" component={Publishing} />
          <Route path="/publishing" component={Publishing} />
          <Route path="/settings/publishing/:accountId" component={Settings} />
          <Route path="/settings" component={Settings} />
          <Route path="/royalties" component={Royalties} />
          <Route path="/confirmEmail/:token">{cce("confirmEmail")}</Route>
          <Route path="/changeEmail/:token">{cce("changeEmail")}</Route>
          <Route path="/resetPassword/:token" component={ResetPassword} />
          <Route path="/resetPassword" component={StartResetPassword} />
          <Route path="/composition/:id" component={ViewWork} />
          <Route path="/writer/:id" component={Writer} />
          <Route path="/request/:id" component={ViewSubmissionRequest} />
          <Route path="/register" component={CreateComposition} />
          <Route path="/activateInvite/:code">{us("activateInvite")}</Route>
          <Route path="/confirmEmail">{us("confirmEmail")}</Route>
          <Route path="/setup/contract/:code">{us("signContract")}</Route>
          <Route path="/setup/contract">{us("signContract")}</Route>
          <Route path="/setup/label/:code">
            {us("finalizeLabelStatement")}
          </Route>
          <Route path="/setup/label">{us("finalizeLabelStatement")}</Route>
          <Route path="/setup/publishinguser/:code">
            {us("finalizePublishingUser")}
          </Route>
          <Route path="/setup/publishinguser">
            {us("finalizePublishingUser")}
          </Route>
          <Route path="/setup/songwriter">{us("createSongwriter")}</Route>
          <Route path="/unsubscribe/:token">
            <Unsubscribe />
          </Route>
          <Route component={NotFound} />
        </Switch>
      </MainFlex>
    </AppFlex>
  );
}

export default App;
