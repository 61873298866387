import React, { useState, useEffect } from "react";
import { Redirect, Link } from "react-router-dom";
import { useGlobal } from "reactn";
import styled from "styled-components";
import axios from "axios";
import apiRoot from "../../apiRoot";

import Compositions from "./Compositions";
import Songwriters from "./Songwriters";
import Requests from "./Requests";

const MainComponent = styled.div`
  background: rgba(255, 255, 255, 0.05);
  max-width: 1200px;
  width: 100%;
  padding: 40px;
  box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  min-height: calc(100vh - 160px);
  @media screen and (max-width: 400px) {
    padding: 20px;
  }
`;

const HeaderComponent = styled.div`
  padding: 40px;
  width: 100%;
  margin-top: -40px;
  margin-left: -40px;
  background: linear-gradient(135deg, hsl(214, 93%, 47%), hsl(271, 95%, 41%));
  color: white;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  padding-bottom: 0px;
  @media screen and (max-width: 400px) {
    padding: 20px;
    padding-bottom: 0;
    margin-top: -20px;
    margin-left: -20px;
  }
`;

const MainTitle = styled.h1`
  margin-top: 15px;
  @media screen and (max-width: 800px) {
    font-size: 24px;
  }
`;

const Subtitle = styled.p`
  font-weight: 500;
  font-size: 14px;
  @media screen and (max-width: 400px) {
    font-size: 12px;
  }
`;

const Tabs = styled.div`
  margin-top: 20px;
  margin-left: -15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 800px) {
    font-size: 24px;
    flex-direction: column-reverse;
    margin-left: 0;
  }
`;

const Tab = styled(Link)`
  color: ${(props) => (props.active ? "white" : "rgba(255,255,255,0.7);")};
  border-bottom: 2px solid
    ${(props) => (props.active ? "white" : "transparent")};
  text-decoration: none;
  padding: 15px;
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  margin-right: 15px;
  transition: color 0.2s, border-bottom 0.2s;
  small {
    font-weight: 500;
    margin-left: 10px;
    font-size: 10px;
    color: ${(props) =>
      props.active ? "rgba(255, 255, 255, 0.9)" : "rgba(255, 255, 255, 0.7)"};
    background: ${(props) =>
      props.active ? "rgba(255, 255, 255, 0.3)" : "rgba(255, 255, 255, 0.2)"};
    border-radius: 100px;
    padding: 1px 10px 0 10px;
    transition: color 0.2s, background 0.2s;
  }
  @media screen and (max-width: 400px) {
    padding: 10px;
    margin-right: 0;
    font-size: 12px;
  }
`;

const Button = styled(Link)`
  background: rgba(255, 255, 255, 0.3);
  font-weight: 600;
  padding: 6px 16px;
  font-size: 14px;
  color: white;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  text-decoration: none;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  transition: background 0.2s;
  :hover {
    background: rgba(255, 255, 255, 0.35);
  }
  svg {
    margin-right: 10px;
    margin-top: -1px;
    margin-left: -2px;
  }
  @media screen and (max-width: 400px) {
    font-size: 12px;
  }
`;

const Dashboard = (props) => {
  const [authState] = useGlobal("authState");
  const [compositionCount, setCompositionCount] = useState();
  const [writerCount, setWriterCount] = useState();
  const [submissionRequestCount, setSubmissionRequestCount] = useState();
  let preReturn;
  if (authState && !authState.loading && !authState.authenticated)
    preReturn = <Redirect to="/login" />;
  if (
    authState &&
    !authState.loading &&
    authState.authenticated &&
    (authState.setupStatus !== "done" ||
      (authState.publishingAccount &&
        authState.publishingAccount.setupStatus !== "done"))
  ) {
    if (authState.setupStatus === "needs_email_confirmation")
      preReturn = <Redirect to="/confirmEmail" />;
    if (authState.setupStatus === "needs_publishing_account")
      preReturn = <Redirect to="/setup/contract" />;
    if (
      authState.setupStatus === "needs_songwriter" ||
      authState.publishingAccount?.setupStatus === "needs_songwriter"
    )
      preReturn = <Redirect to="/setup/songwriter" />;
  } else if (
    authState &&
    !authState.loading &&
    authState.authenticated &&
    authState.publishingAccounts.length === 0
  )
    preReturn = <Redirect to="/settings" />;

  // Get counts
  const pubAccId = authState?.publishingAccount?.id;
  useEffect(() => {
    if (preReturn) return;
    if (!authState?.publishingAccount) return;
    async function getCounts() {
      const response = await axios.post(
        apiRoot,
        {
          query: `
            query getCounts($id: Int!) {
              publishingAccount(id: $id) {
                writers {
                  count
                }
                works {
                  count
                }
                submissionRequests {
                  count
                }
              }
            }`,
          variables: { id: authState.publishingAccount.id },
        },
        { withCredentials: true }
      );
      if (
        response?.data?.data?.publishingAccount?.works &&
        response?.data?.data?.publishingAccount?.writers
      ) {
        setCompositionCount(response.data.data.publishingAccount.works.count);
        setWriterCount(response.data.data.publishingAccount.writers.count);
        setSubmissionRequestCount(
          response.data.data.publishingAccount.submissionRequests.count
        );
      }
    }
    getCounts();
    // eslint-disable-next-line
  }, [pubAccId, preReturn]);

  if (preReturn) return preReturn;
  let canCreate =
    authState?.publishingAccount?.users.filter((i) => i.isMe)[0].role !==
    "view_only";

  return (
    <MainComponent>
      <HeaderComponent>
        <MainTitle>Welcome to your Music Inc. Client Portal</MainTitle>
        <Subtitle>
          The portal is still in beta, and we're actively developing new
          features to make it easier to register your works, manage royalties,
          and more. <br />
          <br />
          Need help? Just send us an email at clientsupport@music.ceo! We'll get
          back to you as soon as possible.
        </Subtitle>
        <Tabs>
          <div>
            <Tab to="/publishing" active={!props.match.params.tab}>
              Compositions{" "}
              {compositionCount ? <small>{compositionCount}</small> : ""}
            </Tab>
            {submissionRequestCount > 0 && (
              <Tab
                to="/publishing/requests"
                active={props.match.params.tab === "requests"}
              >
                Pending matched compositions
                <small>{submissionRequestCount}</small>
              </Tab>
            )}
            <Tab
              to="/publishing/songwriters"
              active={props.match.params.tab === "songwriters"}
            >
              Songwriters
              {writerCount ? <small>{writerCount}</small> : ""}
            </Tab>
          </div>
          {canCreate && (
            <Button to="/register">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="2 2 20 20"
                width="22"
                height="22"
              >
                <path
                  fill="white"
                  d="M13 11h4a1 1 0 0 1 0 2h-4v4a1 1 0 0 1-2 0v-4H7a1 1 0 0 1 0-2h4V7a1 1 0 0 1 2 0v4z"
                />
              </svg>
              Register composition
            </Button>
          )}
        </Tabs>
      </HeaderComponent>
      {!props.match.params.tab && <Compositions />}
      {props.match.params.tab === "requests" && <Requests />}
      {props.match.params.tab === "songwriters" && <Songwriters />}
    </MainComponent>
  );
};

export default Dashboard;
