import React, { useState, useEffect } from "react";
import { useGlobal } from "reactn";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import apiRoot from "../../apiRoot";
import WorkView from "../work/WorkViewComponent";
import Checkbox from "../../components/Checkbox";

const Underline = styled.span`
  display: inline-block;
  position: relative;
  color: white;
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  :after {
    background: linear-gradient(90deg, hsl(160, 95%, 40%), hsl(220, 92%, 45%));
    bottom: 0.6rem;
    left: -0.3rem;
    content: "";
    height: 0.4rem;
    opacity: 1;
    padding: 0 0.25rem;
    position: absolute;
    width: 100%;
    z-index: -1;
  }
  @media screen and (max-width: 600px) {
    font-size: 24px;
    :after {
      bottom: 0.4rem;
    }
  }
`;

const MainPanel = styled.div`
  flex: 1;
  padding-right: 40px;
  color: white;
  @media screen and (max-width: 800px) {
    padding-right: 0;
  }
`;

const MainPanelInner = styled.div`
  max-width: 700px;
`;

const InfoPanel = styled.div`
  background: rgba(255, 255, 255, 0.05);
  max-width: 320px;
  margin-top: -40px;
  margin-right: -40px;
  padding: 40px 20px;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  @media screen and (max-width: 800px) {
    display: none;
  }
`;

const Question = styled.div`
  h4 {
    color: rgba(255, 255, 255, 0.8);
    font-size: 15px;
    font-weight: 600;
  }
  p {
    color: rgba(255, 255, 255, 0.6);
    font-size: 14px;
  }
`;

const Segment = styled.div`
  margin-bottom: 20px;
  display: flex;
`;

const Content = styled.div`
  > h3 {
    margin-top: 0;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    color: rgba(255, 255, 255, 0.8);
    svg {
      margin-right: 15px;
      min-width: 40px;
      min-height: 40px;
    }
    @media screen and (max-width: 600px) {
      font-size: 16px;
    }
  }
  > p {
    font-size: 14px;
    margin-left: 55px;
    color: rgba(255, 255, 255, 0.6);
    margin-top: 0;
    @media screen and (max-width: 600px) {
      font-size: 12px;
      margin-left: 0;
    }
  }
`;

const MatchContainer = styled.div`
  background: rgba(255, 255, 255, 0.05);
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  padding: 15px;
  padding-top: 10px;
  margin-top: 15px;
  color: white;
  h2 {
    font-size: 24px;
    margin-top: 0;
  }
`;

const ErrorMsg = styled.div`
  color: hsl(5, 85%, 75%);
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  margin-top: 20px;
  svg {
    margin-right: 10px;
    min-width: 24px;
  }
  @media screen and (max-width: 400px) {
    margin-bottom: 10px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  @media screen and (max-width: 400px) {
    flex-direction: column-reverse;
  }
`;

const Button = styled.button`
  padding: 6px 16px;
  background: linear-gradient(135deg, hsl(160, 95%, 35%), hsl(220, 92%, 45%));
  color: white;
  font-weight: 600;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  transition: filter 0.2s;
  :hover {
    filter: brightness(110%);
  }
  &:disabled {
    cursor: default;
  }
  svg {
    margin-left: 5px;
    margin-right: -5px;
  }
  @media screen and (max-width: 400px) {
    margin-bottom: 10px;
  }
`;

const LightButton = styled(Button)`
  background: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.8);
  transition: background 0.2s;
  :hover {
    background: rgba(255, 255, 255, 0.14);
  }
  svg {
    margin-right: 5px;
    margin-left: -5px;
  }
`;

const VerticalDivider = styled.div`
  margin-bottom: 15px;
`;

// Transform into submission format
const transformWork = (composition) => {
  let work = { title: composition.title };
  if (composition.duration) {
    const duration1 = composition.duration.split(":");
    work.duration = duration1[0] * 60 + Number(duration1[1]);
  }
  if (composition.iswc) work.iswc = composition.iswc.split("-").join("");
  if (composition.altTitles.length > 0)
    work.alternate_titles = composition.altTitles;
  let recordings = [];
  for (let rec of composition.recordings) {
    let recording = {};
    if (rec.performers.length > 0) recording.performers = rec.performers;
    if (rec.isrc) recording.isrc = rec.isrc.split("-").join("");
    if (recording.performers || recording.isrc) recordings.push(recording);
  }
  if (recordings.length > 0) work.recordings = recordings;
  return work;
};

// Informational segments for things people might want to change
const NoRecording = () => {
  return (
    <Segment>
      <Content>
        <h3>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="40"
            height="40"
          >
            <path
              fill="rgba(255,255,255,0.1)"
              d="M12 2a10 10 0 1 1 0 20 10 10 0 0 1 0-20z"
            />
            <path
              fill="rgba(255,255,255,0.8)"
              d="M12 18a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm1-5.9c-.13 1.2-1.88 1.2-2 0l-.5-5a1 1 0 0 1 1-1.1h1a1 1 0 0 1 1 1.1l-.5 5z"
            />
          </svg>
          You didn't enter any recording details
        </h3>
        <p>
          Recording details help societies to match your composition with its
          recordings, and are extremely important in collecting streaming
          royalties. We strongly recommend entering recording details if there's
          a recording of your song. If you don't have recording data yet but
          expect to, you should wait to register your composition until you have
          these details.
        </p>
      </Content>
    </Segment>
  );
};

const RecordingNoISRC = () => {
  return (
    <Segment>
      <Content>
        <h3>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="40"
            height="40"
          >
            <path
              fill="rgba(255,255,255,0.1)"
              d="M12 2a10 10 0 1 1 0 20 10 10 0 0 1 0-20z"
            />
            <path
              fill="rgba(255,255,255,0.8)"
              d="M12 18a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm1-5.9c-.13 1.2-1.88 1.2-2 0l-.5-5a1 1 0 0 1 1-1.1h1a1 1 0 0 1 1 1.1l-.5 5z"
            />
          </svg>
          You didn't enter an ISRC for your recording
        </h3>
        <p>
          An ISRC acts as a unique identifier for a recording, and is extremely
          important in collecting streaming royalties. We strongly recommend
          entering an ISRC if your song is or will be on streaming platforms. If
          you don't have an ISRC yet but expect to, you should wait to register
          your composition until you have these details.
        </p>
      </Content>
    </Segment>
  );
};

const RecordingNoPerformers = () => {
  return (
    <Segment>
      <Content>
        <h3>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="40"
            height="40"
          >
            <path
              fill="rgba(255,255,255,0.1)"
              d="M12 2a10 10 0 1 1 0 20 10 10 0 0 1 0-20z"
            />
            <path
              fill="rgba(255,255,255,0.8)"
              d="M12 18a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm1-5.9c-.13 1.2-1.88 1.2-2 0l-.5-5a1 1 0 0 1 1-1.1h1a1 1 0 0 1 1 1.1l-.5 5z"
            />
          </svg>
          You didn't enter any performer names
        </h3>
        <p>
          The names of the performers or recording artists who created a
          recording of your song are important in matching recordings to your
          composition and collecting all of your royalties, particularly for
          streaming. We strongly recommend entering performer names if there's a
          recording of your song. If you don't have this information yet but
          expect to, you should wait to register your composition until you have
          these details.
        </p>
      </Content>
    </Segment>
  );
};

// Work matching
const Match = (props) => {
  let [loading, setLoading] = useState(false);
  let [error, setError] = useState("");
  let [done, setDone] = useState(false);
  let [authState] = useGlobal("authState");

  const matchWork = async function () {
    setLoading(true);
    const work = transformWork(props.mywork);
    const response = await axios.post(
      apiRoot,
      {
        query: `mutation matchWork($pubAcc: Int!, $matchId: Int!, $work: WorkInput, $writers: [WriterAssociationInput!]!) {
        publishingAccount(id: $pubAcc) {
          matchAndSubmitWork(matchId: $matchId, work: $work, writers: $writers)
        }
      }`,
        variables: {
          matchId: props.composition.id,
          pubAcc: authState.publishingAccount.id,
          work,
          writers: props.mywork.writers.map((i) => ({
            writerId: i.id,
            controlled: i.controlled,
            share: Number(i.share),
          })),
        },
      },
      { withCredentials: true }
    );
    setLoading(false);
    if (response.data.errors) return setError(response.data.errors[0].message);
    setDone(true);
  };

  return (
    <MatchContainer>
      <WorkView composition={props.composition} />
      {error && <VerticalDivider />}
      {error && (
        <ErrorMsg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path
              fill="hsl(5, 100%, 70%)"
              d="M12 2a10 10 0 1 1 0 20 10 10 0 0 1 0-20zm0 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16zm0 9a1 1 0 0 1-1-1V8a1 1 0 0 1 2 0v4a1 1 0 0 1-1 1zm0 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
            />
          </svg>
          {error}
        </ErrorMsg>
      )}
      {done && <Redirect to="/publishing" />}
      <VerticalDivider />
      <Button onClick={matchWork} disabled={loading}>
        Match composition
      </Button>
    </MatchContainer>
  );
};

const MandatoryMatch = (props) => {
  return (
    <Segment>
      <Content>
        <h3>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="40"
            height="40"
          >
            <path
              fill="rgba(255,255,255,0.1)"
              d="M12 2a10 10 0 1 1 0 20 10 10 0 0 1 0-20z"
            />
            <path
              fill="rgba(255,255,255,0.8)"
              d="M12 18a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm1-5.9c-.13 1.2-1.88 1.2-2 0l-.5-5a1 1 0 0 1 1-1.1h1a1 1 0 0 1 1 1.1l-.5 5z"
            />
          </svg>
          We found a matching composition in our database
        </h3>
        <p>
          This composition has the same ISWC or ISRC you entered, so you can't
          submit a new composition. If the details listed here aren't correct,
          get in touch so we can resolve any issues with our data. If everything
          looks good, just click the Match Composition button to start
          collecting your share.
          <Match
            composition={props.match.matches[0]}
            mywork={props.composition}
          />
        </p>
      </Content>
    </Segment>
  );
};

const OptionalMatch = (props) => {
  return (
    <Segment>
      <Content>
        <h3>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="40"
            height="40"
          >
            <path
              fill="rgba(255,255,255,0.1)"
              d="M12 2a10 10 0 1 1 0 20 10 10 0 0 1 0-20z"
            />
            <path
              fill="rgba(255,255,255,0.8)"
              d="M11 12a1 1 0 0 1 0-2h2a1 1 0 0 1 .96 1.27L12.33 17H13a1 1 0 0 1 0 2h-2a1 1 0 0 1-.96-1.27L11.67 12H11zm2-4a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"
            />
          </svg>
          We found a matching composition in our database
        </h3>
        <p>
          If the details listed below are correct and this is the same
          composition, just click the Match Composition button to start
          collecting your share. If the composition you're trying to register is
          different, click the submit button at the bottom of the page instead
          to create a new composition. Contact us if this is the correct
          composition but has any inaccurate data.
          <Match
            composition={props.match.matches[0]}
            mywork={props.composition}
          />
        </p>
      </Content>
    </Segment>
  );
};

const OptionalMatches = (props) => {
  return (
    <Segment>
      <Content>
        <h3>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="40"
            height="40"
          >
            <path
              fill="rgba(255,255,255,0.1)"
              d="M12 2a10 10 0 1 1 0 20 10 10 0 0 1 0-20z"
            />
            <path
              fill="rgba(255,255,255,0.8)"
              d="M12 18a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm1-5.9c-.13 1.2-1.88 1.2-2 0l-.5-5a1 1 0 0 1 1-1.1h1a1 1 0 0 1 1 1.1l-.5 5z"
            />
          </svg>
          We found some matching compositions in our database
        </h3>
        <p>
          If any of the compositions listed below are the same as the
          composition you're registering, just click the Match Composition
          button to start collecting your share. If the composition you're
          trying to register is different, click the submit button at the bottom
          of the page instead to create a new composition. Contact us if one of
          these is the correct composition but has any inaccurate data.
          {props.match.matches.map((i) => (
            <Match key={i.id} composition={i} mywork={props.composition} />
          ))}
        </p>
      </Content>
    </Segment>
  );
};

// This is just a UI component, doesn't send reqs
const SubmissionRequests = (props) => {
  return (
    <Segment>
      <Content>
        <h3>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="40"
            height="40"
          >
            <path
              fill="rgba(255,255,255,0.1)"
              d="M12 2a10 10 0 1 1 0 20 10 10 0 0 1 0-20z"
            />
            <path
              fill="rgba(255,255,255,0.8)"
              d="M11 12a1 1 0 0 1 0-2h2a1 1 0 0 1 .96 1.27L12.33 17H13a1 1 0 0 1 0 2h-2a1 1 0 0 1-.96-1.27L11.67 12H11zm2-4a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"
            />
          </svg>
          Some of your co-writers are also Music Inc. clients
        </h3>
        <p>
          You can invite these writers to add this composition to their Music
          Inc. accounts and start collecting their royalties as soon as it's
          registered. Just check the box for any writer you want to send a
          request to. Note that we won't start registering this composition
          until all of these requests have either been accepted or rejected to
          ensure we're registering all of the shares we can collect for at once.
          <VerticalDivider />
          {props.reqs.map((req) => (
            <Checkbox
              active={req.accept}
              setActive={(accept) =>
                props.setReqs(
                  props.reqs.map((r) =>
                    r.id === req.id ? { ...r, accept } : r
                  )
                )
              }
            >
              {(req.first_name ? `${req.first_name} ` : "") +
                (req.middle_name ? `${req.middle_name} ` : "") +
                req.last_name}
            </Checkbox>
          ))}
        </p>
      </Content>
    </Segment>
  );
};

const ReviewComposition = (props) => {
  const composition = props.composition;
  const noRecording = composition.recordings.length === 0;
  const noIsrc = composition.recordings.filter((i) => !i.isrc).length > 0;
  const noPerformers =
    composition.recordings.filter((i) => i.performers.length === 0).length > 0;

  let [loading, setLoading] = useState(true);
  let [working, setWorking] = useState(false);
  let [done, setDone] = useState(false);
  let [match, setMatch] = useState(null);
  let [submissionReqs, setSubmissionReqs] = useState(null);
  let [error, setError] = useState("");
  let [authState] = useGlobal("authState");

  async function submit() {
    setWorking(true);
    let workId;
    if (match && match.type === "auto") {
      const work = transformWork(composition);
      workId = match.matches[0].id;
      const response = await axios.post(
        apiRoot,
        {
          query: `mutation matchWork($pubAcc: Int!, $matchId: Int!, $work: WorkInput, $writers: [WriterAssociationInput!]!) {
          publishingAccount(id: $pubAcc) {
            matchAndSubmitWork(matchId: $matchId, work: $work, writers: $writers)
          }
        }`,
          variables: {
            matchId: match.matches[0].id,
            pubAcc: authState.publishingAccount.id,
            work,
            writers: composition.writers.map((i) => ({
              writerId: i.id,
              controlled: i.controlled,
              share: Number(i.share),
            })),
          },
        },
        { withCredentials: true }
      );
      if (response.data.errors)
        return setError(response.data.errors[0].message);
    } else {
      const work = transformWork(composition);
      const response = await axios.post(
        apiRoot,
        {
          query: `
          mutation createWork($pubAcc: Int!, $work: WorkInput!, $writers: [WriterAssociationInput!]!) {
            publishingAccount(id: $pubAcc) {
              createWork(work: $work, writers: $writers) {
                id
              }
            }
          }`,
          variables: {
            pubAcc: authState.publishingAccount.id,
            work,
            writers: composition.writers.map((i) => ({
              writerId: i.id,
              controlled: i.controlled,
              share: Number(i.share),
            })),
          },
        },
        { withCredentials: true }
      );
      if (response.data.errors) {
        setWorking(false);
        return setError(response.data.errors[0].message);
      }
      workId = response.data.data.publishingAccount.createWork.id;
      // send submission requests
      if (submissionReqs) {
        for (const req of submissionReqs.filter((x) => x.accept)) {
          const response = await axios.post(
            apiRoot,
            {
              query: `
            mutation createSubmissionRequest($pubAcc: Int!, $workId: Int!, $writerId: Int!) {
              publishingAccount(id: $pubAcc) {
                createSubmissionRequest(workId: $workId, writerId: $writerId)
              }
            }`,
              variables: {
                pubAcc: authState.publishingAccount.id,
                workId,
                writerId: req.id,
              },
            },
            { withCredentials: true }
          );
          if (response.data.errors) {
            setWorking(false);
            return setError(response.data.errors[0].message);
          }
        }
      }
    }
    // add tags
    if (composition.tags && composition.tags.length > 0) {
      for (const tag of composition.tags) {
        let response;
        if (tag.id) {
          response = await axios.post(
            apiRoot,
            {
              query: `
            mutation addTag($pubAcc: Int!, $workId: Int!, $tagId: Int!) {
              publishingAccount(id: $pubAcc) {
                addTag(workId: $workId, tagId: $tagId)
              }
            }`,
              variables: {
                pubAcc: authState.publishingAccount.id,
                workId,
                tagId: tag.id,
              },
            },
            { withCredentials: true }
          );
        } else {
          response = await axios.post(
            apiRoot,
            {
              query: `
            mutation addTag($pubAcc: Int!, $workId: Int!, $tagText: GenericString!) {
              publishingAccount(id: $pubAcc) {
                addTag(workId: $workId, tagText: $tagText)
              }
            }`,
              variables: {
                pubAcc: authState.publishingAccount.id,
                workId,
                tagText: tag.tag,
              },
            },
            { withCredentials: true }
          );
        }
        if (response.data.errors) {
          setWorking(false);
          return setError(response.data.errors[0].message);
        }
      }
    }
    setWorking(false);
    setDone(true);
  }

  useEffect(() => {
    async function preSubmit() {
      const work = transformWork(composition);
      const response = await axios.post(
        apiRoot,
        {
          query: `
            mutation preCreateWork($pubAcc: Int!, $work: WorkInput!, $writers: [WriterAssociationInput!]!) {
              publishingAccount(id: $pubAcc) {
                preCreateWork(work: $work, writers: $writers) {
                  match_type
                  matches {
                    id
                    title
                    duration
                    iswc
                    alternate_titles
                    recordings {
                      isrc
                      performers
                    }
                    songwriters {
                      songwriter {
                        id
                        first_name
                        middle_name
                        last_name
                        society
                        ipi
                      }
                      share
                    }
                  }
                  availableSubmissionRequests {
                    id
                    first_name
                    middle_name
                    last_name
                  }
                }
              }
            }`,
          variables: {
            pubAcc: authState.publishingAccount.id,
            work,
            writers: composition.writers.map((i) => ({
              writerId: i.id,
              controlled: i.controlled,
              share: Number(i.share),
            })),
          },
        },
        { withCredentials: true }
      );
      setLoading(false);
      if (response.data.errors)
        return setError(response.data.errors[0].message);
      const data = response.data.data.publishingAccount?.preCreateWork;
      if (data.match_type) {
        setMatch({
          type: data.match_type,
          matches: data.matches.map((i) => ({
            ...i,
            altTitles: i.alternate_titles,
            writers: i.songwriters.map((i) => ({
              ...i.songwriter,
              share: i.share,
            })),
          })),
        });
      }
      if (data.availableSubmissionRequests?.length > 0)
        setSubmissionReqs(data.availableSubmissionRequests);
    }
    preSubmit();
  }, [composition, authState]);

  return (
    <div style={{ flex: 1, display: "flex" }}>
      <MainPanel>
        <MainPanelInner>
          {done && <Redirect to="/publishing" />}
          <h1>
            <Underline>Review composition</Underline>
          </h1>
          {noRecording && <NoRecording />}
          {noIsrc && <RecordingNoISRC />}
          {noPerformers && <RecordingNoPerformers />}
          {match && match.type === "mandatory" && (
            <MandatoryMatch match={match} composition={composition} />
          )}
          {match && match.type === "optional" && match.matches.length === 1 && (
            <OptionalMatch match={match} composition={composition} />
          )}
          {match && match.type === "optional" && match.matches.length > 1 && (
            <OptionalMatches match={match} composition={composition} />
          )}
          {submissionReqs && (!match || match.type === "optional") && (
            <SubmissionRequests
              reqs={submissionReqs}
              setReqs={setSubmissionReqs}
            />
          )}
          <WorkView composition={composition} authState={authState} />
          {error ? (
            <ErrorMsg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path
                  fill="hsl(5, 100%, 70%)"
                  d="M12 2a10 10 0 1 1 0 20 10 10 0 0 1 0-20zm0 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16zm0 9a1 1 0 0 1-1-1V8a1 1 0 0 1 2 0v4a1 1 0 0 1-1 1zm0 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
                />
              </svg>
              {error}
            </ErrorMsg>
          ) : (
            <div />
          )}
          <ButtonContainer>
            <LightButton onClick={props.back}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                height="24"
                width="24"
              >
                <path
                  fill="white"
                  d="M9.41 11H17a1 1 0 0 1 0 2H9.41l2.3 2.3a1 1 0 1 1-1.42 1.4l-4-4a1 1 0 0 1 0-1.4l4-4a1 1 0 0 1 1.42 1.4L9.4 11z"
                />
              </svg>
              Back
            </LightButton>
            {(!match || match.type !== "mandatory") && (
              <Button onClick={submit} disabled={loading || working}>
                Submit composition
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  height="24"
                  width="24"
                >
                  <path
                    fill="white"
                    d="M14.59 13H7a1 1 0 0 1 0-2h7.59l-2.3-2.3a1 1 0 1 1 1.42-1.4l4 4a1 1 0 0 1 0 1.4l-4 4a1 1 0 0 1-1.42-1.4l2.3-2.3z"
                  />
                </svg>
              </Button>
            )}
            {match && match.type === "mandatory" && <div />}
          </ButtonContainer>
        </MainPanelInner>
      </MainPanel>
      {(match || submissionReqs) && (
        <InfoPanel>
          {match && (
            <Question>
              <h4>What does composition matching do?</h4>
              <p>
                We match the data you enter against other compositions already
                in our database to ensure we don't have duplicate records
                internally. This helps ensure that works are registered
                correctly with societies and royalties are collected and paid
                out to you properly, so make sure to click the "Match" button if
                any matches appear.
              </p>
            </Question>
          )}
          {submissionReqs && (
            <Question>
              <h4>What do submission requests do?</h4>
              <p>
                If you have any co-writers on this work who are also Music Inc.
                clients, you can choose to send a request for them to add the
                composition to their account. Submission requests allow us to
                register all of the shares we can collect at once, reducing
                registration times and helping your royalties get collected
                faster and more accurately. It also helps your co-writers avoid
                re-entering composition details!
              </p>
            </Question>
          )}
        </InfoPanel>
      )}
    </div>
  );
};

export default ReviewComposition;
