import React, { useEffect, useState } from "react";
import { useGlobal } from "reactn";
import styled from "styled-components";
import { Redirect, useRouteMatch } from "react-router-dom";
import axios from "axios";
import apiRoot from "../../apiRoot";

import ActivateInvite from "./ActivateInvite";
import ConfirmEmail from "./ConfirmEmail";
import SignPublishingContract from "./SignPublishingContract";
import CreateSongwriter from "./CreateSongwriter";
import FinalizeLabelStatement from "./FinalizeLabelStatement";
import FinalizePublishingUser from "./FinalizePublishingUser";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  flex-direction: column;
  padding: 20px;
  @media screen and (max-width: 400px) {
    padding-bottom: 0;
  }
`;

const Logo = styled.svg`
  margin-top: -100px;
  @media screen and (max-width: 400px) {
    width: 100px;
    height: 100px;
  }
`;

const Box = styled.form`
  border-radius: 10px;
  background: #33293a;
  padding: 40px;
  box-shadow: 0 4px 12px 2px rgba(0, 0, 0, 0.3);
  color: white;
  max-width: 800px;
  width: calc(100% - 40px);
  @media screen and (max-width: 400px) {
    padding: 20px;
    border-radius: 0;
    width: 100%;
  }
`;

const Dots = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: 600px) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

const Dot = styled.div`
  background: ${(props) =>
    props.active
      ? "linear-gradient(135deg, hsl(160, 95%, 55%), hsl(220, 92%, 65%))"
      : "rgba(255,255,255,0.2)"};
  box-shadow: ${(props) =>
    props.active ? "0 1px 3px rgba(0,0,0,0.15)" : "none"};
  width: ${(props) => (props.current ? "14px" : "10px")};
  height: ${(props) => (props.current ? "14px" : "10px")};
  margin-right: 10px;
  border-radius: 100px;
`;

const DotContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 600px) {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
`;

const AuthInfo = styled.span`
  font-size: 12px;
  color: rgba(255, 255, 255, 0.8);
  font-weight: 500;
`;

const Logout = styled.span`
  display: inline-block;
  margin-left: 15px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
  transition: color 0.2s;
  :hover {
    color: rgba(255, 255, 255, 0.7);
  }
`;

const UserSetup = (props) => {
  const match = useRouteMatch();

  let [hideHeader, setHideHeader] = useGlobal("hideHeader");
  let [authState, setAuthState] = useGlobal("authState");
  let [invite, setInvite] = useState(null);

  const getInvite = () => {
    if (invite && match.params.code === invite.code) return;
    if (props.tab === "activateInvite" || match.params.code) {
      async function get() {
        const inv = await axios.post(
          apiRoot,
          {
            query: `
          query lookupInvite($code: GenericString!) {
            lookupInvite(code: $code){
              consumed
              type
              administration_fee
              paName
              paRole
              labelArtist {
                label {
                  name
                }
              }
            }
          }
          `,
            variables: { code: match.params.code },
          },
          { withCredentials: true }
        );
        if (!inv.data.data.lookupInvite) setInvite(false);
        else {
          setInvite(inv.data.data.lookupInvite);
        }
      }
      get();
    } else if (!invite) {
      if (
        authState &&
        authState.user &&
        authState.user.invite &&
        authState.user.invite.type === "add_publishing_account_user"
      )
        setInvite(authState.user.invite);
      else if (authState && authState.publishingAccount)
        setInvite(authState.publishingAccount.invite);
      else if (authState && authState.user && authState.user.invite)
        setInvite(authState.user.invite);
    }
  };

  // eslint-disable-next-line
  useEffect(getInvite, [match.params.code]);

  useEffect(() => {
    setHideHeader(true);
    return () => setHideHeader(false);
  }, [hideHeader, setHideHeader]);

  const logout = async () => {
    await axios.post(
      apiRoot,
      {
        query: `
        mutation logout {
          logout
        }`,
      },
      { withCredentials: true }
    );
    setAuthState({ loading: false, authenticated: false });
  };

  const deny =
    invite === false ||
    (invite && invite.consumed && props.tab === "activateInvite") ||
    (invite &&
      invite.consumed &&
      props.tab === "signContract" &&
      match.params.code);

  if (
    authState?.authenticated &&
    authState.setupStatus === "done" &&
    invite &&
    invite.type === "songwriter"
  )
    invite.type = "add_songwriter";

  return (
    <Container>
      <Logo
        width="140"
        height="140"
        viewBox="0 0 140 140"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M48.2626 35.9688C48.2623 34.5441 49.4156 33.2079 50.8251 33H54.2621V56.5626V90.9688C54.2969 92.6453 53.731 94.8298 52.9814 96.7813C51.5123 99.7522 48.9754 102.386 45.6376 104.313C42.2998 106.24 38.7262 107.151 35.4188 106.938C32.1116 106.724 28.8502 105.248 27.1376 102.281C25.4251 99.3151 25.7937 95.7835 27.2626 92.8127C28.7315 89.8418 31.2998 87.1772 34.6376 85.2501C37.9754 83.3231 41.5178 82.4431 44.8251 82.6564C45.9997 82.7322 47.1669 82.9756 48.2626 83.3751V35.9688Z"
          fill="white"
        />
        <path
          d="M96.5 106.5V62.6174L80.3 89.7974H72.38C72.38 89.7974 55.73 47.8974 53.5 47.7074C51.27 47.5174 52.3995 68.155 53 71.7074V96.0074V33.0074C53 33.0074 48.44 33.0074 54.47 33.0074C60.5 33.0074 76.61 69.3674 76.61 69.3674L98.21 33.0074H112.88L112.88 106.5H96.5Z"
          fill="white"
        />
      </Logo>
      <Box>
        {invite === null && <AuthInfo>Loading...</AuthInfo>}
        {deny && <AuthInfo>We couldn't find this invite.</AuthInfo>}
        {invite && !deny && (
          <div>
            <DotContainer>
              {invite.type === "songwriter" && (
                <Dots>
                  <Dot active={true} current={props.tab === "activateInvite"} />
                  <Dot
                    active={props.tab !== "activateInvite"}
                    current={props.tab === "confirmEmail"}
                  />
                  <Dot
                    active={
                      props.tab === "signContract" ||
                      props.tab === "createSongwriter"
                    }
                    current={props.tab === "signContract"}
                  />
                  <Dot
                    active={props.tab === "createSongwriter"}
                    current={props.tab === "createSongwriter"}
                  />
                </Dots>
              )}
              {invite.type === "user_only" && (
                <Dots>
                  <Dot active={true} current={props.tab === "activateInvite"} />
                  <Dot
                    active={props.tab !== "activateInvite"}
                    current={props.tab === "confirmEmail"}
                  />
                </Dots>
              )}
              {invite.type === "publisher" && (
                <Dots>
                  <Dot active={true} current={props.tab === "activateInvite"} />
                  <Dot
                    active={props.tab !== "activateInvite"}
                    current={props.tab === "confirmEmail"}
                  />
                  <Dot
                    active={props.tab === "signContract"}
                    current={props.tab === "signContract"}
                  />
                </Dots>
              )}
              {invite.type === "label_statement" && (
                <Dots>
                  <Dot active={true} current={props.tab === "activateInvite"} />
                  <Dot
                    active={props.tab !== "activateInvite"}
                    current={props.tab === "confirmEmail"}
                  />
                  <Dot
                    active={props.tab === "finalizeLabelStatement"}
                    current={props.tab === "finalizeLabelStatement"}
                  />
                </Dots>
              )}
              {invite.type === "add_publishing_account_user" && (
                <Dots>
                  <Dot active={true} current={props.tab === "activateInvite"} />
                  <Dot
                    active={props.tab !== "activateInvite"}
                    current={props.tab === "confirmEmail"}
                  />
                  <Dot
                    active={props.tab === "finalizePublishingUser"}
                    current={props.tab === "finalizePublishingUser"}
                  />
                </Dots>
              )}
              {invite.type === "add_songwriter" && (
                <Dots>
                  <Dot
                    active={
                      props.tab === "signContract" ||
                      props.tab === "createSongwriter"
                    }
                    current={props.tab === "signContract"}
                  />
                  <Dot
                    active={props.tab === "createSongwriter"}
                    current={props.tab === "createSongwriter"}
                  />
                </Dots>
              )}
              {invite.type === "add_publisher" && (
                <Dots>
                  <Dot
                    active={props.tab === "signContract"}
                    current={props.tab === "signContract"}
                  />
                </Dots>
              )}
              {authState?.authenticated && (
                <AuthInfo>
                  <strong>
                    {authState.publishingAccount
                      ? authState.publishingAccount.name
                      : authState.user.name}
                  </strong>{" "}
                  ({authState.user.email})
                  <Logout onClick={logout}>Logout</Logout>
                </AuthInfo>
              )}
            </DotContainer>
            {invite &&
              (invite.type === "add_songwriter" ||
                invite.type === "add_publisher") &&
              props.tab === "activateInvite" && (
                <Redirect to={"/setup/contract/" + match.params.code} />
              )}
            {props.tab === "activateInvite" &&
              invite &&
              invite.type !== "add_songwriter" &&
              invite.type !== "add_publisher" && (
                <ActivateInvite
                  code={match.params.code}
                  type={invite.type}
                  noredir={authState?.loading}
                />
              )}
            {props.tab === "confirmEmail" && <ConfirmEmail />}
            {props.tab === "signContract" && (
              <SignPublishingContract
                code={match.params.code}
                type={invite.type}
                fee={invite.administration_fee}
              />
            )}
            {props.tab === "createSongwriter" && <CreateSongwriter />}
            {props.tab === "finalizeLabelStatement" && (
              <FinalizeLabelStatement
                code={match.params.code}
                invite={invite}
              />
            )}
            {props.tab === "finalizePublishingUser" && (
              <FinalizePublishingUser
                code={match.params.code}
                invite={invite}
              />
            )}
          </div>
        )}
      </Box>
    </Container>
  );
};

export default UserSetup;
