import React, { useState } from "react";
import { useGlobal } from "reactn";
import styled from "styled-components";

const Outside = styled.div`
  position: relative;
  flex: 1;
  margin-bottom: 40px;
`;

const BaseComponent = styled.div`
  border: 2px solid
    ${(props) =>
      props.active ? "rgba(255, 255, 255, 0.3)" : "rgba(255, 255, 255, 0.1)"};
  border-radius: 2px;
  padding: 4px 8px;
  z-index: 10;
  position: absolute;
  width: 100%;
  background: ${(props) => props.background || "#2c1d32"};
  transition: box-shadow 0.2s, border-color 0.2s;
  box-sizing: border-box;
  box-shadow: ${(props) =>
    props.active ? "0 2px 4px rgba(0,0,0,0.2)" : "none"};
`;

const Label = styled.label`
  font-size: 12px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 1px;
  display: block;
`;

const Input = styled.input`
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;
  border: none;
  background: transparent;
  width: 100%;
  outline: none;
`;

const List = styled.div`
  display: ${(props) => (props.visible ? "block" : "none")};
  max-height: 200px;
  overflow-y: auto;
  margin-top: 10px;
  ::-webkit-scrollbar {
    width: 12px;
    height: 18px;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }
  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    height: 12px;
    border: 8px solid transparent;
    border-width: 0 0 0 8px;
    background-clip: padding-box;
    background-color: rgba(255, 255, 255, 0.2);
  }
`;

const Item = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.7);
  padding: 4px 6px;
  cursor: pointer;
  border-radius: 2px;
  z-index: 15;
  :hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;

function Base(props) {
  const [input, setInput] = useState(props.value);
  const regex = new RegExp(`^(${input})`, "i");
  const [active, setActive] = useState(false);
  const [societies] = useGlobal("societies");
  return (
    <Outside>
      <Label>{props.label}</Label>
      <BaseComponent active={active} background={props.background}>
        <Input
          autoComplete="none"
          value={input}
          onChange={(x) => setInput(x.target.value)}
          onFocus={() => {
            setActive(true);
            setInput("");
          }}
          onBlur={() => {
            if (!active) return;
            if (societies.includes(input)) props.onChange(input);
            else setInput(props.value);
            setActive(false);
          }}
        />
        <List visible={active}>
          <Item
            onMouseDown={() => {
              props.onChange("");
              setInput("");
              setActive(false);
            }}
          >
            None
          </Item>
          {societies &&
            societies
              .filter((option) => regex.test(option))
              .map((i) => (
                <Item
                  key={i}
                  onMouseDown={() => {
                    props.onChange(i);
                    setInput(i);
                    setActive(false);
                  }}
                >
                  {i}
                </Item>
              ))}
        </List>
      </BaseComponent>
    </Outside>
  );
}

export default Base;
