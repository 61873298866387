import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { useGlobal } from "reactn";
import styled from "styled-components";
import axios from "axios";
import apiRoot from "../../apiRoot";
import NotFound from "../NotFound";
import InfiniteScroll from "react-infinite-scroller";
import { Helmet } from "react-helmet";
import FilterSort from "../dashboard/FilterSort";

const MainComponent = styled.div`
  background: rgba(255, 255, 255, 0.05);
  max-width: 1200px;
  width: 100%;
  padding: 40px;
  box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  min-height: calc(100vh - 160px);
  color: white;
  @media screen and (max-width: 400px) {
    padding: 20px;
  }
`;

const Back = styled(Link)`
  text-decoration: none;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 500;
  font-size: 14px;
  transition: color 0.2s;
  :hover {
    color: rgba(255, 255, 255, 0.6);
  }
`;

const Heading = styled.div``;

const Input = styled.input`
  background: rgba(255, 255, 255, 0.05);
  border: none;
  border-radius: 2px;
  width: calc(100% - 24px);
  padding: 6px 10px;
  outline: none;
  transition: background 0.2s;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;
  display: block;
  :focus {
    background: rgba(255, 255, 255, 0.1);
  }
  ::placeholder {
    color: rgba(255, 255, 255, 0.4);
  }
`;

const Composition = styled(Link)`
  text-decoration: none;
  display: block;
  padding: 20px 0;
  border-bottom: ${(props) => (props.last ? "0" : "1px")} solid
    rgba(255, 255, 255, 0.2);
`;

const CompositionTitle = styled.h3`
  color: white;
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 24px;
  @media screen and (max-width: 800px) {
    font-size: 18px;
    margin-bottom: 10px;
  }
`;

const Writers = styled.p`
  color: rgba(255, 255, 255, 0.8);
  margin: 0;
  font-size: 14px;
  a {
    color: rgba(255, 255, 255, 0.8);
  }
  @media screen and (max-width: 400px) {
    font-size: 12px;
  }
`;

const NoCompositions = styled.div`
  text-align: center;
  margin-top: 40px;
`;

const CompositionHeader = styled.h3`
  margin-top: 40px;
  color: rgba(255, 255, 255, 0.9);
`;

const You = styled.span`
  margin-left: 10px;
  background: rgba(255, 255, 255, 0.1);
  padding: 2px 10px;
  border-radius: 10px;
  letter-spacing: 0.5px;
  font-weight: 500;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
`;

const FlexContainer = styled.div`
  margin: 10px 0;
  display: flex;
  align-items: center;
`;

const SortOpen = styled.div`
  display: flex;
  cursor: pointer;
  margin-left: 15px;
  color: rgba(255, 255, 255, 0.6);
  transition: color 0.2s;
  @media screen and (min-width: 801px) {
    display: none;
  }
  :hover {
    color: rgba(255, 255, 255, 0.8);
  }
  svg {
    width: 24px;
    height: 24px;
  }
`;

const Writer = (props) => {
  let [count, setCount] = useState(null);
  let [writer, setWriter] = useState(null);
  let [loading, setLoading] = useState(false);
  let [compositions, setCompositions] = useState({ works: [] });
  let [tags, setTags] = useState([]);
  let [sortDirection, setSortDirection] = useState("asc");
  let [sortBy, setSortBy] = useState("title");
  let [selectedTags, setSelectedTags] = useState([]);
  let [sortOpen, setSortOpen] = useState(false);
  let [error, setError] = useState("");
  let [filter, setFilter] = useState("");
  const [authState] = useGlobal("authState");

  async function getTags() {
    if (!authState?.publishingAccount?.id) return;
    const response = await axios.post(
      apiRoot,
      {
        query: `
          query getTags($id: Int!) {
            publishingAccount(id: $id) {
              tags {
                id
                tag
              }
            }
          }`,
        variables: { id: authState.publishingAccount.id },
      },
      { withCredentials: true }
    );
    setTags(response.data.data?.publishingAccount?.tags);
  }

  async function getCompositions(offset = 0) {
    if (!authState?.publishingAccount) return;
    if (isNaN(Number(props.match.params.id))) setError("404");
    if (offset < compositions.works.length && offset > 0) return;
    setLoading(true);
    const response = await axios.post(
      apiRoot,
      {
        query: `
            query getCompositions($id: Int!, $offset: Int!, $writer: Int!,
              $sortBy: WorkSortBy, $sortOrder: SortOrder, $filterTags: [Int!]) {
              publishingAccount(id: $id) {
                writer(id: $writer) {
                  id
                  ipi
                  society
                  first_name
                  middle_name
                  last_name
                }
                works(offset: $offset, filterSongwriter: $writer, sortBy: $sortBy, sortOrder: $sortOrder, filterTags: $filterTags) {
                  count
                  works {
                    id
                    title
                    songwriters {
                      songwriter {
                        id
                        first_name
                        middle_name
                        last_name
                      }
                    }
                  }
                  isNextPage
                }
              }
            }`,
        variables: {
          id: authState?.publishingAccount?.id,
          offset,
          writer: Number(props.match.params.id),
          sortBy,
          sortOrder: sortDirection,
          filterTags: selectedTags,
        },
      },
      { withCredentials: true }
    );
    if (response.data.errors) return setError(response.data.errors[0].message);
    setCount(response.data?.data?.publishingAccount?.works?.count);
    setWriter(response.data?.data?.publishingAccount?.writer);
    setLoading(false);
    if (response?.data?.data?.publishingAccount?.works) {
      setCompositions({
        works:
          offset === 0
            ? response.data.data.publishingAccount.works.works
            : [
                ...compositions.works,
                ...response.data.data.publishingAccount.works.works,
              ],
        isNextPage: response.data.data.publishingAccount.works.isNextPage,
      });
    }
  }

  useEffect(
    () => getCompositions(),
    // eslint-disable-next-line
    [
      authState?.publishingAccount,
      props.match.params.id,
      sortDirection,
      sortBy,
      selectedTags,
    ]
  );

  useEffect(() => {
    getTags();
    // eslint-disable-next-line
  }, [authState?.publishingAccount?.id]);

  const filterCompositions = compositions.works.filter((i) => {
    return i.title.toLowerCase().includes(filter.toLowerCase());
  });

  if (error) return <NotFound />;
  if (authState && !authState.loading && !authState.authenticated)
    return <Redirect to="/login" />;
  if (!writer) return <MainComponent>Loading...</MainComponent>;

  const name =
    (writer.first_name ? `${writer.first_name} ` : "") +
    (writer.middle_name ? `${writer.middle_name} ` : "") +
    writer.last_name;

  return (
    <MainComponent>
      <Helmet>{name} | Music Inc. Client Portal</Helmet>
      <Heading>
        <Back to="/publishing/songwriters">Back to catalog</Back>
        <h1 style={{ marginBottom: "10px" }}>
          {name}{" "}
          {writer.id === authState?.publishingAccount?.songwriter?.id && (
            <You>YOU</You>
          )}
        </h1>
        <Writers>
          {writer.ipi && writer.society
            ? `${writer.society} — IPI: ${writer.ipi}`
            : "No society affiliation"}
        </Writers>
      </Heading>
      <CompositionHeader>Compositions ({count})</CompositionHeader>
      <div style={{ display: "flex" }}>
        <div style={{ flex: 1 }}>
          <FlexContainer>
            <Input
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              placeholder="Filter compositions (by title)"
            />
            <SortOpen onClick={() => setSortOpen(true)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M3 7.5L7.5 3m0 0L12 7.5M7.5 3v13.5m13.5 0L16.5 21m0 0L12 16.5m4.5 4.5V7.5"
                />
              </svg>
            </SortOpen>
          </FlexContainer>
          <InfiniteScroll
            pageStart={0}
            loadMore={() => {
              if (!loading) getCompositions(compositions.works.length);
            }}
            hasMore={compositions.isNextPage}
          >
            {filterCompositions.map((i, index) => (
              <Composition
                key={i.id}
                to={`/composition/${i.id}`}
                last={index === filterCompositions.length - 1}
              >
                <CompositionTitle>{i.title}</CompositionTitle>
                <Writers>
                  {i.songwriters.map(({ songwriter: writer }, index) => (
                    <span key={writer.id}>
                      {writer.first_name && `${writer.first_name} `}
                      {writer.middle_name && `${writer.middle_name} `}
                      {writer.last_name}
                      {index < i.songwriters.length - 1 && ", "}
                    </span>
                  ))}
                </Writers>
              </Composition>
            ))}
          </InfiniteScroll>
          {!loading && !error && filterCompositions.length === 0 && (
            <NoCompositions>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="64"
                height="64"
              >
                <path
                  fill="rgba(255,255,255,0.2)"
                  d="M12 2a10 10 0 1 1 0 20 10 10 0 0 1 0-20z"
                />
                <path
                  fill="rgba(255,255,255,0.9)"
                  d="M12 18a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm1-5.9c-.13 1.2-1.88 1.2-2 0l-.5-5a1 1 0 0 1 1-1.1h1a1 1 0 0 1 1 1.1l-.5 5z"
                />
              </svg>
              <CompositionTitle>
                You don't have any compositions matching this filter!
              </CompositionTitle>
              <Writers>
                <Link to="/register">Register a composition</Link> or change
                your filter to see more compositions.
              </Writers>
            </NoCompositions>
          )}
        </div>
        <FilterSort
          tags={tags}
          sortDirection={sortDirection}
          setSortDirection={setSortDirection}
          sortBy={sortBy}
          setSortBy={setSortBy}
          selectedTags={selectedTags}
          toggleSelectTag={(id) => {
            if (selectedTags.includes(id))
              setSelectedTags(selectedTags.filter((i) => i !== id));
            else setSelectedTags([...selectedTags, id]);
          }}
          open={sortOpen}
          close={() => setSortOpen(false)}
        />
      </div>
    </MainComponent>
  );
};

export default Writer;
