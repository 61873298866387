import React, { useState } from "react";
import { useGlobal } from "reactn";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import apiRoot from "../../apiRoot";
import getAuthState from "../../getAuthState";
import { Helmet } from "react-helmet";

const Title = styled.h1`
  font-size: 28px;
  margin-bottom: 10px;
  @media screen and (max-width: 600px) {
    font-size: 20px;
  }
`;

const Subtitle = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.6);
  @media screen and (max-width: 400px) {
    font-size: 12px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

const Button = styled.button`
  padding: 6px 25px;
  background: linear-gradient(135deg, hsl(160, 95%, 35%), hsl(220, 92%, 45%));
  color: white;
  font-weight: 600;
  outline: none;
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  transition: filter 0.2s;
  :hover {
    filter: brightness(110%);
  }
  svg {
    margin-left: 10px;
    margin-right: -5px;
  }
`;

const ErrorMsg = styled.div`
  color: hsl(5, 85%, 75%);
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  svg {
    margin-right: 10px;
    min-width: 24px;
  }
  @media screen and (max-width: 600px) {
    margin-bottom: 10px;
  }
`;

const FinalizePublishingUser = (props) => {
  let [authState, setAuthState] = useGlobal("authState");
  let [, setSocieties] = useGlobal("societies");
  let [error, setError] = useState("");
  let [working, setWorking] = useState(false);
  let [done, setDone] = useState(false);

  const submit = async (e) => {
    e.preventDefault();
    setWorking(true);
    const response = await axios.post(
      apiRoot,
      {
        query: `
      mutation activateInvite($code: GenericString!) {
        activatePubAccJoinInvite(code: $code)
      }`,
        variables: {
          code: props.code,
        },
      },
      { withCredentials: true }
    );
    setWorking(false);
    if (response.data.errors) return setError(response.data.errors[0].message);
    setDone(true);
    await getAuthState(setAuthState, setSocieties);
  };

  if (done) return <Redirect to="/dashboard" />;
  if (!authState.loading && !authState.authenticated)
    return <Redirect to="/login" />;

  return (
    <div>
      <Helmet>
        <title>Publishing Account Invitation | Music Inc. Client Portal</title>
      </Helmet>
      <Title>You've been invited to join a publishing account</Title>
      <Subtitle>
        {props.invite.paName} uses Music Inc. to administer their music
        publishing rights and collect royalties, and has invited you to join
        their account as{" "}
        {props.invite.paRole === "view_only" ? "a viewer" : "an administrator"}.
      </Subtitle>
      {props.code && (
        <Subtitle>
          To add this publishing account to your Music Inc. account, click the
          button below.
        </Subtitle>
      )}
      {props.code && (
        <ButtonContainer>
          {error ? (
            <ErrorMsg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path
                  fill="hsl(5, 100%, 70%)"
                  d="M12 2a10 10 0 1 1 0 20 10 10 0 0 1 0-20zm0 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16zm0 9a1 1 0 0 1-1-1V8a1 1 0 0 1 2 0v4a1 1 0 0 1-1 1zm0 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
                />
              </svg>
              {error}
            </ErrorMsg>
          ) : (
            <div />
          )}
          <Button onClick={submit} disabled={working}>
            Accept invitation
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              height="24"
              width="24"
            >
              <path
                fill="white"
                d="M14.59 13H7a1 1 0 0 1 0-2h7.59l-2.3-2.3a1 1 0 1 1 1.42-1.4l4 4a1 1 0 0 1 0 1.4l-4 4a1 1 0 0 1-1.42-1.4l2.3-2.3z"
              />
            </svg>
          </Button>
        </ButtonContainer>
      )}
      {!props.code && (
        <Subtitle>
          By activating your invite, you've joined this publishing account. Let
          us know if you have any questions.
        </Subtitle>
      )}
    </div>
  );
};

export default FinalizePublishingUser;
