import React, { useState } from "react";
import { useEffect, useGlobal } from "reactn";
import axios from "axios";
import { format } from "date-fns";
import styled from "styled-components";
import apiRoot from "../../apiRoot";
import Checkbox from "../../components/Checkbox";
import Tooltip from "../../components/Tooltip";
import Contract from "../userSetup/Contract";
import PubInviteUser from "./PubInviteUser";

const Container = styled.div`
  max-width: 400px;
`;

const Divider = styled.div`
  width: 90%;
  margin: 30px auto;
  height: 1px;
  background: rgba(255, 255, 255, 0.15);
`;

const InputGroup = styled.div`
  h3 {
    color: white;
    margin-top: 0;
    margin-bottom: 10px;
  }
`;

const Label = styled.label`
  font-weight: 500;
  color: rgba(255, 255, 255, 0.6);
  font-size: 12px;
`;

const Input = styled.input`
  background: transparent;
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-radius: 2px;
  width: calc(100% - 24px);
  padding: 6px 10px;
  outline: none;
  transition: border-color 0.2s;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;
  margin-bottom: 10px;
  :focus {
    border-color: rgba(255, 255, 255, 0.3);
  }
`;

const Text = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 10px;
`;

const Button = styled.button`
  cursor: pointer;
  background: rgba(255, 255, 255, 0.2);
  color: hsl(221, 92%, 75%);
  font-weight: 600;
  display: block;
  text-align: center;
  padding: 8px;
  width: 100%;
  border: none;
  border-radius: 2px;
  transition: background 0.2s;
  outline: none;
  margin-top: 10px;
  :hover {
    background: rgba(255, 255, 255, 0.25);
  }
  &:disabled {
    background: rgba(255, 255, 255, 0.1);
    cursor: default;
  }
`;

const Divider2 = styled.div`
  margin-bottom: 20px;
`;

const ErrorMsg = styled.div`
  color: hsl(5, 85%, 75%);
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  svg {
    margin-right: 10px;
  }
`;

const SuccessMsg = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 20px;
`;

const ContractContainer = styled.div`
  max-height: 600px;
  overflow-y: auto;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
  margin-top: 10px;
  padding-right: 10px;
  p {
    margin-top: 0;
  }
  ::-webkit-scrollbar {
    width: 14px;
    height: 18px;
  }
  ::-webkit-scrollbar-thumb {
    min-height: 30px;
    border: 4px solid transparent;
    background-clip: padding-box;
    -webkit-border-radius: 7px;
    background-color: rgba(255, 255, 255, 0.2);
  }
  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }
  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
`;

const ShowButton = styled.div`
  padding: 6px 12px;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.9);
  background: rgba(255, 255, 255, 0.1);
  cursor: pointer;
  transition: background 0.2s;
  display: inline-block;
  border-radius: 4px;
  margin-bottom: 10px;
  font-weight: 500;
  :hover {
    background: rgba(255, 255, 255, 0.15);
  }
`;

const UserComponent = styled.div`
  margin-bottom: 25px;
`;

const Details = styled.div``;

const UserName = styled.h4`
  font-size: 16px;
  color: rgba(255, 255, 255, 0.95);
  font-weight: 500;
  margin: 0;
  small {
    letter-spacing: 0.4px;
    display: inline-block;
    margin-left: 5px;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.7);
    background: rgba(255, 255, 255, 0.1);
    border-radius: 3px;
    padding: 2px 6px;
  }
`;

const UserEmail = styled.div`
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
  font-weight: 500;
`;

const PubSettings = (props) => {
  let [authState, setAuthState] = useGlobal("authState");
  let [loading, setLoading] = useState(true);
  let [viewContract, setViewContract] = useState(false);
  let [name, setName] = useState(null);
  let [allowSubmissionReq, setAllowSubmissionReq] = useState(null);
  let [users, setUsers] = useState(null);
  let [invites, setInvites] = useState(null);
  let [basicDetails, setBasicDetails] = useState(null);
  let [working1, setWorking1] = useState(false);
  let [working2, setWorking2] = useState(false);
  let [error1, setError1] = useState("");
  let [error2, setError2] = useState("");
  let [startRemove, setStartRemove] = useState(null);
  let [startRemoveI, setStartRemoveI] = useState(null);

  async function getInfo() {
    const info = await axios.post(
      apiRoot,
      {
        query: `
      query getPublishingAccount($id: Int!) {
        publishingAccount(id: $id) {
          name
          type
          administration_fee
          submission_requests_enabled
          users {
            user {
              id
              name
              email
            }
            isMe
            role
            created_at
          }
          pendingInvites {
            id
            email
            role
            created_at
          }
        }
      }`,
        variables: { id: props.id },
      },
      { withCredentials: true }
    );
    const pa = info.data.data.publishingAccount;
    if (!pa) return;
    setName(pa.name);
    setBasicDetails({
      type: pa.type,
      administration_fee: pa.administration_fee,
    });
    setUsers(pa.users);
    setInvites(pa.pendingInvites);
    setAllowSubmissionReq(pa.submission_requests_enabled);
    setLoading(false);
  }

  useEffect(() => {
    setViewContract(false);
    getInfo();
    // eslint-disable-next-line
  }, [props.id]);

  async function rename() {
    if (!name) return setError1("Name required!");
    if (name.length > 50)
      return setError1("Name must be 50 characters or less!");
    setWorking1(true);
    const response = await axios.post(
      apiRoot,
      {
        query: `mutation changeInfo($name: GenericString!, $id: Int!) {
        publishingAccount(id: $id) {
          rename(name: $name)
        }
      }`,
        variables: { name, id: props.id },
      },
      { withCredentials: true }
    );
    if (response.data.errors) setError1(response.data.errors[0].message);
    else setError1("");
    setWorking1(false);
    setAuthState({
      ...authState,
      publishingAccounts: authState.publishingAccounts.map((i) =>
        i.id === props.id ? { ...i, name } : i
      ),
      publishingAccount:
        authState.publishingAccount.id === props.id
          ? { ...authState.publishingAccount, name }
          : authState.publishingAccount,
    });
  }

  async function changeSubReq() {
    setWorking2(true);
    const response = await axios.post(
      apiRoot,
      {
        query: `mutation changeInfo($allow: Boolean!, $id: Int!) {
        publishingAccount(id: $id) {
          changeSubmissionRequestSettings(allow: $allow)
        }
      }`,
        variables: { allow: allowSubmissionReq, id: props.id },
      },
      { withCredentials: true }
    );
    if (response.data.errors) setError2(response.data.errors[0].message);
    else setError2("");
    setWorking2(false);
  }

  async function changeRole(id, role) {
    const response = await axios.post(
      apiRoot,
      {
        query: `mutation changeInfo($id: Int!, $user: Int!, $role: PublishingAccountRole!) {
        publishingAccount(id: $id) {
          changeUserRole(user: $user, role: $role)
        }
      }`,
        variables: { id: props.id, user: id, role },
      },
      { withCredentials: true }
    );
    if (response.data.errors) setError2(response.data.errors[0].message);
    else setError2("");
    setUsers(users.map((i) => (i.user.id === id ? { ...i, role } : i)));
  }

  async function removeUser(id) {
    const response = await axios.post(
      apiRoot,
      {
        query: `mutation changeInfo($id: Int!, $user: Int!) {
        publishingAccount(id: $id) {
          removeUser(user: $user)
        }
      }`,
        variables: { id: props.id, user: id },
      },
      { withCredentials: true }
    );
    if (response.data.errors) setError2(response.data.errors[0].message);
    else setError2("");
    setUsers(users.filter((i) => i.user.id !== id));
  }

  async function revokeInvite(id) {
    const response = await axios.post(
      apiRoot,
      {
        query: `mutation changeInfo($id: Int!, $invite: Int!) {
        publishingAccount(id: $id) {
          revokeInvite(id: $invite)
        }
      }`,
        variables: { id: props.id, invite: id },
      },
      { withCredentials: true }
    );
    if (response.data.errors) setError2(response.data.errors[0].message);
    else setError2("");
    setInvites(invites.filter((i) => i.id !== id));
  }

  if (loading)
    return (
      <Container>
        <Label>Loading...</Label>
      </Container>
    );

  let isOwner = users.filter((i) => i.isMe)[0].role === "owner";

  return (
    <Container>
      <InputGroup>
        <h3>Basic Details</h3>
        <Label>Publishing account type</Label>
        <Text>
          {basicDetails.type === "songwriter" ? "Songwriter" : "Publisher"}
        </Text>
        <Label>
          Administration fee
          <Tooltip>
            We take this percentage of the publishing royalties we collect as a
            fee for our service, and send the rest to you. You agreed to this
            fee when signing our administration contract while activating your
            account.
          </Tooltip>
        </Label>
        <Text>{basicDetails.administration_fee * 100}%</Text>
        <Label>Contract</Label>
        <br />
        {viewContract ? (
          <ContractContainer>
            <Contract fee={basicDetails.administration_fee * 100} />
          </ContractContainer>
        ) : (
          <ShowButton onClick={() => setViewContract(true)}>
            Show contract
          </ShowButton>
        )}
        <br />
        <Label>Publishing account name</Label>
        {isOwner ? (
          <Input value={name} onChange={(e) => setName(e.target.value)}></Input>
        ) : (
          <Text>{name}</Text>
        )}
        {error1 && (
          <ErrorMsg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path
                fill="hsl(5, 100%, 70%)"
                d="M12 2a10 10 0 1 1 0 20 10 10 0 0 1 0-20zm0 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16zm0 9a1 1 0 0 1-1-1V8a1 1 0 0 1 2 0v4a1 1 0 0 1-1 1zm0 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
              />
            </svg>
            {error1}
          </ErrorMsg>
        )}
        {isOwner && (
          <Button disabled={working1} onClick={rename}>
            Save changes
          </Button>
        )}
      </InputGroup>
      <Divider />
      {basicDetails.type === "songwriter" && (
        <InputGroup>
          <h3>Settings</h3>
          <SuccessMsg>
            Submission requests allow other Music Inc. users to send their
            composition submissions directly to your Music Inc. account when
            you're listed as a songwriter. If you want to collect on the
            composition through Music Inc., you can approve the details in just
            one click rather than adding it yourself. You can disable submission
            requests if you don't want to use this feature.
          </SuccessMsg>
          <Checkbox
            active={allowSubmissionReq}
            setActive={isOwner ? setAllowSubmissionReq : () => {}}
          >
            Allow submission requests
          </Checkbox>
          <Divider2 />
          {error2 && (
            <ErrorMsg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path
                  fill="hsl(5, 100%, 70%)"
                  d="M12 2a10 10 0 1 1 0 20 10 10 0 0 1 0-20zm0 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16zm0 9a1 1 0 0 1-1-1V8a1 1 0 0 1 2 0v4a1 1 0 0 1-1 1zm0 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
                />
              </svg>
              {error2}
            </ErrorMsg>
          )}
          {isOwner && (
            <Button disabled={working2} onClick={changeSubReq}>
              Save changes
            </Button>
          )}
        </InputGroup>
      )}
      {basicDetails.type === "songwriter" && <Divider />}
      <InputGroup>
        <h3>Users</h3>
        <SuccessMsg>
          Account owners have full control over the publishing account and its
          settings. Administrators have the ability to add and update
          compositions, but not to change publishing account settings or manage
          users. View only users can see compositions, royalty statements, and
          settings, but not make changes. If you want to transfer account
          ownership, please contact us at clientsupport@music.ceo.
        </SuccessMsg>
        {users.map((user) => (
          <UserComponent>
            <Details>
              <UserName>
                {user.user.name} {user.isMe && <small>YOU</small>}
              </UserName>
              <UserEmail>{user.user.email}</UserEmail>
              <UserEmail>
                Added{" "}
                {format(new Date(user.created_at), "MMMM d, yyyy, h:mm a")}
              </UserEmail>
              {user.role === "owner" && <UserEmail>Owner</UserEmail>}
              {user.role === "admin" && (
                <UserEmail>
                  Administrator
                  {isOwner && (
                    <span>
                      {" "}
                      ·{" "}
                      <span
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() => changeRole(user.user.id, "view_only")}
                      >
                        Change to View Only
                      </span>
                    </span>
                  )}
                </UserEmail>
              )}
              {user.role === "view_only" && (
                <UserEmail>
                  View Only
                  {isOwner && (
                    <span>
                      {" "}
                      ·{" "}
                      <span
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() => changeRole(user.user.id, "admin")}
                      >
                        Change to Administrator
                      </span>
                    </span>
                  )}
                </UserEmail>
              )}
              {isOwner &&
                user.role !== "owner" &&
                startRemove !== user.user.id && (
                  <UserEmail>
                    <span
                      style={{
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() => setStartRemove(user.user.id)}
                    >
                      Remove
                    </span>
                  </UserEmail>
                )}
              {startRemove === user.user.id && (
                <UserEmail>
                  <br />
                  Are you sure you want to remove this user's access?
                  <br />
                  <span
                    style={{
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => removeUser(user.user.id)}
                  >
                    Remove
                  </span>{" "}
                  ·{" "}
                  <span
                    style={{
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => setStartRemove(null)}
                  >
                    Cancel
                  </span>
                </UserEmail>
              )}
            </Details>
          </UserComponent>
        ))}
        {invites.map((user) => (
          <UserComponent>
            <Details>
              <UserName>
                {user.email} <small>INVITED</small>
              </UserName>
              <UserEmail>
                Invited{" "}
                {format(new Date(user.created_at), "MMMM d, yyyy, h:mm a")}
              </UserEmail>
              {user.role === "owner" && <UserEmail>Owner</UserEmail>}
              {user.role === "admin" && <UserEmail>Administrator</UserEmail>}
              {user.role === "view_only" && <UserEmail>View Only</UserEmail>}
              {isOwner && user.role !== "owner" && startRemoveI !== user.id && (
                <UserEmail>
                  <span
                    style={{
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => setStartRemoveI(user.id)}
                  >
                    Revoke invite
                  </span>
                </UserEmail>
              )}
              {startRemoveI === user.id && (
                <UserEmail>
                  <br />
                  Are you sure you want to revoke this user's invite?
                  <br />
                  <span
                    style={{
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => revokeInvite(user.id)}
                  >
                    Revoke
                  </span>{" "}
                  ·{" "}
                  <span
                    style={{
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => setStartRemoveI(null)}
                  >
                    Cancel
                  </span>
                </UserEmail>
              )}
            </Details>
          </UserComponent>
        ))}
        {isOwner && <PubInviteUser id={props.id} refresh={getInfo} />}
      </InputGroup>
    </Container>
  );
};

export default PubSettings;
