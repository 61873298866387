import React from "react";

const Contract = (props) => (
  <div>
    <p>
      The following agreement (“Agreement”) is made between Music Inc.
      (“Administrator”), a corporation registered in the Commonwealth of
      Virginia, United States, and the individual or entity signing this
      Agreement by registering for Administrator’s publishing administration
      services (“Copyright Owner”).
    </p>
    <h2>1. Assignment of Rights</h2>
    <p>
      Throughout the term of this Agreement, Copyright Owner assigns to
      Administrator the sole and exclusive right to administer and collect upon
      one hundred percent (100%) of the right, title, and interest in certain
      musical compositions owned or controlled by Copyright Owner
      (“Compositions”), whether created or owned now or at a future point in the
      term of this Agreement, with the exception of the right of
      synchronization, for which Copyright Owner shall retain certain
      administration, promotion, and collection rights as described below. The
      rights you grant to Administrator include, but are not limited to, the
      rights to perform the following duties throughout the Territory with
      respect to the Compositions:
    </p>
    <p>
      - To register the Compositions with performance rights, mechanical rights,
      and collective management organizations, in accordance with their terms
      and agreements, and to provide Letters of Direction which shall be
      executed by Copyright Owner should it already have registered works with
      these organizations, for the purpose of allowing Administrator to
      administer the relevant Compositions
    </p>
    <p>
      - To perform and provide licenses to perform the Compositions, publicly or
      privately, by any means now known or hereafter developed, including radio,
      television, the Internet, physical performances, or any other means by
      which Compositions may be publicly performed
    </p>
    <p>
      - To make or license to be made phonographic recordings or deliveries via
      any medium, including physical phonographic records, digital permanent
      phonographic downloads, streaming, podcasts, ringtones, transcriptions,
      soundtracks, or any other mechanical, electrical, or other reproductions
      of the Compositions, in whole or in part, and to use, manufacture,
      license, or sell these reproductions for any and all purposes
    </p>
    <p>
      - To print, publish, sell, or license others to do the same, copies of the
      Compositions in all forms, including sheet music or lyric reproductions,
      whether physically or digitally
    </p>
    <p>
      - To exercise and exploit any other rights which may exist with regard to
      the Compositions under any relevant laws, including but not limited to
      copyright laws, with the exception of certain synchronization licenses as
      described below
    </p>
    <p>
      - To grant synchronization rights in bulk, under “micro-sync” licenses,
      for uses including but not limited to blanket licenses of Compositions for
      uses within videos, “art tracks,” and other user-generated content on
      services such as YouTube, and to claim and monetize any content on these
      services which use the Compositions, unless Administrator is directed to
      end monetization of the videos in question, but not to negotiate and grant
      synchronization licenses for any traditional licenses, such as in films,
      television, commercials, and video games, or any other non-bulk usages by
      an individual grantee or licensee; this right is withheld by Copyright
      Owner which may sign these licenses individually and collect relevant fees
    </p>
    <p>
      - To administer and collect royalty payments or other monies derived from
      the exploitation of the Compositions under any licenses or right types
      permitted under this Agreement
    </p>
    <p>
      - To use the names (real and professional) and other information about the
      writers, publishers, administrators, and other parties involved in the
      creation and promotion of the Compositions, for the purposes of
      registering Compositions with collection societies and for the marketing
      of the services of Administrator
    </p>
    <p>
      You acknowledge that licenses granted by Administrator may extend beyond
      the term of this Agreement, and you authorize Administrator to enter into
      such licenses. You also permit Administrator to collect any royalties
      which have been earned but not collected at the time of registration,
      which shall be paid out under the same terms as any royalties earned and
      collected during the term of the Agreement or after the point of
      registration.
    </p>
    <h2>2. Royalties and Accounting</h2>
    <p>
      Administrator shall pay to Copyright Owner {100 - props.fee}% of monies
      received by Administrator in relation to the exploitation of the
      Compositions, where the remainder shall be withheld by Administrator as
      its administrative fee for services performed. Such monies shall be
      accounted to Copyright Owner on a monthly basis, within fourteen (14) days
      of the end of the month, where the monies that shall be included in
      royalty statements and amounts payable in such an accounting period shall
      be those which have been fully paid to and collected by Administrator and
      for which all information necessary to accurately match royalties to the
      applicable compositions and owners has been delivered to Administrator
      within the month for which the royalty statement is being prepared. These
      monies shall be calculated based on the amounts received by Administrator
      or its international subsidiaries, but after the deduction of any fees
      which may be charged by collecting societies, sub-publishers, or other
      similar entities not controlled by Administrator. Only monies which can be
      authoritatively attributed to the exploitation of the works of the
      Administrator shall be considered payable; in the event that Administrator
      receives amounts as a payment based on market share for uncollected
      royalties or enters into settlements or otherwise receives single payments
      based on the musical compositions of all copyright owners which have
      entered into agreements with Administrator, these amounts shall be
      distributed among copyright owners by any means chosen by Administrator
      and then paid to Copyright Owner under the same terms as outlined above.
      Copyright Owner must register with Administrator’s payments site and enter
      payout information and request a payout in order to receive any monies
      which are payable. For most payment methods, if the total amount payable
      does not exceed $10 USD, Administrator shall withhold unpaid amounts until
      the next accounting period in which the amount payable exceeds $10 USD.
      Administrator may set other payment thresholds for certain payment
      methods, and may impose fees associated with requesting a payout by
      certain methods, but must provide at least one payment method with a
      threshold of $10 USD and no payment fee. All royalty statements and
      amounts rendered to Copyright Owner shall be binding upon Copyright Owner.
      Any issues in accounting which are discovered and corrected by
      Administrator shall not be deemed a material breach of this Agreement.
      Administrator may be required to withhold certain monies from amounts
      payable under the tax laws of the United States and tax treaties between
      the United States and the Copyright Owner’s country of tax residence, and
      such amounts shall be communicated to Copyright Owner at the time a payout
      is requested and withheld from the balance paid.
    </p>
    <h2>3. Delivery of Works, Warranties, and Indemnification</h2>
    <p>
      In order for Administrator to perform its administrative services,
      Copyright Owner must deliver details on the works it controls through
      Administrator’s online client portal. The Compositions included under this
      Agreement shall only include those registered by Copyright Owner through
      the online portal, or which Copyright Owner permits or directs
      Administrator to register on their behalf, and any other compositions
      owned or controlled by Copyright Owner shall be excluded from this
      Agreement. Copyright Owner may sign any other agreements or collect
      independently on any works which are not registered in the client portal.
      By registering a work through the client portal or otherwise delivering
      shares to Administrator, Copyright Owner warrants and represents the
      following:
    </p>
    <p>
      - Copyright Owner controls a share in the composition’s collection rights,
      and the shares entered and marked as controlled are accurate and only
      those which Copyright Owner owns or controls
    </p>
    <p>
      - All other information associated with the registration and with
      Copyright Owner’s account in the client portal is accurate to the best of
      Copyright Owner’s knowledge
    </p>
    <p>
      - Copyright Owner has all necessary authority to enter into this
      agreement, without the consent of any third party, and Copyright Owner has
      not already signed a publishing, co-publishing, administration, or
      songwriter agreement that conflicts with the terms of this Agreement
    </p>
    <p>
      - None of the material making up the Compositions is a part of any other
      copyrighted work owned by a third-party which is being used without
      permission, and no part of the Compositions infringes upon any legal
      rights owned by a third-party; the Compositions do not embody any
      unauthorized samples, interpolations, arrangements, or other uses of
      third-party works
    </p>
    <p>
      - No liens or encumbrances exist on the Compositions, and Administrator
      shall not be required to make any payments relating to the exploitation of
      the Compositions other than to the Copyright Owner as set forth herein
    </p>
    <p>
      Copyright Owner will not attempt to abuse, interfere with, or manipulate
      the functioning of the client portal or other technology created or made
      available by Administrator, recognizes that such software is the sole
      property of Administrator, and will not attempt to disassemble, reverse
      engineer, or otherwise use the copyrighted intellectual property of
      Administrator embodied in such technology for any non-permitted uses.
    </p>
    <p>
      If any of these warranties are found by Administrator to be inaccurate or
      are believed to be inaccurate, or Administrator otherwise believes that
      you are engaged in any fraudulent activities, Administrator may freeze
      funds associated with your account or disable other activities, and any
      costs incurred by Administrator if your activities are found to be
      fraudulent or in violation of these warranties may be deducted from monies
      otherwise payable. Certain licensees or collection societies may have
      other policies relating to fraud, overclaims, or other such circumstances
      that may limit the ability of funds to be paid, and you agree that any of
      these policies or agreements are binding upon you and may affect our
      ability to collect and pay royalties to you.
    </p>
    <p>
      Copyright Owner hereby indemnifies, saves and holds Administrator, its
      successors and assigns, and its parent, subsidiary and affiliated
      companies and its and their respective officers, employees and agents
      harmless from any and all liability, claims, demands, loss and damage
      (including without limitation, reasonable attorneys’ fees and court costs)
      arising from or connected with any claim, demand, or action (collectively,
      “Claim”) which is inconsistent with any of the warranties,
      representations, or agreements made or assumed by Copyright Owner in this
      Agreement. Should any Claim be leveraged in conjunction or relation to the
      Compositions or limited relationship between Administrator and Copyright
      Owner, it shall wholly be the responsibility of Copyright Owner to
      compensate the Administrator or any of its according firms whether
      financially or in some other form deemed acceptable in a court of law. In
      no event shall either party be liable for any indirect, incidental,
      consequential, or special damages whatsoever arising from or related to
      any breach or violation of this agreement.
    </p>
    <h2>4. Term and Termination</h2>
    <p>
      This Agreement shall have an initial Term of six (6) calendar months from
      the date of signing. After that point, this Agreement shall continue to be
      renewed automatically and indefinitely, with no action required by either
      party. If either party wishes to terminate the Agreement, it can be
      terminated by notifying the other party, and such termination will go into
      effect after a minimum of thirty (30) days, given that the date of
      termination is after the end of the initial Term. During or after that
      time, Administrator will sign any necessary letters of direction to assign
      the administrative rights to the Compositions to another party chosen by
      Copyright Owner. Administrator may continue to receive some royalties for
      works registered during the Term or uses which occurred during the Term.
      In the event that Administrator receives any royalties after the Agreement
      has been terminated, they shall be accounted to Copyright Owner as if the
      Agreement was still in effect. If Administrator terminates the agreement
      but Copyright Owner fails to establish other administration for the
      Compositions, Administrator may withhold royalty payment until such time
      as Copyright Owner has transferred the registration of the Compositions to
      another party.
    </p>
    <h2>5. Miscellaneous</h2>
    <p>
      Copyright Owner irrevocably appoints the Administrator and authorized
      agent as the attorney-in-fact (with full power of substitution and
      delegation) to take such action and to execute and deliver any and all
      instruments or documents which Administrator from time to time deems
      reasonably desirable or necessary to vest in Administrator, its successors
      and assigns, all administrative rights to the Compositions granted by
      Copyright Owner hereunder.
    </p>
    <p>
      Administrator shall have the right to freely assign, in whole or in part,
      this Agreement and any or all of its rights hereunder. Copyright Owner
      shall not have the right or power to assign this Agreement, or any of
      Copyright Owner’s rights or obligations hereunder, and any such purported
      assignment will be void from the making thereof.
    </p>
    <p>
      Copyright Owner acknowledges that exploitation of the Compositions is in
      no way guaranteed to result in any amounts payable under this agreement,
      and waives any claims that Administrator could have collected a greater
      amount payable than was collected.
    </p>
    <p>
      Copyright Owner expressly waives all so-called “moral rights” in the Works
      and to the extent any such waiver is not enforceable pursuant to the laws
      of any country of the Territory then the Copyright Owner hereby agrees not
      to assert such “moral rights”.
    </p>
    <p>
      Copyright Owner shall at all times keep all of Administrator’s
      confidential information in confidence and trust. Copyright Owner shall
      not use such confidential information other than: (i) as expressly
      permitted herein or (ii) with the prior written approval of Administrator.
      Copyright Owner shall not directly or indirectly disclose the terms of
      this Agreement to any person of the public or non-essential person without
      prior approval of Administrator, unless otherwise required to do so by any
      law established by any government with applicable jurisdiction.
    </p>
    <p>
      Paragraph headings contained in this Agreement are for convenience only
      and shall not be considered for any purpose in construing this agreement.
    </p>
    <p>
      Any notice required or permitted under this Agreement shall be in writing
      and shall be deemed given when delivered personally or ten (10) days after
      being sent by email to Administrator. That said, neither party shall be
      deemed to be in breach of such party’s obligation without any form of
      formal writing indicating the nature of such breach, and a failure to cure
      such breach within thirty (30) days after receipt of such notice.
    </p>
    <p>
      This Agreement constitutes the entire agreement between the parties hereto
      concerning the matters covered herein and supersedes all prior agreements
      or understandings between the parties whether written or oral, concerning
      aforementioned matters covered herein. In the event that any of the terms
      of this Agreement become or are illegal or unenforceable, such terms shall
      be null and void and shall be deemed deleted from this Agreement, and all
      the remaining terms of this Agreement shall remain in full force and
      effect. The parties may only amend this Agreement in writing, signed by
      duly authorized officers of the parties. No waiver of any provision of
      this Agreement, nor consent to any departure by either party herefrom,
      shall in any event be effective unless the same shall be in writing and
      signed by a duly authorized officer of the party to be charged with the
      waiver or consent, and then such waiver or consent shall be effective only
      in the specific instance and for the specific purpose for which is given.
      This Agreement shall be governed by the laws of the Commonwealth of
      Virginia and the United States of America, and the parties agree to the
      jurisdiction of the City of Norfolk, Virginia to resolve any disputes
      arising from this Agreement.
    </p>
  </div>
);

export default Contract;
