import React from "react";
import { Redirect, Link } from "react-router-dom";
import { useGlobal } from "reactn";
import styled from "styled-components";

const MainComponent = styled.div`
  background: rgba(255, 255, 255, 0.05);
  max-width: 1200px;
  width: 100%;
  padding: 30px 40px;
  box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  min-height: calc(100vh - 160px);
  @media screen and (max-width: 400px) {
    padding: 20px;
  }
`;

const MainTitle = styled.h1`
  margin-top: 15px;
  color: rgba(255, 255, 255, 0.95);
  @media screen and (max-width: 800px) {
    font-size: 24px;
  }
`;

const Subtitle = styled.p`
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  max-width: 800px;
  margin-bottom: 30px;
`;

const Services = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr;
  max-width: 100%;
  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`;

const ServiceBlock = styled.a`
  border-radius: 6px;
  cursor: pointer;
  padding: 20px;
  background: ${(props) => props.gradient};
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.5);
  text-decoration: none;
  transition: box-shadow 0.2s, filter 0.2s;
  :hover {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.6);
    filter: brightness(1.1);
  }
`;

const ServiceBlockInt = ServiceBlock.withComponent(Link);

const ServiceTitle = styled.h3`
  color: rgba(255, 255, 255, 0.95);
  font-size: 26px;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 20px;
  cursor: pointer;
  text-shadow: 0 1px 7px rgba(0, 0, 0, 0.3);
`;

const ServiceText = styled.p`
  font-weight: 500;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.95);
  margin: 0;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
`;

const Dashboard = (props) => {
  const [authState] = useGlobal("authState");
  // eslint-disable-next-line
  let preReturn;

  if (authState && !authState.loading && !authState.authenticated)
    preReturn = <Redirect to="/login" />;
  if (
    authState &&
    !authState.loading &&
    authState.authenticated &&
    (authState.setupStatus !== "done" ||
      (authState.publishingAccount &&
        authState.publishingAccount.setupStatus !== "done"))
  ) {
    if (authState.setupStatus === "needs_email_confirmation")
      preReturn = <Redirect to="/confirmEmail" />;
    if (authState.setupStatus === "needs_publishing_account")
      preReturn = <Redirect to="/setup/contract" />;
    if (
      authState.setupStatus === "needs_songwriter" ||
      authState.publishingAccount?.setupStatus === "needs_songwriter"
    )
      preReturn = <Redirect to="/setup/songwriter" />;
  }

  if (preReturn) return preReturn;

  return (
    <MainComponent>
      <MainTitle>Your Music Inc. Services</MainTitle>
      <Subtitle>
        The main Music Inc. client portal is built primarily around publishing
        administration, but other services provided by Music Inc. might require
        visiting a separate portal, like the payment portal. You can see and
        access all the services you have access to below.
      </Subtitle>
      <Services>
        {authState?.publishingAccounts?.length > 0 && (
          <ServiceBlockInt
            to="/publishing"
            gradient="linear-gradient(135deg, hsl(270, 92%, 47%), hsl(288, 92%, 38%))"
          >
            <ServiceTitle>Publishing Administration</ServiceTitle>
            <ServiceText>
              View your submitted compositions and register new compositions to
              collect your music publishing royalties
            </ServiceText>
          </ServiceBlockInt>
        )}
        <ServiceBlock
          href="https://payments.music.ceo"
          gradient="linear-gradient(135deg, hsl(220, 92%, 47%), hsl(200, 92%, 35%))"
        >
          <ServiceTitle>Payment Portal</ServiceTitle>
          <ServiceText>
            Check your balance and transactions, request payouts, and manage
            payment methods and tax forms
          </ServiceText>
        </ServiceBlock>
        {authState?.user?.hasLabelArtists && (
          <ServiceBlock
            href="https://statements.music.ceo"
            gradient="linear-gradient(135deg, hsl(25, 92%, 41%), hsl(47, 94%, 43%))"
          >
            <ServiceTitle>Label Statements</ServiceTitle>
            <ServiceText>
              View record label royalty statements, including information on
              advances and breakdowns of revenue sources
            </ServiceText>
          </ServiceBlock>
        )}
        {(authState?.user?.admin || authState?.user?.referrer) && (
          <ServiceBlock
            href="https://admin.music.ceo"
            gradient="linear-gradient(135deg, hsl(140, 92%, 34%), hsl(170, 94%, 40%))"
          >
            <ServiceTitle>Admin Dashboard</ServiceTitle>
            <ServiceText>
              Create invites (for referrers), and for administrative users,
              manage compositions, registrations, payments, and more
            </ServiceText>
          </ServiceBlock>
        )}
      </Services>
    </MainComponent>
  );
};

export default Dashboard;
