import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { Link, useRouteMatch } from "react-router-dom";
import { Helmet } from "react-helmet";
import apiRoot from "../apiRoot";

const Page = styled.div`
  padding: 80px 20px;
  max-width: 1200px;
  margin: 0 auto;
  color: rgba(255, 255, 255, 0.8);
  @media screen and (max-width: 800px) {
    padding: 20px;
    h1 {
      font-size: 24px;
    }
  }
`;

const Button = styled.button`
  padding: 6px 16px;
  background: linear-gradient(135deg, hsl(160, 95%, 35%), hsl(220, 92%, 45%));
  color: white;
  font-weight: 600;
  outline: none;
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  transition: filter 0.2s;
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 14px;
  text-decoration: none;
  :hover {
    filter: brightness(110%);
  }
  svg {
    margin-right: 10px;
    margin-bottom: 1px;
    margin-top: -1px;
  }
`;

const ButtonLink = Button.withComponent(Link);

const types = {
  submission_requests_email: "submission request",
  royalty_statement_email: "royalty statement notification",
  email_on_other_deposit: "Music Inc. Payments deposit",
  email_on_payment_send: "payout notification",
  label_statement_email: "label statement notification",
};

const Unsubscribe = (props) => {
  const match = useRouteMatch();

  const [error, setError] = useState(null);
  const [emailType, setEmailType] = useState(null);
  const [unsubbedAll, setUnsubbedAll] = useState(false);

  useEffect(() => {
    async function unsub() {
      // Unsubscribe
      const response = await axios.post(
        apiRoot,
        {
          query: `
      mutation unsubscribe($token: GenericString!) {
        unsubscribe(token: $token)
      }`,
          variables: { token: match.params.token },
        },
        { withCredentials: true }
      );
      // Set error if failed
      if (response.data.errors)
        return setError(response.data.errors[0].message);
      // If not, set email type
      else setEmailType(response.data.data.unsubscribe);
    }
    unsub();
  }, [match.params.token]);

  async function unsubAll() {
    const response = await axios.post(
      apiRoot,
      {
        query: `mutation changeInfo($submission_request: Boolean!, $royalty_statement: Boolean!, $other_deposit: Boolean!, $payment_send: Boolean!, $label_statement: Boolean!) {
          changeEmailSettings(submission_request:$submission_request,royalty_statement:$royalty_statement,other_deposit:$other_deposit,payment_send:$payment_send,label_statement:$label_statement)
        }`,
        variables: {
          submission_request: false,
          royalty_statement: false,
          other_deposit: false,
          payment_send: false,
          label_statement: false,
        },
      },
      { withCredentials: true }
    );
    if (response.data.errors) return setError(response.data.errors[0].message);
    else setUnsubbedAll(true);
  }

  return (
    <Page>
      <Helmet>
        <title>Unsubscribe | Music Inc. Client Portal</title>
      </Helmet>
      {!error && !emailType && <h1>Working...</h1>}
      {error && (
        <div>
          <h1>Unsubscribe failed</h1>
          <p>{error}</p>
        </div>
      )}
      {emailType && !unsubbedAll && (
        <div>
          <h1>You've been unsubscribed from {types[emailType]} emails.</h1>
          <p>Want to disable all optional email notifications?</p>
          <Button onClick={unsubAll}>Unsubscribe from all emails</Button>
          <br />
          <ButtonLink to="/settings">Email Settings</ButtonLink>
        </div>
      )}
      {unsubbedAll && (
        <div>
          <h1>You're unsubscribed from all notification emails.</h1>
          <ButtonLink to="/settings">Email Settings</ButtonLink>
        </div>
      )}
    </Page>
  );
};

export default Unsubscribe;
