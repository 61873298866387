import React, { useState, useEffect } from "react";
import { useGlobal } from "reactn";
import { Link } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import apiRoot from "../../apiRoot";
import InfiniteScroll from "react-infinite-scroller";
import { Helmet } from "react-helmet";
import { SongwriterSort } from "./FilterSort";

const Container = styled.div`
  display: flex;
  margin-top: 30px;
  @media screen and (max-width: 400px) {
    margin-top: 10px;
  }
`;

const ErrorMsg = styled.div`
  color: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;
  margin-top: 25px;
  svg {
    margin-right: 10px;
    min-width: 32px;
  }
`;

const LeftSide = styled.div`
  flex: 1;
`;

const Writer = styled(Link)`
  text-decoration: none;
  display: block;
  padding: 20px 0;
  border-bottom: ${(props) => (props.last ? "0" : "1px")} solid
    rgba(255, 255, 255, 0.2);
`;

const WriterName = styled.h3`
  color: white;
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 24px;
  @media screen and (max-width: 800px) {
    font-size: 18px;
    margin-bottom: 10px;
  }
`;

const Metadata = styled.p`
  color: rgba(255, 255, 255, 0.8);
  margin: 0;
  font-size: 14px;
  a {
    color: rgba(255, 255, 255, 0.8);
  }
  @media screen and (max-width: 400px) {
    font-size: 12px;
  }
`;

const NoWriters = styled.div`
  text-align: center;
`;

const Input = styled.input`
  background: rgba(255, 255, 255, 0.05);
  border: none;
  border-radius: 2px;
  width: calc(100% - 24px);
  padding: 6px 10px;
  outline: none;
  transition: background 0.2s;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;
  display: block;
  :focus {
    background: rgba(255, 255, 255, 0.1);
  }
  ::placeholder {
    color: rgba(255, 255, 255, 0.4);
  }
`;

const You = styled.span`
  margin-left: 10px;
  background: rgba(255, 255, 255, 0.1);
  padding: 2px 10px;
  border-radius: 10px;
  letter-spacing: 0.5px;
  font-weight: 500;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
`;

const Count = styled.p`
  color: rgba(255, 255, 255, 0.6);
  font-weight: 500;
  margin: 4px 0 0 0;
  font-size: 12px;
`;

const FlexContainer = styled.div`
  margin: 10px 0;
  display: flex;
  align-items: center;
`;

const SortOpen = styled.div`
  display: flex;
  cursor: pointer;
  margin-left: 15px;
  color: rgba(255, 255, 255, 0.6);
  transition: color 0.2s;
  @media screen and (min-width: 801px) {
    display: none;
  }
  :hover {
    color: rgba(255, 255, 255, 0.8);
  }
  svg {
    width: 24px;
    height: 24px;
  }
`;

const Songwriters = () => {
  const [authState] = useGlobal("authState");
  let [writers, setWriters] = useState({ writers: [] });
  let [sortDirection, setSortDirection] = useState("asc");
  let [sortBy, setSortBy] = useState("name");
  let [loading, setLoading] = useState(false);
  let [error, setError] = useState("");
  let [filter, setFilter] = useState("");
  let [sortOpen, setSortOpen] = useState(false);
  let [loadOffset, setLoadOffset] = useState(0);

  async function getWriters(offset = 0, replace = false) {
    if (!authState?.publishingAccount) return;
    if (offset < writers.writers.length && offset > 0) return;
    setLoading(true);
    const response = await axios.post(
      apiRoot,
      {
        query: `
          query getWriters($id: Int!, $offset: Int!, $sortBy: WriterSortBy, $sortOrder: SortOrder) {
            publishingAccount(id: $id) {
              writers(offset: $offset, sortBy: $sortBy, sortOrder: $sortOrder) {
                writers {
                  id
                  first_name
                  middle_name
                  last_name
                  ipi
                  society
                  work_count
                }
                isNextPage
              }
            }
          }`,
        variables: {
          id: authState.publishingAccount.id,
          offset,
          sortBy,
          sortOrder: sortDirection,
        },
      },
      { withCredentials: true }
    );
    setLoading(false);
    setLoadOffset(
      offset + response?.data?.data?.publishingAccount?.writers?.writers.length
    );
    if (response.data.errors) return setError(response.data.errors[0].message);
    if (replace && response?.data?.data?.publishingAccount?.writers) {
      setWriters({
        writers: response?.data?.data?.publishingAccount?.writers?.writers,
        isNextPage: response.data.data.publishingAccount.writers.isNextPage,
      });
    } else if (response?.data?.data?.publishingAccount?.writers) {
      let newWriters =
        response.data.data.publishingAccount.writers.writers.filter(
          (i) =>
            (writers ? writers.writers : []).findIndex((x) => x.id === i.id) ===
            -1
        );
      if (newWriters) {
        setWriters({
          writers: [...(writers ? writers.writers : []), ...newWriters],
          isNextPage: response.data.data.publishingAccount.writers.isNextPage,
        });
      } else
        setWriters({
          writers: writers ? writers.writers : [],
          isNextPage: response.data.data.publishingAccount.writers.isNextPage,
        });
    }
  }

  const pubAccId = authState?.publishingAccount?.id;
  useEffect(() => {
    getWriters(0, true);
    // eslint-disable-next-line
  }, [pubAccId, sortBy, sortDirection]);

  const filterWriters = (writers?.writers || []).filter((i) => {
    return (
      (i.first_name ? `${i.first_name} ` : "") +
      (i.middle_name ? `${i.middle_name} ` : "") +
      i.last_name
    )
      .toLowerCase()
      .includes(filter.toLowerCase());
  });

  const me = authState?.publishingAccount?.songwriter;

  return (
    <Container>
      <Helmet>
        <title>Your Songwriters | Music Inc. Client Portal</title>
      </Helmet>
      <LeftSide>
        {error && (
          <ErrorMsg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              class="icon-important"
              width="32"
              height="32"
            >
              <path
                fill="rgba(255,255,255,0.2)"
                d="M12 2a10 10 0 1 1 0 20 10 10 0 0 1 0-20z"
              />
              <path
                fill="rgba(255,255,255,0.9)"
                d="M12 18a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm1-5.9c-.13 1.2-1.88 1.2-2 0l-.5-5a1 1 0 0 1 1-1.1h1a1 1 0 0 1 1 1.1l-.5 5z"
              />
            </svg>
            {error}
          </ErrorMsg>
        )}
        <FlexContainer>
          <Input
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            placeholder="Filter writers"
          />
          <SortOpen onClick={() => setSortOpen(true)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M3 7.5L7.5 3m0 0L12 7.5M7.5 3v13.5m13.5 0L16.5 21m0 0L12 16.5m4.5 4.5V7.5"
              />
            </svg>
          </SortOpen>
        </FlexContainer>
        <InfiniteScroll
          pageStart={0}
          loadMore={() => {
            if (!loading) getWriters(loadOffset);
          }}
          hasMore={writers ? writers.isNextPage : false}
        >
          {filterWriters.map((i, index) => (
            <Writer
              key={i.id}
              to={`/writer/${i.id}`}
              last={index === filterWriters.length - 1}
            >
              <WriterName>
                {i.first_name ? `${i.first_name} ` : ""}
                {i.middle_name ? `${i.middle_name} ` : ""}
                {i.last_name}{" "}
                {i.id === authState?.publishingAccount?.songwriter?.id && (
                  <You>YOU</You>
                )}
              </WriterName>
              <Metadata>
                {i.ipi && i.society
                  ? `${i.society} — IPI: ${i.ipi}`
                  : "No society affiliation"}
              </Metadata>
              <Count>
                {i.work_count} composition{i.work_count !== 1 && "s"}
              </Count>
            </Writer>
          ))}
        </InfiniteScroll>
        {loading && <Metadata>Loading...</Metadata>}
        {writers.writers.length === 0 && !loading && !error && me && (
          <Writer key={me.id} to={`/writer/${me.id}`} last={true}>
            <WriterName>
              {me.first_name ? `${me.first_name} ` : ""}
              {me.middle_name ? `${me.middle_name} ` : ""}
              {me.last_name} <You>YOU</You>
            </WriterName>
            <Metadata>
              {me.ipi && me.society
                ? `${me.society} — IPI: ${me.ipi}`
                : "No society affiliation"}
            </Metadata>
          </Writer>
        )}
        {writers.writers.length > 0 &&
          filterWriters.length === 0 &&
          !loading &&
          !error && (
            <NoWriters>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="64"
                height="64"
              >
                <path
                  fill="rgba(255,255,255,0.2)"
                  d="M12 2a10 10 0 1 1 0 20 10 10 0 0 1 0-20z"
                />
                <path
                  fill="rgba(255,255,255,0.9)"
                  d="M12 18a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm1-5.9c-.13 1.2-1.88 1.2-2 0l-.5-5a1 1 0 0 1 1-1.1h1a1 1 0 0 1 1 1.1l-.5 5z"
                />
              </svg>
              <WriterName>
                We couldn't find any songwriters matching your filter!
              </WriterName>
              <Metadata>
                <Link to="/register">Register a composition</Link> to start
                collecting your publishing royalties, or change your filter to
                see more writers.
              </Metadata>
            </NoWriters>
          )}
      </LeftSide>
      <SongwriterSort
        sortDirection={sortDirection}
        setSortDirection={setSortDirection}
        sortBy={sortBy}
        setSortBy={setSortBy}
        open={sortOpen}
        close={() => setSortOpen(false)}
      />
    </Container>
  );
};

export default Songwriters;
