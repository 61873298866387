import React, { useState, useEffect } from "react";
import { useGlobal } from "reactn";
import styled from "styled-components";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroller";
import apiRoot from "../../apiRoot";
import CreateWriter from "./CreateWriter";

const ModalBackground = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;

const Modal = styled.div`
  border-radius: 10px;
  background: #2c1d32;
  padding: 15px;
  width: 100%;
  max-width: 400px;
  box-shadow: 0 4px 10px 2px rgba(0, 0, 0, 0.2);
  padding-bottom: 5px;
  max-height: calc(100vh - 200px);
  display: flex;
  flex-direction: column;
`;

const ErrorMsg = styled.div`
  color: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;
  margin-top: 25px;
  svg {
    margin-right: 10px;
  }
`;

const Heading = styled.div`
  margin-top: -15px;
  margin-left: -15px;
  width: calc(100% - 20px);
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  padding: 15px 25px;
  border-radius: 10px 10px 0 0;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Close = styled.div`
  cursor: pointer;
  display: flex;
`;

const WriterList = styled.div`
  max-height: 300px;
  overflow-y: auto;
  margin: 10px 0;
  ::-webkit-scrollbar {
    width: 14px;
    height: 18px;
  }
  ::-webkit-scrollbar-thumb {
    min-height: 30px;
    border: 4px solid transparent;
    background-clip: padding-box;
    -webkit-border-radius: 7px;
    background-color: rgba(255, 255, 255, 0.2);
  }
  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }
  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
`;

const Writer = styled.div`
  width: 100%;
  padding: 10px;
  cursor: pointer;
  border-radius: 4px;
  background: transparent;
  box-sizing: border-box;
  flex: 1;
  margin: 10px 0;
  transition: background 0.15s;
  :hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;

const WriterName = styled.h4`
  color: white;
  margin: 0;
`;

const WriterSociety = styled.h5`
  font-weight: 500;
  color: rgba(255, 255, 255, 0.5);
  margin-top: 5px;
  margin-bottom: 0;
  font-size: 12px;
`;

const NoWriters = styled.p`
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  padding: 10px;
  text-align: center;
`;

const Input = styled.input`
  background: rgba(255, 255, 255, 0.05);
  border: none;
  margin-top: 10px;
  border-radius: 2px;
  width: calc(100% - 24px);
  padding: 6px 10px;
  outline: none;
  transition: background 0.2s;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;
  display: block;
  :focus {
    background: rgba(255, 255, 255, 0.1);
  }
  ::placeholder {
    color: rgba(255, 255, 255, 0.4);
  }
`;

const Button = styled.button`
  padding: 6px 16px;
  background: linear-gradient(135deg, hsl(160, 95%, 35%), hsl(220, 92%, 45%));
  color: white;
  font-weight: 600;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  transition: filter 0.2s;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 10px;
  :hover {
    filter: brightness(110%);
  }
  svg {
    margin-right: 10px;
    margin-bottom: 1px;
    margin-top: -1px;
  }
`;

const AddSongwriterModal = (props) => {
  let [writers, setWriters] = useGlobal("writers");
  let [filter, setFilter] = useState("");
  let [showLoading, setShowLoading] = useState(true);
  let [loading, setLoading] = useState(false);
  let [loadOffset, setLoadOffset] = useState(0);
  let [error, setError] = useState("");
  let [creatingSongwriter, setCreatingSongwriter] = useState(false);
  let [authState] = useGlobal("authState");

  async function getWriters(offset = 0) {
    if (!authState?.publishingAccount?.id) return;
    setLoading(true);
    if (writers && !writers.isNextPage) setShowLoading(false);
    const response = await axios.post(
      apiRoot,
      {
        query: `
          query getCompositions($id: Int!, $offset: Int!) {
            publishingAccount(id: $id) {
              writers(offset: $offset) {
                writers {
                  id
                  first_name
                  middle_name
                  last_name
                  ipi
                  society
                }
                isNextPage
              }
            }
          }`,
        variables: { id: authState.publishingAccount.id, offset },
      },
      { withCredentials: true }
    );
    setLoading(false);
    setLoadOffset(
      offset + response?.data?.data?.publishingAccount?.writers?.writers.length
    );
    if (response.data.errors) return setError(response.data.errors[0].message);
    if (writers && writers.pa !== authState.publishingAccount.id) {
      setWriters({
        pa: authState.publishingAccount.id,
        writers: response.data.data.publishingAccount.writers.writers,
        isNextPage: response.data.data.publishingAccount.writers.isNextPage,
      });
    } else {
      if (response?.data?.data?.publishingAccount?.writers) {
        let newWriters =
          response.data.data.publishingAccount.writers.writers.filter(
            (i) =>
              (writers ? writers.writers : []).findIndex(
                (x) => x.id === i.id
              ) === -1
          );
        if (newWriters) {
          setWriters({
            pa: authState.publishingAccount.id,
            writers: [...(writers ? writers.writers : []), ...newWriters].sort(
              (a, b) => {
                if (a.last_name.toLowerCase() > b.last_name.toLowerCase())
                  return 1;
                if (a.last_name.toLowerCase() < b.last_name.toLowerCase())
                  return -1;
                return a.first_name.toLowerCase() > b.first_name.toLowerCase()
                  ? 1
                  : -1;
              }
            ),
            isNextPage: response.data.data.publishingAccount.writers.isNextPage,
          });
        } else
          setWriters({
            pa: authState.publishingAccount.id,
            writers: writers ? writers.writers : [],
            isNextPage: response.data.data.publishingAccount.writers.isNextPage,
          });
      }
    }
  }

  const pubAccId = authState?.publishingAccount?.id;
  useEffect(() => {
    getWriters();
    // eslint-disable-next-line
  }, [pubAccId]);

  const filterWriters = (writers?.writers || [])
    .filter((i) => !props.writers.includes(i.id))
    .filter((i) => {
      return (
        (i.first_name ? `${i.first_name} ` : "") +
        (i.middle_name ? `${i.middle_name} ` : "") +
        i.last_name
      )
        .toLowerCase()
        .includes(filter.toLowerCase());
    });

  return (
    <ModalBackground>
      <Modal>
        <Heading>
          <span>Add songwriter</span>
          <Close onClick={props.close}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path
                fill="rgba(255,255,255,0.6)"
                fill-rule="evenodd"
                d="M15.78 14.36a1 1 0 0 1-1.42 1.42l-2.82-2.83-2.83 2.83a1 1 0 1 1-1.42-1.42l2.83-2.82L7.3 8.7a1 1 0 0 1 1.42-1.42l2.83 2.83 2.82-2.83a1 1 0 0 1 1.42 1.42l-2.83 2.83 2.83 2.82z"
              />
            </svg>
          </Close>
        </Heading>
        {!creatingSongwriter && (
          <Input
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            placeholder="Filter writers"
          />
        )}
        {error && (
          <ErrorMsg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              class="icon-important"
              width="32"
              height="32"
            >
              <path
                fill="rgba(255,255,255,0.2)"
                d="M12 2a10 10 0 1 1 0 20 10 10 0 0 1 0-20z"
              />
              <path
                fill="rgba(255,255,255,0.9)"
                d="M12 18a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm1-5.9c-.13 1.2-1.88 1.2-2 0l-.5-5a1 1 0 0 1 1-1.1h1a1 1 0 0 1 1 1.1l-.5 5z"
              />
            </svg>
            {error}
          </ErrorMsg>
        )}
        {!creatingSongwriter && (
          <WriterList>
            <InfiniteScroll
              pageStart={0}
              loadMore={() => {
                if (!loading) getWriters(loadOffset);
              }}
              hasMore={writers ? writers.isNextPage : false}
              useWindow={false}
            >
              {filterWriters.map((i) => (
                <Writer
                  onClick={() => {
                    props.addWriter(i);
                    props.close();
                  }}
                >
                  <WriterName>
                    {i.first_name ? `${i.first_name} ` : ""}
                    {i.middle_name ? `${i.middle_name} ` : ""}
                    {i.last_name}
                  </WriterName>
                  <WriterSociety>
                    {i.ipi && i.society
                      ? `${i.society} — IPI: ${i.ipi}`
                      : "No society affiliation"}
                  </WriterSociety>
                </Writer>
              ))}
            </InfiniteScroll>
            {loading && showLoading && <NoWriters>Loading...</NoWriters>}
            {filterWriters.length === 0 && !loading && !error && (
              <NoWriters>
                No writers found! You might want to create a new songwriter.
              </NoWriters>
            )}
          </WriterList>
        )}
        {!creatingSongwriter && (
          <Button onClick={() => setCreatingSongwriter(true)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="2 2 20 20"
              class="icon-add-circle"
              width="22"
              height="22"
            >
              <path
                fill="white"
                d="M13 11h4a1 1 0 0 1 0 2h-4v4a1 1 0 0 1-2 0v-4H7a1 1 0 0 1 0-2h4V7a1 1 0 0 1 2 0v4z"
              />
            </svg>{" "}
            Create songwriter
          </Button>
        )}
        {creatingSongwriter && (
          <CreateWriter
            submitWriter={(details) => {
              props.addWriter(details);
              setWriters({
                pa: authState.publishingAccount.id,
                writers: [...(writers ? writers.writers : []), details].sort(
                  (a, b) => {
                    if (a.last_name > b.last_name) return 1;
                    if (a.last_name < b.last_name) return -1;
                    return a.first_name > b.first_name ? 1 : -1;
                  }
                ),
                isNextPage: writers.isNextPage,
              });
              props.close();
            }}
            writers={props.writers}
          />
        )}
      </Modal>
    </ModalBackground>
  );
};

export default AddSongwriterModal;
