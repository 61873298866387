import React, { useState } from "react";
import styled from "styled-components";
import axios from "axios";
import apiRoot from "../../apiRoot";
import ButtonSelect from "../../components/ButtonSelect";

const Container = styled.div`
  background: rgba(255, 255, 255, 0.1);
  padding: 15px;
  border-radius: 4px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
`;

const Title = styled.h3`
  font-size: 16px;
  color: white;
  margin-top: 0;
  margin-bottom: 10px;
`;

const Label = styled.label`
  font-weight: 500;
  color: rgba(255, 255, 255, 0.6);
  font-size: 12px;
`;

const Input = styled.input`
  background: transparent;
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-radius: 2px;
  width: calc(100% - 24px);
  padding: 6px 10px;
  outline: none;
  transition: border-color 0.2s;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;
  margin-bottom: 10px;
  :focus {
    border-color: rgba(255, 255, 255, 0.3);
  }
`;

const Button = styled.button`
  cursor: pointer;
  background: rgba(255, 255, 255, 0.1);
  color: white;
  font-weight: 600;
  display: block;
  text-align: center;
  padding: 8px;
  width: 100%;
  border: none;
  border-radius: 2px;
  transition: background 0.2s;
  outline: none;
  margin-top: 10px;
  :hover {
    background: rgba(255, 255, 255, 0.15);
  }
  &:disabled {
    background: rgba(255, 255, 255, 0.04);
    color: rgba(255, 255, 255, 0.6);
    cursor: default;
  }
`;

const ErrorMsg = styled.div`
  color: hsl(5, 85%, 75%);
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  margin: 10px 0;
  svg {
    margin-right: 10px;
  }
`;

const PubInviteUser = (props) => {
  let [email, setEmail] = useState("");
  let [role, setRole] = useState(null);
  let [working, setWorking] = useState(false);
  let [error, setError] = useState(null);

  const invite = async () => {
    if (!email) return setError("Email required!");
    if (!role) return setError("Role required!");
    if (
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    )
      return setError("Invalid email!");
    setWorking(true);
    const response = await axios.post(
      apiRoot,
      {
        query: `mutation invite($id: Int!, $email: Email!, $role: PublishingAccountRole!) {
        publishingAccount(id: $id) {
          inviteUser(email: $email, role: $role)
        }
      }`,
        variables: { id: props.id, email, role },
      },
      { withCredentials: true, validateStatus: () => true }
    );
    if (response.data.errors) {
      setError(response.data.errors[0].message);
      return setWorking(false);
    } else setError("");
    setWorking(false);
    props.refresh();
    setEmail("");
    setRole(null);
  };

  return (
    <Container>
      <Title>Invite User</Title>
      <Label>Email address</Label>
      <Input value={email} onChange={(e) => setEmail(e.target.value)} />
      <Label>Role</Label>
      <ButtonSelect
        active={role}
        setActive={(i) => setRole(i)}
        options={[
          { id: "admin", text: "Administrator" },
          { id: "view_only", text: "View Only" },
        ]}
      />
      {error && (
        <ErrorMsg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path
              fill="hsl(5, 100%, 70%)"
              d="M12 2a10 10 0 1 1 0 20 10 10 0 0 1 0-20zm0 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16zm0 9a1 1 0 0 1-1-1V8a1 1 0 0 1 2 0v4a1 1 0 0 1-1 1zm0 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
            />
          </svg>
          {error}
        </ErrorMsg>
      )}
      <Button onClick={invite} disabled={working}>
        Invite user
      </Button>
    </Container>
  );
};

export default PubInviteUser;
