import styled from "styled-components";

const Container = styled.div`
  flex: 1;
  max-width: 250px;
  padding-left: 30px;
  margin-top: 11px;
  @media screen and (max-width: 800px) {
    max-height: 60vh;
    overflow-y: auto;
    background: #2b1d33;
    border-radius: 10px;
    padding: 15px 15px 5px 15px;
    width: 80vh;
    box-shadow: 0 4px 10px 2px rgba(0, 0, 0, 0.2);
  }
  ::-webkit-scrollbar {
    width: 14px;
    height: 18px;
  }
  ::-webkit-scrollbar-thumb {
    min-height: 30px;
    border: 4px solid transparent;
    background-clip: padding-box;
    -webkit-border-radius: 7px;
    background-color: rgba(255, 255, 255, 0.2);
  }
  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }
  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
`;

const Subtitle = styled.h3`
  color: rgba(255, 255, 255, 0.8);
  display: flex;
  margin: 0;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 600;
`;

const Directions = styled.div`
  display: flex;
`;

const Direction = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.2s;
  border-radius: 32px;
  cursor: pointer;
  color: white;
  ${({ active }) => (active ? "background: rgba(255,255,255,0.15);" : "")}
  &:hover {
    background: ${({ active }) =>
      active ? "rgba(255,255,255,0.15)" : "rgba(255,255,255,0.05)"};
  }
  svg {
    width: 20px;
    height: 20px;
  }
`;

const Divider = styled.div`
  margin-right: 5px;
`;

const VDivider = styled.div`
  margin-bottom: 20px;
`;

const VDivider2 = styled.div`
  margin-bottom: 10px;
`;

const OptionContainer = styled.div`
  margin: 2px 0 2px -10px;
  display: flex;
  padding: 7px 10px;
  cursor: pointer;
  border-radius: 4px;
  align-items: center;
  :hover {
    background: rgba(255, 255, 255, 0.05);
  }
`;

const OptionCheck = styled.div`
  width: ${(props) => (props.checked ? "20px" : "16px")};
  height: ${(props) => (props.checked ? "20px" : "16px")};
  background: ${(props) =>
    props.checked
      ? "linear-gradient(135deg, hsl(214, 93%, 47%), hsl(271, 95%, 41%))"
      : "transparent"};
  color: white;
  ${(props) =>
    !props.checked ? `border: 2px solid rgba(255,255,255,0.2)` : ""};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  border-radius: 24px;
  svg {
    width: 16px;
    height: 16px;
  }
`;

const OptionText = styled.span`
  font-weight: 500;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: -1px;
`;

const Background = styled.div`
  flex: 1;
  max-width: 250px;
  display: block;
  @media screen and (max-width: 800px) {
    height: 100vh;
    width: 100vw;
    max-width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    display: ${(props) => (props.visible ? "flex" : "none")};
    align-items: center;
    justify-content: center;
    z-index: 10;
  }
`;

const Option = ({ checked, check, text }) => (
  <OptionContainer onClick={check}>
    <OptionCheck checked={checked}>
      {checked && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          class="w-5 h-5"
        >
          <path
            fill-rule="evenodd"
            d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
            clip-rule="evenodd"
          />
        </svg>
      )}
    </OptionCheck>
    <OptionText>{text}</OptionText>
  </OptionContainer>
);

const FilterSort = (props) => (
  <Background onClick={props.close} visible={props.open}>
    <Container onClick={(e) => e.stopPropagation()}>
      <Subtitle>
        Sort
        <Directions>
          <Direction
            onClick={() => props.setSortDirection("asc")}
            active={props.sortDirection === "asc"}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 19.5v-15m0 0l-6.75 6.75M12 4.5l6.75 6.75"
              />
            </svg>
          </Direction>
          <Divider />
          <Direction
            onClick={() => props.setSortDirection("desc")}
            active={props.sortDirection === "desc"}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 4.5v15m0 0l6.75-6.75M12 19.5l-6.75-6.75"
              />
            </svg>
          </Direction>
        </Directions>
      </Subtitle>
      <Option
        checked={props.sortBy === "title"}
        check={() => props.setSortBy("title")}
        text="Title"
      />
      <Option
        checked={props.sortBy === "created_at"}
        check={() => props.setSortBy("created_at")}
        text="Creation date"
      />
      <VDivider />
      {props.tags?.length > 0 && <Subtitle>Filter tags</Subtitle>}
      <VDivider2 />
      {props.tags?.map((i) => (
        <Option
          checked={props.selectedTags.includes(i.id)}
          check={() => props.toggleSelectTag(i.id)}
          text={i.tag}
        />
      ))}
    </Container>
  </Background>
);

const SongwriterSort = (props) => (
  <Background onClick={props.close} visible={props.open}>
    <Container onClick={(e) => e.stopPropagation()}>
      <Subtitle>
        Sort
        <Directions>
          <Direction
            onClick={() => props.setSortDirection("asc")}
            active={props.sortDirection === "asc"}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 19.5v-15m0 0l-6.75 6.75M12 4.5l6.75 6.75"
              />
            </svg>
          </Direction>
          <Divider />
          <Direction
            onClick={() => props.setSortDirection("desc")}
            active={props.sortDirection === "desc"}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 4.5v15m0 0l6.75-6.75M12 19.5l-6.75-6.75"
              />
            </svg>
          </Direction>
        </Directions>
      </Subtitle>
      <Option
        checked={props.sortBy === "name"}
        check={() => props.setSortBy("name")}
        text="Name"
      />
      <Option
        checked={props.sortBy === "work_count"}
        check={() => props.setSortBy("work_count")}
        text="Number of compositions"
      />
    </Container>
  </Background>
);

export default FilterSort;
export { SongwriterSort };
