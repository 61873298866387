import React, { useState } from "react";
import styled from "styled-components";

const Button = styled.button`
  padding: 6px 16px;
  background: linear-gradient(135deg, hsl(160, 95%, 35%), hsl(220, 92%, 45%));
  color: white;
  font-weight: 600;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  transition: filter 0.2s;
  width: 100%;
  margin-top: 20px;
  :hover {
    filter: brightness(110%);
  }
`;

const ErrorMsg = styled.div`
  color: hsl(5, 85%, 75%);
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  margin-top: 10px;
  svg {
    margin-right: 10px;
    min-width: 24px;
  }
`;

const Title = styled.h4`
  margin: 10px 0;
  color: white;
  font-size: 16px;
`;

const Subtitle = styled.p`
  margin: 0;
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
`;

const TwoPanel = styled.div`
  display: flex;
  margin-top: 20px;
`;

const Panel = styled.div`
  flex: 1;
`;

const Divider = styled.div`
  margin-right: 15px;
`;

const PanelTitle = styled.h5`
  margin-top: 0;
  margin-bottom: 5px;
  color: rgba(255, 255, 255, 0.6);
  font-weight: 600;
`;

const Item = styled.div`
  font-size: 12px;
  color: white;
  margin-bottom: 3px;
`;

const ItemName = styled.span`
  color: rgba(255, 255, 255, 0.6);
`;

const None = styled.span`
  color: rgba(255, 255, 255, 0.4);
`;

const LightButton = styled(Button)`
  background: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.8);
  transition: background 0.2s;
  :hover {
    background: rgba(255, 255, 255, 0.14);
  }
`;

const VerticalDivider = styled.div`
  margin-top: 10px;
`;

const Compare = (props) => {
  return (
    <TwoPanel>
      <Panel>
        <PanelTitle>Your data:</PanelTitle>
        <Item>
          <ItemName>First Name: </ItemName>
          {props.your.firstName || <None>(none)</None>}
        </Item>
        <Item>
          <ItemName>Middle Name: </ItemName>
          {props.your.middleName || <None>(none)</None>}
        </Item>
        <Item>
          <ItemName>Last Name: </ItemName>
          {props.your.lastName}
        </Item>
        <Item>
          <ItemName>Society: </ItemName>
          {props.your.society || <None>(none)</None>}
        </Item>
        <Item>
          <ItemName>IPI: </ItemName>
          {props.your.ipi || <None>(none)</None>}
        </Item>
      </Panel>
      <Divider />
      <Panel>
        <PanelTitle>Our data:</PanelTitle>
        <Item>
          <ItemName>First Name: </ItemName>
          {props.our.first_name || <None>(none)</None>}
        </Item>
        <Item>
          <ItemName>Middle Name: </ItemName>
          {props.our.middle_name || <None>(none)</None>}
        </Item>
        <Item>
          <ItemName>Last Name: </ItemName>
          {props.our.last_name}
        </Item>
        <Item>
          <ItemName>Society: </ItemName>
          {props.our.society || <None>(none)</None>}
        </Item>
        <Item>
          <ItemName>IPI: </ItemName>
          {props.our.ipi || <None>(none)</None>}
        </Item>
      </Panel>
    </TwoPanel>
  );
};

const ReviewCreateWriter = (props) => {
  let [error, setError] = useState("");
  let [errorOn, setErrorOn] = useState(null);

  const match = (matched) => {
    if (props.writers.includes(matched.id)) {
      setErrorOn(matched.id);
      return setError("This writer is already listed on the composition!");
    } else props.submitMatch(matched);
  };

  return (
    <div>
      {props.match.match_type === "mandatory" && (
        <div>
          <Title>
            We found a songwriter in our database that matches what you entered
          </Title>
          <Subtitle>
            This writer has the same IPI you entered, so if the other data isn't
            correct, you'll need to contact us to resolve the issue.
          </Subtitle>
          <Compare your={props.metadata} our={props.match.matches[0]} />
          {props.match.matches[0].exampleWorks?.length > 0 && (
            <Item>
              <VerticalDivider />
              <ItemName>Listed on the following compositions: </ItemName>
              {props.match.matches[0].exampleWorks.join(", ")}
            </Item>
          )}
          {error && (
            <ErrorMsg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path
                  fill="hsl(5, 100%, 70%)"
                  d="M12 2a10 10 0 1 1 0 20 10 10 0 0 1 0-20zm0 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16zm0 9a1 1 0 0 1-1-1V8a1 1 0 0 1 2 0v4a1 1 0 0 1-1 1zm0 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
                />
              </svg>
              {error}
            </ErrorMsg>
          )}
          <Button onClick={() => match(props.match.matches[0])}>
            Match songwriter
          </Button>
          <LightButton onClick={props.back}>Back</LightButton>
        </div>
      )}
      {props.match.match_type === "optional" &&
        props.match.matches.length === 1 && (
          <div>
            <Title>
              We found a songwriter in our database that matches what you
              entered
            </Title>
            <Subtitle>
              If the data we have doesn't match the writer you're looking to
              create, just click "Create new songwriter" to add a new songwriter
              to our database. If you entered a society and IPI but you match
              your data to a writer with no society/IPI, we'll update our record
              with your data - no need to create a new writer.
            </Subtitle>
            <Compare your={props.metadata} our={props.match.matches[0]} />
            {props.match.matches[0].exampleWorks?.length > 0 && (
              <Item>
                <VerticalDivider />
                <ItemName>Listed on the following compositions: </ItemName>
                {props.match.matches[0].exampleWorks.join(", ")}
              </Item>
            )}
            {error && (
              <ErrorMsg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path
                    fill="hsl(5, 100%, 70%)"
                    d="M12 2a10 10 0 1 1 0 20 10 10 0 0 1 0-20zm0 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16zm0 9a1 1 0 0 1-1-1V8a1 1 0 0 1 2 0v4a1 1 0 0 1-1 1zm0 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
                  />
                </svg>
                {error}
              </ErrorMsg>
            )}
            <Button onClick={() => match(props.match.matches[0])}>
              Match songwriter
            </Button>
            <LightButton onClick={props.createWriter}>
              Create new songwriter
            </LightButton>
            <LightButton onClick={props.back}>Back</LightButton>
          </div>
        )}
      {props.match.match_type === "optional" && props.match.matches.length > 1 && (
        <div>
          <Title>
            We found multiple songwriters in our database that match what you
            entered
          </Title>
          <Subtitle>
            If the data we have doesn't match the writer you're looking to
            create, just click "Create new songwriter" to add a new songwriter
            to our database. If you entered a society and IPI but you match your
            data to a writer with no society/IPI, we'll update our record with
            your data - no need to create a new writer.
          </Subtitle>
          {props.match.matches.map((matchData) => (
            <div key={matchData.id}>
              <Compare your={props.metadata} our={matchData} />
              {matchData.exampleWorks?.length > 0 && (
                <Item>
                  <VerticalDivider />
                  <ItemName>Listed on the following compositions: </ItemName>
                  {matchData.exampleWorks.join(", ")}
                </Item>
              )}
              {error && errorOn === matchData.id && (
                <ErrorMsg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path
                      fill="hsl(5, 100%, 70%)"
                      d="M12 2a10 10 0 1 1 0 20 10 10 0 0 1 0-20zm0 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16zm0 9a1 1 0 0 1-1-1V8a1 1 0 0 1 2 0v4a1 1 0 0 1-1 1zm0 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
                    />
                  </svg>
                  {error}
                </ErrorMsg>
              )}
              <Button onClick={() => match(matchData)}>Match songwriter</Button>
            </div>
          ))}
          <LightButton onClick={props.createWriter}>
            Create new songwriter
          </LightButton>
          <LightButton onClick={props.back}>Back</LightButton>
        </div>
      )}
    </div>
  );
};

export default ReviewCreateWriter;
