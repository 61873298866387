import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import AddAltTitle from "./AddAltTitle";
import AddRecording from "./AddRecording";
import AddArtist from "./AddArtist";
import TagArray from "../../components/TagArray";

const Container = styled.div`
  @media screen and (max-width: 400px) {
    h3 {
      font-size: 16px;
    }
  }
`;

const WorkTitle = styled.h2`
  font-size: 32px;
  margin-top: 0;
  margin-bottom: 10px;
  @media screen and (max-width: 400px) {
    font-size: 24px;
  }
`;

const SongwriterName = styled(Link)`
  color: inherit;
  text-decoration: none;
`;

const Songwriter = styled.p`
  margin: 0 0 15px 0;
  font-weight: 500;
  @media screen and (max-width: 400px) {
    font-size: 14px;
  }
`;

const Share = styled.span`
  color: rgba(255, 255, 255, 0.7);
  font-size: 12px;
`;

const Controlled = styled.span`
  background: rgba(255, 255, 255, 0.1);
  padding: 3px 12px;
  border-radius: 100px;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.8);
  font-weight: 600;
  letter-spacing: 0.4px;
  flex: none;
  display: inline-block;
  margin-left: 10px;
  @media screen and (max-width: 400px) {
    font-size: 10px;
    padding: 1px 8px;
  }
`;

const NoInfo = styled.div`
  color: rgba(255, 255, 255, 0.7);
`;

const WriterSociety = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.5);
`;

const Detail = styled.div`
  margin-bottom: 4px;
  font-size: 13px;
  display: flex;
  align-items: center;
  span {
    font-weight: 500;
    color: rgba(255, 255, 255, 0.7);
    margin-right: 5px;
  }
  @media screen and (max-width: 400px) {
    font-size: 12px;
  }
`;

const WorkView = (props) => {
  const composition = props.composition;
  const parseDuration = (duration) => {
    let minutes = Math.floor(duration / 60);
    let seconds = duration % 60;
    if (String(seconds).length === 1) seconds = "0" + String(seconds);
    return minutes + ":" + seconds;
  };
  const formatIsrc = (isrc) =>
    isrc.substr(0, 2) +
    "-" +
    isrc.substr(2, 3) +
    "-" +
    isrc.substr(5, 2) +
    "-" +
    isrc.substr(7);
  const formatIswc = (iswc) =>
    iswc[0] + "-" + iswc.substr(1, 9) + "-" + iswc[10];
  return (
    <Container>
      <WorkTitle>{composition.title}</WorkTitle>
      <TagArray
        tags={composition.tags}
        addTag={props.canModify ? props.addTag : null}
        removeTag={props.canModify ? props.addTag : null}
      />
      <div style={{ marginBottom: "15px" }} />
      {composition.iswc && (
        <Detail>
          <span>ISWC: </span>
          {composition.iswc.includes("-")
            ? composition.iswc
            : formatIswc(composition.iswc)}
        </Detail>
      )}
      {composition.duration && (
        <Detail>
          <span>Duration: </span>
          {typeof composition.duration === "string"
            ? composition.duration
            : parseDuration(composition.duration)}
        </Detail>
      )}
      {composition.altTitles && composition.altTitles.length > 0 && (
        <Detail>
          <span>Alternate titles: </span>
          {composition.altTitles.join(", ")}
        </Detail>
      )}
      {props.canModify && (
        <AddAltTitle
          refresh={props.refresh}
          id={composition.id}
          pubAcc={props.authState.publishingAccount.id}
          existingTitles={[composition.title, ...(composition.altTitles || [])]}
        />
      )}
      <h3>Songwriters</h3>
      {composition.writers.map((i) => (
        <Songwriter key={i.id}>
          <SongwriterName to={"/writer/" + i.id}>
            {i.first_name ? `${i.first_name} ` : ""}
            {i.middle_name ? `${i.middle_name} ` : ""}
            {i.last_name}
          </SongwriterName>{" "}
          <Share>({i.share}%)</Share>
          {props.authState &&
            i.controlled &&
            (props.authState.publishingAccount.songwriter ? (
              <Controlled>YOU</Controlled>
            ) : (
              <Controlled>CONTROLLED</Controlled>
            ))}
          <WriterSociety>
            {i.ipi && i.society
              ? `${i.society} — IPI: ${i.ipi}`
              : "No society affiliation"}
          </WriterSociety>
        </Songwriter>
      ))}
      <h3>{composition.recordings.length > 1 ? "Recordings" : "Recording"}</h3>
      {composition.recordings.length === 0 && (
        <NoInfo>No recording information</NoInfo>
      )}
      {composition.recordings.map((i, index) => (
        <p key={index}>
          {i.isrc && (
            <Detail>
              <span>ISRC: </span>
              {i.isrc.includes("-") ? i.isrc : formatIsrc(i.isrc)}
            </Detail>
          )}
          {i.performers && i.performers.length > 0 && (
            <Detail>
              <span>Performers: </span>
              {i.performers.join(", ")}
              {props.canModify && (
                <AddArtist
                  style={{ marginLeft: "8px" }}
                  refresh={props.refresh}
                  id={composition.id}
                  recordingId={i.id}
                  pubAcc={props.authState.publishingAccount.id}
                  existingArtists={i.performers}
                />
              )}
            </Detail>
          )}
          {(!i.performers || i.performers.length === 0) && props.canModify && (
            <div>
              <AddArtist
                refresh={props.refresh}
                id={composition.id}
                pubAcc={props.authState.publishingAccount.id}
                existingArtists={[]}
                recordingId={i.id}
                none={true}
              />
            </div>
          )}
        </p>
      ))}
      {props.canModify && (
        <AddRecording
          refresh={props.refresh}
          id={composition.id}
          pubAcc={props.authState.publishingAccount.id}
          existingRecordings={composition.recordings}
        />
      )}
    </Container>
  );
};

export default WorkView;
