import axios from "axios";
import apiRoot from "./apiRoot";

const getAuthState = async (setAuthState, setSocieties) => {
  setAuthState({ loading: true });
  // Get user and all publishing accounts
  const authRequest = await axios.post(
    apiRoot,
    {
      query: `
      query getAuthData {
        setupStatus {
          status
          administration_fee
        }
        me {
          id
          admin
          referrer
          name
          email
          email_confirmed
          invite {
            type
            labelArtist {
              label {
                name
              }
            }
          }
          publishingAccounts {
            id
            name
            type
            setupStatus
            invite {
              type
            }
            users {
              isMe
              role
            }
            songwriter {
              id
              ipi
              society
              first_name
              middle_name
              last_name
            }
          }
          labelArtists {
            id
          }
        }
        __type(name: "PerformanceSociety") {
          enumValues {
            name
          }
        }
      }`,
    },
    { withCredentials: true }
  );
  setSocieties(authRequest.data.data.__type.enumValues.map((i) => i.name));
  if (authRequest.data.data && authRequest.data.data.me) {
    const me = authRequest.data.data.me;
    // Check if localStorage defines a publishing account for this user ID, if logged in
    const item = localStorage.getItem("active_publishing_accounts");
    let authStateTemp = {
      loading: false,
      authenticated: true,
      setupStatus: authRequest.data.data.setupStatus.status,
      adminFee: authRequest.data.data.setupStatus.administration_fee,
      user: {
        admin: me.admin,
        referrer: me.referrer,
        id: me.id,
        name: me.name,
        email: me.email,
        email_confirmed: me.email_confirmed,
        invite: me.invite,
        hasLabelArtists: me.labelArtists.length > 0,
      },
      publishingAccounts: me.publishingAccounts,
    };
    if (item && me.publishingAccounts.length > 0) {
      const publishingAccountId = JSON.parse(item)[me.id];
      // If so, set publishing account
      if (publishingAccountId) {
        setAuthState({
          ...authStateTemp,
          publishingAccount: me.publishingAccounts.filter(
            (i) => i.id === publishingAccountId
          )[0],
        });
      }
      // Otherwise, use the first one and set it in localstorage
      else {
        setAuthState({
          ...authStateTemp,
          publishingAccount: me.publishingAccounts[0],
        });
        localStorage.setItem(
          "active_publishing_accounts",
          JSON.stringify({
            ...JSON.parse(item),
            [me.id]: me.publishingAccounts[0].id,
          })
        );
      }
    } else if (me.publishingAccounts.length > 0) {
      setAuthState({
        ...authStateTemp,
        publishingAccount: me.publishingAccounts[0],
      });
      localStorage.setItem(
        "active_publishing_accounts",
        JSON.stringify({
          [me.id]: me.publishingAccounts[0].id,
        })
      );
    } else {
      setAuthState(authStateTemp);
    }
    return authStateTemp;
  } else {
    setAuthState({ loading: false, authenticated: false });
  }
};

export default getAuthState;
