import React, { useState } from "react";
import { useGlobal } from "reactn";
import { Redirect, Link } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import apiRoot from "../../apiRoot";
import Tooltip from "../../components/Tooltip";
import getAuthState from "../../getAuthState";
import { Helmet } from "react-helmet";

const Title = styled.h1`
  font-size: 28px;
  margin-bottom: 10px;
  @media screen and (max-width: 600px) {
    font-size: 20px;
  }
`;

const Subtitle = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.6);
  a {
    color: inherit;
  }
  @media screen and (max-width: 600px) {
    font-size: 12px;
  }
`;

const InputGroup = styled.div`
  flex: 1;
  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

const Label = styled.label`
  font-weight: 500;
  color: rgba(255, 255, 255, 0.6);
  font-size: 12px;
`;

const Divider = styled.div`
  margin-right: 20px;
  @media screen and (max-width: 600px) {
    margin-bottom: 10px;
  }
`;

const Input = styled.input`
  background: transparent;
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-radius: 2px;
  width: calc(100% - 24px);
  padding: 6px 10px;
  outline: none;
  transition: border-color 0.2s;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;
  display: block;
  :focus {
    border-color: rgba(255, 255, 255, 0.3);
  }
  ::placeholder {
    color: rgba(255, 255, 255, 0.4);
  }
`;

const InputRow = styled.div`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  margin-bottom: 10px;
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

const Button = styled.button`
  padding: 6px 25px;
  background: linear-gradient(135deg, hsl(160, 95%, 35%), hsl(220, 92%, 45%));
  color: white;
  font-weight: 600;
  outline: none;
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  transition: filter 0.2s;
  :hover {
    filter: brightness(110%);
  }
  svg {
    margin-left: 10px;
    margin-right: -5px;
  }
`;

const ErrorMsg = styled.div`
  color: hsl(5, 85%, 75%);
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  svg {
    margin-right: 10px;
    min-width: 24px;
  }
  @media screen and (max-width: 600px) {
    margin-bottom: 10px;
  }
`;

const ActivateInvite = (props) => {
  let [authState, setAuthState] = useGlobal("authState");
  let [, setSocieties] = useGlobal("societies");
  let [name, setName] = useState("");
  let [email, setEmail] = useState("");
  let [password, setPassword] = useState("");
  let [confirmPassword, setConfirmPassword] = useState("");
  let [error, setError] = useState("");
  let [working, setWorking] = useState(false);
  let [done, setDone] = useState(false);

  const submit = async (e) => {
    e.preventDefault();
    // input validation
    if (!name) return setError("Name required!");
    if (!email) return setError("Email required!");
    if (!password) return setError("Password required!");
    if (name.length > 50)
      return setError("Name must be 50 characters or less!");
    if (email.length > 254) return setError("Email is too long!");
    if (password.length < 8)
      return setError("Password must be at least 8 characters!");
    if (password.length > 128)
      return setError("Password must be 128 characters or less!");
    if (password !== confirmPassword)
      return setError("Password and confirmation must match!");
    if (
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    )
      return setError("Invalid email!");
    setError("");
    setWorking(true);
    // submit data
    const response = await axios.post(apiRoot, {
      query: `
      mutation activateInvite($code: GenericString!, $email: Email!, $password: Password!, $name: Name!) {
        activateAccountInvite(code: $code, email: $email, password: $password, name: $name)
      }`,
      variables: {
        code: props.code,
        name,
        email,
        password,
      },
    });
    setWorking(false);
    if (response.data.errors) return setError(response.data.errors[0].message);
    const response2 = await axios.post(
      apiRoot,
      {
        query: `
        mutation authenticate($email: Email!, $password: Password!) {
          authenticate(email: $email, password: $password)
        }
      `,
        variables: {
          email,
          password,
        },
      },
      {
        withCredentials: true,
      }
    );
    if (response2.data.errors)
      return setError(response2.data.errors[0].message);
    setDone(true);
    await getAuthState(setAuthState, setSocieties);
  };

  if (done) return <Redirect to="/dashboard" />;

  if (
    !done &&
    authState &&
    authState.authenticated &&
    props.type === "label_statement"
  )
    return <Redirect to={"/setup/label/" + props.code} />;

  if (
    !done &&
    authState &&
    authState.authenticated &&
    props.type === "add_publishing_account_user"
  )
    return <Redirect to={"/setup/publishinguser/" + props.code} />;

  if (authState?.authenticated && !props.noredir)
    return <Redirect to="/dashboard" />;

  return (
    <div>
      <Helmet>
        <title>Set Up Your Account | Music Inc. Client Portal</title>
      </Helmet>
      {done && <Redirect to="/confirmEmail" />}
      <Title>Set up your Music Inc. account</Title>
      {(props.type === "songwriter" || props.type === "publisher") && (
        <Subtitle>
          You've been invited to get started with Music Inc. Publishing
          Administration. There are just a few steps you'll need to complete to
          start collecting your publishing royalties. To activate your invite,
          start by creating an account on our client portal. This is where
          you'll manage your composition registrations and view royalty
          statements.
        </Subtitle>
      )}
      {props.type === "user_only" && (
        <Subtitle>
          You've been invited to create a Music Inc. account. To activate your
          invite, just fill out the information below to create an account on
          our client portal.
        </Subtitle>
      )}
      {props.type === "label_statement" && (
        <Subtitle>
          You've been invited to set up a Music Inc. account to view your
          royalty statements and get paid. Set up your account below to get
          started.
          <br />
          <br />
          If you already have a Music Inc. account,{" "}
          <Link to="/login">log in</Link> and then click your invite link again.
        </Subtitle>
      )}
      {props.type === "add_publishing_account_user" && (
        <Subtitle>
          You've been invited to set up a Music Inc. account to join an existing
          publishing administration account. Set up your account below to get
          started.
          <br />
          <br />
          If you already have a Music Inc. account,{" "}
          <Link to="/login">log in</Link> and then click your invite link again.
        </Subtitle>
      )}
      <InputRow>
        <InputGroup>
          <Label>
            Name
            <Tooltip>
              This doesn't need to be your legal name, it's just what we'll use
              to refer to you within the client portal. Feel free to enter an
              alias, nickname, or whatever else you want here.
            </Tooltip>
          </Label>
          <Input value={name} onChange={(e) => setName(e.target.value)} />
        </InputGroup>
        <Divider />
        <InputGroup>
          <Label>Email address</Label>
          <Input value={email} onChange={(e) => setEmail(e.target.value)} />
        </InputGroup>
      </InputRow>
      <InputRow>
        <InputGroup>
          <Label>Password</Label>
          <Input
            value={password}
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </InputGroup>
        <Divider />
        <InputGroup>
          <Label>Confirm password</Label>
          <Input
            value={confirmPassword}
            type="password"
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </InputGroup>
      </InputRow>
      <ButtonContainer>
        {error ? (
          <ErrorMsg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path
                fill="hsl(5, 100%, 70%)"
                d="M12 2a10 10 0 1 1 0 20 10 10 0 0 1 0-20zm0 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16zm0 9a1 1 0 0 1-1-1V8a1 1 0 0 1 2 0v4a1 1 0 0 1-1 1zm0 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
              />
            </svg>
            {error}
          </ErrorMsg>
        ) : (
          <div />
        )}
        <Button onClick={submit} disabled={working}>
          Activate account
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            height="24"
            width="24"
          >
            <path
              fill="white"
              d="M14.59 13H7a1 1 0 0 1 0-2h7.59l-2.3-2.3a1 1 0 1 1 1.42-1.4l4 4a1 1 0 0 1 0 1.4l-4 4a1 1 0 0 1-1.42-1.4l2.3-2.3z"
            />
          </svg>
        </Button>
      </ButtonContainer>
    </div>
  );
};

export default ActivateInvite;
