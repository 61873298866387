import React, { useState } from "react";
import { useGlobal } from "reactn";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import apiRoot from "../../apiRoot";
import { Helmet } from "react-helmet";

const Title = styled.h1`
  font-size: 28px;
  margin-bottom: 10px;
  @media screen and (max-width: 600px) {
    font-size: 20px;
  }
`;

const Subtitle = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.6);
  @media screen and (max-width: 600px) {
    font-size: 12px;
  }
`;

const Resend = styled.div`
  font-weight: 600;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
  transition: color 0.2s;
  :hover {
    color: rgba(255, 255, 255, 0.9);
  }
`;

const ConfirmEmail = () => {
  let [authState] = useGlobal("authState");
  let [resent, setResent] = useState(false);
  let [error, setError] = useState("");

  const resend = async () => {
    setResent(true);
    // submit data
    const response = await axios.post(
      apiRoot,
      {
        query: `
      mutation resendConfirmation {
        resendConfirmation
      }`,
      },
      { withCredentials: true }
    );
    if (response.data.errors) setError(response.data.errors[0].message);
  };

  if (authState && !authState.loading && !authState.authenticated)
    return <Redirect to="/login" />;

  if (authState && authState.setupStatus !== "needs_email_confirmation")
    return <Redirect to="/dashboard" />;

  return (
    <div>
      <Helmet>
        <title>Confirm Your Email | Music Inc. Client Portal</title>
      </Helmet>
      <Title>Confirm your email address</Title>
      <Subtitle>
        To continue setting up your account, check your email address (
        {authState?.user?.email}) for a confirmation link.
      </Subtitle>
      {!resent && <Resend onClick={resend}>Resend confirmation email</Resend>}
      {error && <Subtitle>{error}</Subtitle>}
      {resent && !error && (
        <Subtitle>
          We've sent another confirmation email. Check your spam folder if you
          don't see it.
        </Subtitle>
      )}
    </div>
  );
};

export default ConfirmEmail;
