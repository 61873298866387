import React, { useState } from "react";
import axios from "axios";
import styled from "styled-components";
import apiRoot from "../../apiRoot";

const AddButton = styled.div`
  display: inline-flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
  transition: color 0.2s;
  font-size: 14px;
  :hover {
    color: rgba(255, 255, 255, 0.7);
  }
  svg {
    margin-right: 10px;
  }
`;

const Row = styled.div`
  display: flex;
  max-width: 600px;
  align-items: center;
  @media screen and (max-width: 500px) {
    flex-wrap: wrap;
  }
`;

const Label = styled.label`
  font-weight: 500;
  color: rgba(255, 255, 255, 0.6);
  font-size: 12px;
`;

const Input = styled.input`
  background: transparent;
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-radius: 2px;
  width: calc(100% - 24px);
  padding: 6px 10px;
  outline: none;
  height: 36px;
  box-sizing: border-box;
  -webkit-appearance: none;
  transition: border-color 0.2s;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;
  display: block;
  margin-right: 10px;
  @media screen and (max-width: 500px) {
    width: 100%;
    margin-bottom: 10px;
  }
  :focus {
    border-color: rgba(255, 255, 255, 0.3);
  }
  ::placeholder {
    color: rgba(255, 255, 255, 0.4);
  }
`;

const Button = styled.button`
  padding: 6px 16px;
  background: linear-gradient(135deg, hsl(160, 95%, 35%), hsl(220, 92%, 45%));
  color: white;
  font-weight: 600;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  transition: filter 0.2s;
  margin-right: 15px;
  :hover {
    filter: brightness(110%);
  }
  svg {
    margin-left: 5px;
  }
`;

const ErrorMsg = styled.div`
  color: hsl(5, 85%, 75%);
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  margin-top: 10px;
  svg {
    margin-right: 10px;
    min-width: 24px;
  }
  @media screen and (max-width: 600px) {
    margin-bottom: 10px;
  }
`;

const filterTitle = (title) => {
  const charArray =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789 !\"#$%&'()*+,-./:;<=>?@[]\\^_`{}~£€".split(
      ""
    );
  return title
    .split("")
    .filter((i) => charArray.includes(i))
    .join("");
};

const AddAltTitle = (props) => {
  const [active, setActive] = useState(false);
  const [working, setWorking] = useState(false);
  const [title, setTitle] = useState("");
  const [error, setError] = useState(null);

  async function submit() {
    if (working) return;
    if (!title) {
      return setError("Title must not be empty!");
    }
    // Confirm non-duplication
    for (let t of props.existingTitles) {
      if (title.toLowerCase().trim() === t.toLowerCase().trim()) {
        return setError(
          "You cannot add an alternate title equal to an existing title! (Note: titles are case-insensitive)"
        );
      }
    }
    setWorking(true);
    const r = await axios.post(
      apiRoot,
      {
        query: `mutation addAltTitle($p: Int!, $id: Int!, $title: WorkTitle!) {
        publishingAccount(id: $p) {
          addAlternateTitle(workId: $id, title: $title)
        }
      }`,
        variables: {
          p: props.pubAcc,
          id: props.id,
          title: title.trim(),
        },
      },
      {
        withCredentials: true,
      }
    );
    setWorking(false);
    if (r.data.errors) return setError(r.data.errors[0].message);
    setActive(false);
    setError(null);
    setTitle("");
    props.refresh();
  }

  if (!active)
    return (
      <AddButton onClick={() => setActive(true)} style={{ marginTop: "10px" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="2 2 20 20"
          class="icon-add-circle"
          width="18"
          height="18"
        >
          <path
            fill="rgba(255,255,255,0.6)"
            d="M13 11h4a1 1 0 0 1 0 2h-4v4a1 1 0 0 1-2 0v-4H7a1 1 0 0 1 0-2h4V7a1 1 0 0 1 2 0v4z"
          />
        </svg>
        Add alternate title
      </AddButton>
    );
  else
    return (
      <div>
        <Label>Alternate Title</Label>
        <Row>
          <Input
            value={title}
            onChange={(e) =>
              setTitle(filterTitle(e.target.value).substr(0, 60))
            }
          />
          <Button onClick={submit}>Submit</Button>
          <AddButton
            onClick={() => {
              setActive(false);
              setError(null);
              setTitle("");
            }}
          >
            Cancel
          </AddButton>
        </Row>
        {error && (
          <ErrorMsg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path
                fill="hsl(5, 100%, 70%)"
                d="M12 2a10 10 0 1 1 0 20 10 10 0 0 1 0-20zm0 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16zm0 9a1 1 0 0 1-1-1V8a1 1 0 0 1 2 0v4a1 1 0 0 1-1 1zm0 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
              />
            </svg>
            {error}
          </ErrorMsg>
        )}
      </div>
    );
};

export default AddAltTitle;
