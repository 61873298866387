import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { useGlobal } from "reactn";
import styled from "styled-components";
import axios from "axios";
import onClickOutside from "react-onclickoutside";
import apiRoot, { base } from "../../apiRoot";
import { Helmet } from "react-helmet";

const MainComponent = styled.div`
  background: rgba(255, 255, 255, 0.05);
  max-width: 1200px;
  width: 100%;
  padding: 40px;
  box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  min-height: calc(100vh - 160px);
  @media screen and (max-width: 400px) {
    padding: 20px;
  }
`;

const HeaderComponent = styled.div`
  padding: 40px;
  width: 100%;
  margin-top: -40px;
  margin-left: -40px;
  background: linear-gradient(135deg, hsl(214, 93%, 47%), hsl(271, 95%, 41%));
  color: white;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  @media screen and (max-width: 400px) {
    padding: 20px;
    margin-top: -20px;
    margin-left: -20px;
  }
`;

const MainTitle = styled.h1`
  margin-top: 15px;
  @media screen and (max-width: 800px) {
    font-size: 24px;
  }
`;

const Subtitle = styled.p`
  font-weight: 500;
  font-size: 14px;
  @media screen and (max-width: 400px) {
    font-size: 12px;
  }
`;

const LightButton = styled.a`
  color: rgba(255, 255, 255, 0.9);
  text-decoration: none;
  padding: 5px 0;
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  margin-top: 10px;
  svg {
    margin-top: -1px;
    margin-right: 10px;
  }
  transition: color 0.2s;
  :hover {
    color: white;
    svg {
      path {
        fill: white;
      }
    }
  }
  @media screen and (max-width: 400px) {
    padding: 10px;
    margin-right: 0;
    font-size: 12px;
  }
`;

const LightButton2 = styled(LightButton)`
  color: rgba(255, 255, 255, 0.7);
  margin-left: 20px;
  margin-top: 0;
  padding: 0;
  font-size: 12px;
  :hover {
    color: rgba(255, 255, 255, 0.8);
    svg {
      path {
        fill: rgba(255, 255, 255, 0.8);
      }
    }
  }
  svg {
    margin-top: -2px;
    path {
      fill: rgba(255, 255, 255, 0.7);
    }
  }
`;

const Button = styled.a`
  background: rgba(255, 255, 255, 0.3);
  font-weight: 600;
  padding: 6px 16px;
  font-size: 14px;
  color: white;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  transition: background 0.2s;
  :hover {
    background: rgba(255, 255, 255, 0.35);
  }
  svg {
    margin-left: 10px;
    margin-right: -2px;
  }
  @media screen and (max-width: 400px) {
    font-size: 12px;
  }
`;

const Statement = styled.div`
  background: rgba(255, 255, 255, 0.05);
  padding: 15px 20px;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  align-items: center;
`;

const Month = styled.h4`
  color: white;
  margin: 0;
  font-size: 20px;
  display: flex;
  align-items: center;
`;

const Amount = styled.span`
  color: white;
  font-weight: 500;
`;

const NoCompositions = styled.div`
  text-align: center;
  margin: 40px auto 0 auto;
  max-width: 600px;
`;

const CompositionTitle = styled.h3`
  color: white;
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 24px;
  @media screen and (max-width: 800px) {
    font-size: 18px;
    margin-bottom: 10px;
  }
`;

const Writers = styled.p`
  color: rgba(255, 255, 255, 0.8);
  margin: 0;
  font-size: 14px;
  a {
    color: rgba(255, 255, 255, 0.8);
  }
  @media screen and (max-width: 400px) {
    font-size: 12px;
  }
`;

const PopoverOpen = styled.div`
  cursor: pointer;
  display: flex;
  margin-left: 5px;
  :hover {
    svg {
      path {
        fill: rgba(255, 255, 255, 0.6);
      }
    }
  }
`;

const PopoverBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  pointer-events: ${(props) => (props.visible ? "inherit" : "none")};
  transition: opacity 0.15s;
`;

const PopoverBox = styled.div`
  background: #342939;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  color: rgba(255, 255, 255, 0.8);
  font-weight: 500;
  max-width: 450px;
  font-size: 14px;
`;

const DetailPopoverPre = function (props) {
  DetailPopoverPre.handleClickOutside = props.close;

  return (
    <PopoverBox>
      Your monthly statements include royalties which were paid and reported to
      us in the month of the statement. The society statements included in this
      monthly statement are:
      <br />
      <br />
      {props.contents && props.contents.map((i) => <div>{i}</div>)}
    </PopoverBox>
  );
};

const clickOutsideConfig = {
  handleClickOutside: () => DetailPopoverPre.handleClickOutside,
};

const DetailPopover = onClickOutside(DetailPopoverPre, clickOutsideConfig);

const Royalties = (props) => {
  const [authState] = useGlobal("authState");
  const [statements, setStatements] = useState();
  const [popover, setPopover] = useState();
  let preReturn;
  if (authState && !authState.loading && !authState.authenticated)
    preReturn = <Redirect to="/login" />;
  if (
    authState &&
    !authState.loading &&
    authState.authenticated &&
    authState.setupStatus !== "done"
  )
    preReturn = <Redirect to="/dashboard" />;

  // Get counts
  const pubAccId = authState?.publishingAccount?.id;
  useEffect(() => {
    if (preReturn) return;
    if (!authState?.publishingAccount) return;
    async function getStatements() {
      const response = await axios.post(
        apiRoot,
        {
          query: `
            query getStatements($id: Int!) {
              publishingAccount(id: $id) {
                statements {
                  id
                  month
                  amount
                  contents
                }
              }
            }`,
          variables: { id: authState.publishingAccount.id },
        },
        { withCredentials: true }
      );
      if (response?.data?.data?.publishingAccount?.statements) {
        setStatements(response.data.data.publishingAccount.statements);
      }
    }
    getStatements();
    // eslint-disable-next-line
  }, [pubAccId, preReturn]);

  if (preReturn) return preReturn;

  return (
    <MainComponent>
      <Helmet>
        <title>Royalty Dashboard | Music Inc. Client Portal</title>
      </Helmet>
      <PopoverBackground visible={popover}>
        <DetailPopover contents={popover} close={() => setPopover(null)} />
      </PopoverBackground>
      <HeaderComponent>
        <MainTitle>Your Royalty Dashboard</MainTitle>
        <Subtitle>
          We're working on building a more advanced royalty analysis system so
          you can get detailed information about where your publishing royalties
          are coming from, but most of our effort right now is focused on
          building out our collection pipelines to collect more of your money.
          For now, you'll need to download statements in CSV format and analyze
          them in spreadsheet software if you're looking for more detailed
          information.
          <br />
          <br />
          You'll need to visit the Music Inc. Payment Portal to cash out your
          royalty balance. Click the Visit Payment Portal button below to check
          your balance, add payout methods and tax forms, and request a payout.
          <br />
          {statements && statements.length > 0 && (
            <LightButton
              href={base + "/royalty_statement/" + pubAccId + ".csv"}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="20"
                height="20"
              >
                <path
                  fill="rgba(255,255,255,0.9)"
                  d="M11 14.59V3a1 1 0 0 1 2 0v11.59l3.3-3.3a1 1 0 0 1 1.4 1.42l-5 5a1 1 0 0 1-1.4 0l-5-5a1 1 0 0 1 1.4-1.42l3.3 3.3zM3 17a1 1 0 0 1 2 0v3h14v-3a1 1 0 0 1 2 0v3a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-3z"
                />
              </svg>{" "}
              Download Consolidated Statement (CSV)
            </LightButton>
          )}
        </Subtitle>
        <Button href="https://payments.music.ceo">
          Visit Payment Portal
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            height="24"
            width="24"
          >
            <path
              fill="white"
              d="M14.59 13H7a1 1 0 0 1 0-2h7.59l-2.3-2.3a1 1 0 1 1 1.42-1.4l4 4a1 1 0 0 1 0 1.4l-4 4a1 1 0 0 1-1.42-1.4l2.3-2.3z"
            />
          </svg>
        </Button>
      </HeaderComponent>
      {statements &&
        statements.map((i) => (
          <Statement>
            <div>
              <Month>
                {i.month}
                <PopoverOpen onClick={() => setPopover(i.contents)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="20"
                    height="20"
                  >
                    <path
                      fill="rgba(255,255,255,0.5)"
                      d="M12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9a1 1 0 0 1 1 1v4a1 1 0 0 1-2 0v-4a1 1 0 0 1 1-1zm0-4a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"
                    />
                  </svg>
                </PopoverOpen>
                <LightButton2
                  href={
                    base +
                    "/royalty_statement/" +
                    pubAccId +
                    "/" +
                    i.id +
                    ".csv"
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="16"
                    height="16"
                  >
                    <path
                      fill="rgba(255,255,255,0.9)"
                      d="M11 14.59V3a1 1 0 0 1 2 0v11.59l3.3-3.3a1 1 0 0 1 1.4 1.42l-5 5a1 1 0 0 1-1.4 0l-5-5a1 1 0 0 1 1.4-1.42l3.3 3.3zM3 17a1 1 0 0 1 2 0v3h14v-3a1 1 0 0 1 2 0v3a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-3z"
                    />
                  </svg>{" "}
                  Download Statement (CSV)
                </LightButton2>
              </Month>
            </div>
            <Amount>${i.amount.toFixed(2)}</Amount>
          </Statement>
        ))}
      {statements && statements.length === 0 && (
        <NoCompositions>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="64"
            height="64"
          >
            <path
              fill="rgba(255,255,255,0.2)"
              d="M12 2a10 10 0 1 1 0 20 10 10 0 0 1 0-20z"
            />
            <path
              fill="rgba(255,255,255,0.9)"
              d="M12 18a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm1-5.9c-.13 1.2-1.88 1.2-2 0l-.5-5a1 1 0 0 1 1-1.1h1a1 1 0 0 1 1 1.1l-.5 5z"
            />
          </svg>
          <CompositionTitle>
            You don't have any royalty statements yet!
          </CompositionTitle>
          <Writers>
            It can take a while for royalties to start being earned on your
            compositions. Publishing works fairly slowly, so don't expect to see
            anything here for a few months after you register your songs.
          </Writers>
        </NoCompositions>
      )}
    </MainComponent>
  );
};

export default Royalties;
