import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { useGlobal } from "reactn";
import styled from "styled-components";
import axios from "axios";
import apiRoot from "../../apiRoot";
import NotFound from "../NotFound";
import WorkView from "./WorkViewComponent";
import { Helmet } from "react-helmet";

const MainComponent = styled.div`
  background: rgba(255, 255, 255, 0.05);
  max-width: 1200px;
  width: 100%;
  padding: 40px;
  box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  min-height: calc(100vh - 160px);
  color: white;
  @media screen and (max-width: 400px) {
    padding: 20px;
  }
`;

const Back = styled(Link)`
  text-decoration: none;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 500;
  font-size: 14px;
  transition: color 0.2s;
  :hover {
    color: rgba(255, 255, 255, 0.6);
  }
`;

const Heading = styled.div``;

const HeadingText = styled.h4`
  font-weight: 600;
  color: rgba(255, 255, 255, 0.7);
  letter-spacing: 0.4px;
  display: flex;
  align-items: center;
  margin-top: 15px;
  svg {
    border-radius: 24px;
    background: rgba(255, 255, 255, 0.1);
    margin-right: 10px;
    padding: 6px;
  }
  @media screen and (max-width: 400px) {
    font-size: 12px;
  }
`;

const Segment = styled.div`
  margin-bottom: 20px;
  display: flex;
`;

const Content = styled.div`
  > h3 {
    margin-top: 0;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    color: rgba(255, 255, 255, 0.8);
    svg {
      margin-right: 10px;
      min-width: 40px;
      min-height: 40px;
    }
    @media screen and (max-width: 600px) {
      font-size: 14px;
    }
  }
  > p {
    font-size: 14px;
    margin-left: 50px;
    color: rgba(255, 255, 255, 0.5);
    margin-top: 0;
    max-width: 700px;
    @media screen and (max-width: 600px) {
      font-size: 12px;
      margin-left: 0;
    }
  }
`;

const PendingRequests = (props) => {
  let writerNames = "";
  props.writers.forEach((i, index) => {
    const name =
      (i.first_name ? `${i.first_name} ` : "") +
      (i.middle_name ? `${i.middle_name} ` : "") +
      i.last_name;
    if (writerNames === "") writerNames = name;
    else if (index === props.writers.length - 1)
      writerNames = writerNames + " and " + name;
    else writerNames = writerNames + ", " + name;
  });

  return (
    <Segment>
      <Content>
        <h3>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="40"
            height="40"
          >
            <path
              fill="rgba(255,255,255,0.1)"
              d="M12 2a10 10 0 1 1 0 20 10 10 0 0 1 0-20z"
            />
            <path
              fill="rgba(255,255,255,0.8)"
              d="M12 18a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm1-5.9c-.13 1.2-1.88 1.2-2 0l-.5-5a1 1 0 0 1 1-1.1h1a1 1 0 0 1 1 1.1l-.5 5z"
            />
          </svg>
          We're waiting on other songwriters to accept this composition
        </h3>
        <p>
          <span>{writerNames}</span>
          {props.writers.length > 1 ? " have" : " has"} not yet accepted a
          request that this composition's creator sent. We don't begin
          registering compositions with societies until all requests have been
          either accepted or rejected, so this composition has not yet entered
          our review and registration systems.
        </p>
      </Content>
    </Segment>
  );
};

const ViewWork = (props) => {
  let [work, setWork] = useState(null);
  let [error, setError] = useState("");
  const [authState] = useGlobal("authState");

  const getWork = async function () {
    const response = await axios.post(
      apiRoot,
      {
        query: `
        query($publishingAccount: Int!, $workId: Int!) {
          publishingAccount(id: $publishingAccount){
            work(id: $workId) {
              id
              title
              duration
              iswc
              alternate_titles
              recordings {
                id
                performers
                isrc
              }
              tags {
                id
                tag
              }
              pendingSubmissionRequests {
                first_name
                middle_name
                last_name
              }
              songwriters {
                songwriter {
                  id
                  ipi
                  society
                  first_name
                  middle_name
                  last_name
                }
                controlledBy {
                  id
                }
                share
              }
            }
          }
        }`,
        variables: {
          publishingAccount: authState?.publishingAccount?.id,
          workId: Number(props.match.params.id),
        },
      },
      { withCredentials: true }
    );
    if (response.data.errors) return setError(response.data.errors[0].message);
    setWork(response.data.data?.publishingAccount?.work);
  };

  useEffect(() => {
    if (!authState?.publishingAccount) return;
    if (isNaN(Number(props.match.params.id))) setError("404");
    getWork();
    // eslint-disable-next-line
  }, [authState?.publishingAccount, props.match.params.id]);

  if (error) return <NotFound />;
  if (authState && !authState.loading && !authState.authenticated)
    return <Redirect to="/login" />;
  if (!work) return <MainComponent>Loading...</MainComponent>;

  const addTag = async (tag) => {
    if (tag.id) {
      await axios.post(
        apiRoot,
        {
          query: `
            mutation addTag($pubAcc: Int!, $workId: Int!, $tagId: Int!) {
              publishingAccount(id: $pubAcc) {
                addTag(workId: $workId, tagId: $tagId)
              }
            }`,
          variables: {
            pubAcc: authState?.publishingAccount.id,
            workId: Number(props.match.params.id),
            tagId: tag.id,
          },
        },
        { withCredentials: true }
      );
    } else {
      await axios.post(
        apiRoot,
        {
          query: `
            mutation addTag($pubAcc: Int!, $workId: Int!, $tagText: GenericString!) {
              publishingAccount(id: $pubAcc) {
                addTag(workId: $workId, tagText: $tagText)
              }
            }`,
          variables: {
            pubAcc: authState?.publishingAccount.id,
            workId: Number(props.match.params.id),
            tagText: tag.tag,
          },
        },
        { withCredentials: true }
      );
    }
    getWork();
  };

  return (
    <MainComponent>
      <Helmet>{work.title} | Music Inc. Client Portal</Helmet>
      <Heading>
        <Back to="/publishing">Back to catalog</Back>
        <HeadingText>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            x="0px"
            y="0px"
            viewBox="5 0 100 100"
            height="24"
            width="24"
          >
            <path
              fill="white"
              d="M82.2,13.7H82l-43.8,9c-2,0.1-3.5,1.7-3.5,3.8v36.9c-2.3-1.8-5-2.7-7.8-2.7c-7.1,0-12.8,5.7-12.8,12.8s5.7,12.8,12.7,12.8  c7.1,0,12.8-5.7,12.8-12.8V44.3L81,35.8v19.9C78.7,54,76,53,73.2,53c-7.1,0-12.8,5.7-12.8,12.8s5.7,12.8,12.8,12.8S86,72.9,86,65.8  V17.5C86,15.4,84.3,13.7,82.2,13.7z"
            />
          </svg>
          COMPOSITION REGISTRATION
        </HeadingText>
      </Heading>
      {work.pendingSubmissionRequests.length > 0 && (
        <PendingRequests writers={work.pendingSubmissionRequests} />
      )}
      <WorkView
        composition={{
          ...work,
          altTitles: work.alternate_titles,
          writers: work.songwriters.map((i) => ({
            ...i.songwriter,
            share: i.share,
            controlled: i.controlledBy?.id === authState?.publishingAccount?.id,
          })),
        }}
        canModify={
          authState?.publishingAccount?.users.filter((i) => i.isMe)[0].role !==
          "view_only"
        }
        addTag={addTag}
        refresh={getWork}
        authState={authState}
      />
    </MainComponent>
  );
};

export default ViewWork;
