import React, { useState } from "react";
import axios from "axios";
import styled from "styled-components";
import apiRoot from "../../apiRoot";

const AddButton = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 18px;
  background: rgba(255, 255, 255, 0.1);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background 0.2s;
  :hover {
    background: rgba(255, 255, 255, 0.15);
  }
`;

const Input = styled.input`
  background: transparent;
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-right: none;
  border-radius: 18px 0 0 18px;
  padding: 5px 10px 3px 12px;
  outline: none;
  box-sizing: border-box;
  -webkit-appearance: none;
  transition: border-color 0.2s;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;
  display: inline-block;
  margin-left: ${(props) => (props.margin ? "15px" : "0")};
  font-size: 12px;
  ::placeholder {
    color: rgba(255, 255, 255, 0.4);
  }
  :focus {
    border-color: rgba(255, 255, 255, 0.3);
  }
`;

const InlineButton = styled.button`
  padding: 7px 16px 5px 16px;
  background: linear-gradient(135deg, hsl(160, 95%, 35%), hsl(220, 92%, 45%));
  color: white;
  font-weight: 600;
  outline: none;
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  cursor: pointer;
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  transition: filter 0.2s;
  font-size: 12px;
  margin-right: 15px;
  margin-left: -15px;
  :hover {
    filter: brightness(110%);
  }
`;

const CancelButton = styled.button`
  color: rgba(255, 255, 255, 0.6);
  padding: 10px;
  background: transparent;
  border: none;
  display: inline-block;
  font-size: 12px;
  transition: color 0.2s;
  cursor: pointer;
  :hover {
    color: rgba(255, 255, 255, 0.7);
  }
`;

const AddButton2 = styled.div`
  display: inline-flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
  transition: color 0.2s;
  font-size: 14px;
  :hover {
    color: rgba(255, 255, 255, 0.7);
  }
  svg {
    margin-right: 10px;
  }
`;
const filterName = (name) => {
  const charArray =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789 !#$%&'()+-./?@`".split(
      ""
    );
  return name
    .split("")
    .filter((i) => charArray.includes(i))
    .join("");
};

const AddArtist = (props) => {
  const [active, setActive] = useState(false);
  const [working, setWorking] = useState(false);
  const [name, setName] = useState("");
  const [error, setError] = useState(null);

  async function submit() {
    if (working) return;
    if (!name) {
      return setError("Name must not be empty!");
    }
    // Confirm non-duplication
    for (let t of props.existingArtists) {
      if (name.toLowerCase().trim() === t.toLowerCase().trim()) {
        return setError(
          "You cannot add a performing artist equal to an existing performing artist! (Note: names are case-insensitive)"
        );
      }
    }
    setWorking(true);
    const r = await axios.post(
      apiRoot,
      {
        query: `mutation addAltTitle($p: Int!, $id: Int!, $recId: Int!, $name: Name!) {
        publishingAccount(id: $p) {
          addPerformingArtist(workId: $id, recordingId: $recId, artist: $name)
        }
      }`,
        variables: {
          p: props.pubAcc,
          id: props.id,
          recId: props.recordingId,
          name: name.trim(),
        },
      },
      {
        withCredentials: true,
      }
    );
    setWorking(false);
    if (r.data.errors) return setError(r.data.errors[0].message);
    setActive(false);
    setError(null);
    setName("");
    props.refresh();
  }

  if (!active) {
    if (props.none)
      return (
        <AddButton2
          onClick={() => setActive(true)}
          style={{ marginTop: "10px" }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="2 2 20 20"
            class="icon-add-circle"
            width="18"
            height="18"
          >
            <path
              fill="rgba(255,255,255,0.6)"
              d="M13 11h4a1 1 0 0 1 0 2h-4v4a1 1 0 0 1-2 0v-4H7a1 1 0 0 1 0-2h4V7a1 1 0 0 1 2 0v4z"
            />
          </svg>
          Add artist
        </AddButton2>
      );
    return (
      <AddButton style={props.style} onClick={() => setActive(true)}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="2 2 20 20"
          class="icon-add-circle"
          width="16"
          height="16"
        >
          <path
            fill="rgba(255,255,255,0.9)"
            d="M13 11h4a1 1 0 0 1 0 2h-4v4a1 1 0 0 1-2 0v-4H7a1 1 0 0 1 0-2h4V7a1 1 0 0 1 2 0v4z"
          />
        </svg>
      </AddButton>
    );
  } else
    return (
      <div>
        <Input
          margin={!props.none}
          placeholder="Artist name"
          value={name}
          onChange={(e) => setName(filterName(e.target.value).substr(0, 45))}
        />
        <InlineButton onClick={submit}>Add artist</InlineButton>
        <CancelButton
          onClick={() => {
            setActive(false);
            setError(null);
            setName("");
          }}
        >
          Cancel
        </CancelButton>
        {error && <div>{error}</div>}
      </div>
    );
};

export default AddArtist;
