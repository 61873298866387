import React, { useEffect, useState } from "react";
import { Redirect, useRouteMatch } from "react-router-dom";
import { useGlobal } from "reactn";
import styled from "styled-components";
import axios from "axios";
import apiRoot from "../apiRoot";
import getAuthState from "../getAuthState";

const CenterComponent = styled.div`
  height: calc(100vh - 80px);
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
`;

const ErrorMsg = styled.div`
  color: hsl(5, 85%, 75%);
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  svg {
    margin-right: 10px;
  }
`;

const ConfirmOrChangeEmail = (props) => {
  const match = useRouteMatch();

  let [hideHeader, setHideHeader] = useGlobal("hideHeader");
  let [error, setError] = useState("");
  let [authState, setAuthState] = useGlobal("authState");
  let [, setSocieties] = useGlobal("societies");

  useEffect(() => {
    setHideHeader(true);
    return () => setHideHeader(false);
  }, [hideHeader, setHideHeader]);

  useEffect(() => {
    async function sendRequest() {
      if (window.ceworking) return;
      window.ceworking = true;
      const response = await axios.post(apiRoot, {
        query: `mutation ${props.operation}($token: GenericString!) {
          ${props.operation}(token: $token)
        }`,
        variables: { token: match.params.token },
      });
      if (response.data.errors) setError(response.data.errors[0].message);
      /* else if (!response.data.data[props.operation])
        setError(
          "We couldn't confirm your email address. Are you sure the link is correct and you haven't already confirmed this email?"
        );*/ else {
        await getAuthState(setAuthState, setSocieties);
      }
    }
    sendRequest();
    // eslint-disable-next-line
  }, []);

  if (
    authState &&
    !authState.loading &&
    authState.authenticated &&
    authState.setupStatus !== "needs_email_confirmation"
  )
    return <Redirect to="/dashboard" />;
  if (error)
    return (
      <CenterComponent>
        <ErrorMsg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path
              fill="hsl(5, 100%, 70%)"
              d="M12 2a10 10 0 1 1 0 20 10 10 0 0 1 0-20zm0 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16zm0 9a1 1 0 0 1-1-1V8a1 1 0 0 1 2 0v4a1 1 0 0 1-1 1zm0 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
            />
          </svg>
          {error}
        </ErrorMsg>
      </CenterComponent>
    );
  return <CenterComponent>Working...</CenterComponent>;
};

export default ConfirmOrChangeEmail;
