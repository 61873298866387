import React, { useState } from "react";
import axios from "axios";
import styled from "styled-components";
import InputMask from "react-input-mask";
import Tooltip from "../../components/Tooltip";
import apiRoot from "../../apiRoot";

const AddButton = styled.div`
  display: inline-flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
  transition: color 0.2s;
  font-size: 14px;
  :hover {
    color: rgba(255, 255, 255, 0.7);
  }
  svg {
    margin-right: 10px;
  }
`;

const Label = styled.label`
  font-weight: 500;
  color: rgba(255, 255, 255, 0.6);
  font-size: 12px;
`;

const Input = styled.input`
  background: transparent;
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-radius: 2px;
  width: calc(100% - 24px);
  padding: 6px 10px;
  outline: none;
  height: 36px;
  box-sizing: border-box;
  -webkit-appearance: none;
  transition: border-color 0.2s;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;
  display: block;
  margin-right: 10px;
  @media screen and (max-width: 500px) {
    width: 100%;
    margin-bottom: 10px;
  }
  :focus {
    border-color: rgba(255, 255, 255, 0.3);
  }
  ::placeholder {
    color: rgba(255, 255, 255, 0.4);
  }
`;

const Button = styled.button`
  padding: 8px 20px;
  margin-top: 10px;
  background: linear-gradient(135deg, hsl(160, 95%, 35%), hsl(220, 92%, 45%));
  color: white;
  font-weight: 600;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  transition: filter 0.2s;
  margin-right: 15px;
  :hover {
    filter: brightness(110%);
  }
  svg {
    margin-left: 5px;
  }
`;

const InputRow = styled.div`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  margin-bottom: 15px;
  @media screen and (max-width: 600px) {
    ${(props) =>
      !props.altTitle && props.rev
        ? "flex-direction: column-reverse;"
        : "flex-direction: column;"}
    margin-bottom: 10px;
  }
`;

const ErrorMsg = styled.div`
  color: hsl(5, 85%, 75%);
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  margin-top: 10px;
  svg {
    margin-right: 10px;
    min-width: 24px;
  }
  @media screen and (max-width: 600px) {
    margin-bottom: 10px;
  }
`;

const PerformerRow = styled.div`
  max-width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: -10px;
  margin-left: -10px;
  margin-right: -10px;
  @media screen and (max-width: 900px) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media screen and (max-width: 670px) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

const Performer = styled.div`
  box-sizing: border-box;
  flex: 1;
  margin: 10px;
  display: flex;
  align-items: center;
  @media screen and (max-width: 600px) {
    width: 100%;
    max-width: 100%;
  }
`;

const AddAltTitle = styled.div`
  cursor: pointer;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: center;
  font-size: 14px;
  svg {
    margin-right: 15px;
    margin-bottom: 2px;
  }
`;

const AddPerformer = styled(AddAltTitle)`
  margin: 10px;
  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

const InputGroup = styled.div`
  flex: 1;
  ${(props) =>
    !props.altTitle &&
    `@media screen and (max-width: 600px) {
    width: 100%;
  }`}
`;

const VerticalDivider = styled.div`
  margin-bottom: 10px;
`;

const Remove = styled.div`
  margin-left: 10px;
  cursor: pointer;
  display: flex;
`;

const Recording = styled.div`
  box-sizing: border-box;
  margin-bottom: 10px;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  background: rgba(255, 255, 255, 0.1);
  max-width: 600px;
`;

const RemoveRecording = styled.div`
  padding: 0 10px;
  margin-top: 20px;
  margin-left: 10px;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 500;
  font-size: 14px;
  transition: color 0.2s;
  display: flex;
  align-items: center;
  cursor: pointer;
  svg {
    margin-right: 5px;
  }
  :hover {
    color: rgba(255, 255, 255, 0.6);
  }
  @media screen and (max-width: 600px) {
    margin: 0;
    padding-right: 0;
    align-self: flex-end;
  }
`;

const filterName = (name) => {
  const charArray =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789 !#$%&'()+-./?@`".split(
      ""
    );
  return name
    .split("")
    .filter((i) => charArray.includes(i))
    .join("");
};

const AddRecording = (props) => {
  const [active, setActive] = useState(false);
  const [working, setWorking] = useState(false);
  const [recording, setRecording] = useState({ isrc: "", performers: [] });
  const [error, setError] = useState(null);

  async function submit() {
    if (working) return;
    if (recording.isrc && recording.isrc.length < 15)
      return setError("Invalid ISRC!");
    recording.performers.forEach((perf) => {
      if (!perf) return setError("Performer names must not be empty!");
    });
    if (
      props.existingRecordings.filter(
        (i) => i.isrc === recording.isrc.split("-").join("")
      ).length > 0
    )
      return setError("Multiple recordings cannot have the same ISRC!");
    setWorking(true);
    const r = await axios.post(
      apiRoot,
      {
        query: `mutation addRecording($p: Int!, $id: Int!, $recording: RecordingInput!) {
        publishingAccount(id: $p) {
          addRecording(workId: $id, recording: $recording)
        }
      }`,
        variables: {
          p: props.pubAcc,
          id: props.id,
          recording: {
            ...recording,
            isrc: recording.isrc.split("-").join(""),
          },
        },
      },
      {
        withCredentials: true,
      }
    );
    setWorking(false);
    if (r.data.errors) return setError(r.data.errors[0].message);
    setActive(false);
    setError(null);
    setRecording({ isrc: "", performers: [] });
    props.refresh();
  }

  if (!active)
    return (
      <AddButton onClick={() => setActive(true)} style={{ marginTop: "10px" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="2 2 20 20"
          class="icon-add-circle"
          width="18"
          height="18"
        >
          <path
            fill="rgba(255,255,255,0.6)"
            d="M13 11h4a1 1 0 0 1 0 2h-4v4a1 1 0 0 1-2 0v-4H7a1 1 0 0 1 0-2h4V7a1 1 0 0 1 2 0v4z"
          />
        </svg>
        Add recording
      </AddButton>
    );
  else
    return (
      <div>
        <Recording>
          <InputRow rev>
            <InputGroup>
              <Label>
                ISRC
                <Tooltip>
                  The International Standard Recording Code is a unique
                  identifier for a recording. This is usually assigned by your
                  distributor or record label.
                </Tooltip>
              </Label>
              <InputMask
                mask="aa-***-99-99999"
                maskChar=""
                value={recording.isrc}
                onChange={(e) =>
                  setRecording({
                    ...recording,
                    isrc: e.target.value.toUpperCase(),
                  })
                }
              >
                {(inputProps) => <Input {...inputProps} placeholder="" />}
              </InputMask>
            </InputGroup>
            <RemoveRecording
              onClick={() => {
                setActive(false);
                setError(null);
                setRecording({ isrc: "", performers: [] });
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path
                  fill="rgba(255,255,255,0.4)"
                  fill-rule="evenodd"
                  d="M15.78 14.36a1 1 0 0 1-1.42 1.42l-2.82-2.83-2.83 2.83a1 1 0 1 1-1.42-1.42l2.83-2.82L7.3 8.7a1 1 0 0 1 1.42-1.42l2.83 2.83 2.82-2.83a1 1 0 0 1 1.42 1.42l-2.83 2.83 2.83 2.82z"
                />
              </svg>{" "}
              Cancel
            </RemoveRecording>
          </InputRow>
          <Label>
            Performing artists
            <Tooltip>
              Use the artist names or aliases as they appear on streaming
              platforms, if different from the artist's full name
            </Tooltip>
          </Label>
          <VerticalDivider />
          <PerformerRow>
            {recording.performers.map((performer, per_index) => (
              <Performer key={per_index}>
                <InputGroup>
                  <Input
                    value={performer}
                    onChange={(e) =>
                      setRecording({
                        ...recording,
                        performers: recording.performers.map((p, in2) =>
                          in2 === per_index
                            ? filterName(e.target.value).substr(0, 45)
                            : p
                        ),
                      })
                    }
                  ></Input>
                </InputGroup>
                <Remove
                  onClick={() =>
                    setRecording({
                      ...recording,
                      performers: recording.performers.filter(
                        (_, in2) => in2 !== per_index
                      ),
                    })
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path
                      fill="rgba(255,255,255,0.4)"
                      fill-rule="evenodd"
                      d="M15.78 14.36a1 1 0 0 1-1.42 1.42l-2.82-2.83-2.83 2.83a1 1 0 1 1-1.42-1.42l2.83-2.82L7.3 8.7a1 1 0 0 1 1.42-1.42l2.83 2.83 2.82-2.83a1 1 0 0 1 1.42 1.42l-2.83 2.83 2.83 2.82z"
                    />
                  </svg>
                </Remove>
              </Performer>
            ))}
            <AddPerformer
              onClick={() =>
                setRecording({
                  ...recording,
                  performers: [...recording.performers, ""],
                })
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="2 2 20 20"
                width="22"
                height="22"
              >
                <path
                  fill="rgba(255,255,255,0.5)"
                  d="M13 11h4a1 1 0 0 1 0 2h-4v4a1 1 0 0 1-2 0v-4H7a1 1 0 0 1 0-2h4V7a1 1 0 0 1 2 0v4z"
                />
              </svg>
              Add artist
            </AddPerformer>
          </PerformerRow>
          {error && (
            <ErrorMsg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path
                  fill="hsl(5, 100%, 70%)"
                  d="M12 2a10 10 0 1 1 0 20 10 10 0 0 1 0-20zm0 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16zm0 9a1 1 0 0 1-1-1V8a1 1 0 0 1 2 0v4a1 1 0 0 1-1 1zm0 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
                />
              </svg>
              {error}
            </ErrorMsg>
          )}
          <Button onClick={submit}>Submit</Button>
        </Recording>
      </div>
    );
};

export default AddRecording;
