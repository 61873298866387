import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { useGlobal } from "reactn";
import styled from "styled-components";
import axios from "axios";
import apiRoot from "../../apiRoot";
import NotFound from "../NotFound";
import WorkView from "./WorkViewComponent";
import { Helmet } from "react-helmet";

const HeaderComponent = styled.div`
  padding: 20px 40px;
  width: 100%;
  margin-top: -40px;
  margin-left: -40px;
  background: linear-gradient(135deg, hsl(214, 93%, 47%), hsl(271, 95%, 41%));
  color: white;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  @media screen and (max-width: 400px) {
    margin-top: -20px;
    margin-left: -20px;
    padding: 20px;
  }
`;

const MainComponent = styled.div`
  background: rgba(255, 255, 255, 0.05);
  max-width: 1200px;
  width: 100%;
  padding: 40px;
  box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  min-height: calc(100vh - 160px);
  color: white;
  @media screen and (max-width: 400px) {
    padding: 20px;
  }
`;

const Back = styled(Link)`
  text-decoration: none;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 500;
  font-size: 14px;
  transition: color 0.2s;
  :hover {
    color: rgba(255, 255, 255, 0.6);
  }
`;

const Heading = styled.div`
  margin-top: 20px;
`;

const HeadingText = styled.h4`
  font-weight: 600;
  color: rgba(255, 255, 255, 0.7);
  letter-spacing: 0.4px;
  display: flex;
  align-items: center;
  margin-top: 15px;
  svg {
    border-radius: 24px;
    background: rgba(255, 255, 255, 0.1);
    margin-right: 10px;
    padding: 6px;
  }
  @media screen and (max-width: 400px) {
    font-size: 12px;
  }
`;

const MainTitle = styled.h1`
  margin-top: 15px;
  font-size: 24px;
  @media screen and (max-width: 800px) {
    font-size: 18px;
  }
`;

const Subtitle = styled.p`
  font-weight: 500;
  font-size: 14px;
  max-width: 750px;
  @media screen and (max-width: 400px) {
    font-size: 12px;
  }
`;

const Flex = styled.div`
  display: flex;
`;

const Button = styled.button`
  border: none;
  background: rgba(255, 255, 255, 0.3);
  font-weight: 600;
  padding: 6px 16px;
  font-size: 14px;
  color: white;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  text-decoration: none;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  transition: background 0.2s;
  cursor: pointer;
  margin-right: 10px;
  :hover {
    background: rgba(255, 255, 255, 0.35);
  }
  @media screen and (max-width: 400px) {
    font-size: 12px;
  }
`;

const LightButton = styled(Button)`
  background: transparent;
  box-shadow: none;
  color: rgba(255, 255, 255, 0.8);
  transition: color 0.2s;
  :hover {
    color: rgba(255, 255, 255, 0.9);
    background: transparent;
  }
  @media screen and (max-width: 400px) {
    font-size: 12px;
  }
`;

const ViewSubmissionRequest = (props) => {
  let [work, setWork] = useState(null);
  let [error, setError] = useState("");
  let [working, setWorking] = useState(false);
  let [done, setDone] = useState(false);
  const [authState] = useGlobal("authState");

  const acceptRequest = async function () {
    setWorking(true);
    const response = await axios.post(
      apiRoot,
      {
        query: `
          mutation acceptRequest($reqId: Int!, $pubAcc: Int!) {
            publishingAccount(id: $pubAcc) {
              acceptSubmissionRequest(submissionRequestId: $reqId)
            }
          }`,
        variables: {
          reqId: Number(props.match.params.id),
          pubAcc: authState.publishingAccount.id,
        },
      },
      { withCredentials: true }
    );
    setWorking(false);
    if (response.data.errors) return setError(response.data.errors[0].message);
    setDone(true);
  };

  const rejectRequest = async function () {
    setWorking(true);
    const response = await axios.post(
      apiRoot,
      {
        query: `
          mutation rejectRequest($reqId: Int!, $pubAcc: Int!) {
            publishingAccount(id: $pubAcc) {
              denySubmissionRequest(submissionRequestId: $reqId)
            }
          }`,
        variables: {
          reqId: Number(props.match.params.id),
          pubAcc: authState.publishingAccount.id,
        },
      },
      { withCredentials: true }
    );
    setWorking(false);
    if (response.data.errors) return setError(response.data.errors[0].message);
    setDone(true);
  };

  useEffect(() => {
    if (!authState?.publishingAccount) return;
    if (isNaN(Number(props.match.params.id))) setError("404");
    const getWork = async function () {
      const response = await axios.post(
        apiRoot,
        {
          query: `
          query($publishingAccount: Int!, $workId: Int!) {
            publishingAccount(id: $publishingAccount){
              submissionRequest(id: $workId) {
                work {
                  id
                  title
                  duration
                  iswc
                  alternate_titles
                  recordings {
                    performers
                    isrc
                  }
                  songwriters {
                    songwriter {
                      id
                      ipi
                      society
                      first_name
                      middle_name
                      last_name
                    }
                    controlledBy {
                      id
                    }
                    share
                  }
                }
              }
            }
          }`,
          variables: {
            publishingAccount: authState?.publishingAccount?.id,
            workId: Number(props.match.params.id),
          },
        },
        { withCredentials: true }
      );
      if (response.data.errors)
        return setError(response.data.errors[0].message);
      setWork(response.data.data?.publishingAccount?.submissionRequest?.work);
    };
    getWork();
  }, [authState?.publishingAccount, props.match.params.id]);

  if (error) return <NotFound />;
  if (authState && !authState.loading && !authState.authenticated)
    return <Redirect to="/login" />;
  if (!work) return <MainComponent>Loading...</MainComponent>;

  let canCreate =
    authState?.publishingAccount?.users.filter((i) => i.isMe)[0].role !==
    "view_only";

  return (
    <MainComponent>
      <Helmet>
        <title>{work.title} | Music Inc. Client Portal</title>
      </Helmet>
      {done && <Redirect to="/publishing" />}
      <HeaderComponent>
        <MainTitle>
          Would you like to register your share on this composition?
        </MainTitle>
        <Subtitle>
          Another Music Inc. client registered this composition on which you are
          listed as a co-writer. If you want to start collecting on your share,
          just click "Accept request" to add this composition to your account -
          no other registration necessary.
        </Subtitle>
        {canCreate ? (
          <Flex>
            <Button onClick={acceptRequest} disabled={working}>
              Accept request
            </Button>
            <LightButton onClick={rejectRequest} disabled={working}>
              Reject request
            </LightButton>
          </Flex>
        ) : (
          <Subtitle>
            You only have view-only access, and cannot accept this request. Ask
            a member of your publishing account with access to handle this
            submission request.
          </Subtitle>
        )}
      </HeaderComponent>
      <Heading>
        <Back to="/publishing">Back to catalog</Back>
        <HeadingText>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            x="0px"
            y="0px"
            viewBox="5 0 100 100"
            height="24"
            width="24"
          >
            <path
              fill="white"
              d="M82.2,13.7H82l-43.8,9c-2,0.1-3.5,1.7-3.5,3.8v36.9c-2.3-1.8-5-2.7-7.8-2.7c-7.1,0-12.8,5.7-12.8,12.8s5.7,12.8,12.7,12.8  c7.1,0,12.8-5.7,12.8-12.8V44.3L81,35.8v19.9C78.7,54,76,53,73.2,53c-7.1,0-12.8,5.7-12.8,12.8s5.7,12.8,12.8,12.8S86,72.9,86,65.8  V17.5C86,15.4,84.3,13.7,82.2,13.7z"
            />
          </svg>
          COMPOSITION REGISTRATION
        </HeadingText>
      </Heading>
      <WorkView
        composition={{
          ...work,
          altTitles: work.alternate_titles,
          writers: work.songwriters.map((i) => ({
            ...i.songwriter,
            share: i.share,
          })),
        }}
        authState={authState}
      />
    </MainComponent>
  );
};

export default ViewSubmissionRequest;
