import React, { useState, useEffect } from "react";
import axios from "axios";
import { useGlobal } from "reactn";
import styled from "styled-components";
import apiRoot from "../../apiRoot";
import { Redirect, Link } from "react-router-dom";
import getAuthState from "../../getAuthState";
import { Helmet } from "react-helmet";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const LoginBox = styled.form`
  border-radius: 10px;
  background: #33293a;
  padding: 40px;
  box-shadow: 0 4px 12px 2px rgba(0, 0, 0, 0.3);
  color: white;
  max-width: 300px;
  width: calc(100% - 40px);
  margin-top: -80px;
  @media screen and (max-width: 400px) {
    height: calc(100% + 80px);
    max-width: 100vw;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: none;
    width: 400px;
    box-sizing: border-box;
  }
`;

/*const Underline = styled.span`
  display: inline-block;
  position: relative;
  z-index: 5;
  :after {
    background: linear-gradient(90deg, hsl(12, 95%, 60%), hsl(340, 92%, 55%));
    bottom: 0.6rem;
    left: -0.3rem;
    content: "";
    height: 0.3rem;
    opacity: 1;
    padding: 0 0.25rem;
    position: absolute;
    width: 100%;
    z-index: -1;
  }
`;*/

const Heading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Logo = styled.svg`
  margin-right: 20px;
  margin-left: -20px;
  margin-top: -20px;
`;

const HeadingText = styled.h1`
  line-height: 1;
  margin-top: 10px;
`;

const InputGroup = styled.div`
  margin-bottom: 15px;
`;

const Label = styled.label`
  font-weight: 500;
  color: rgba(255, 255, 255, 0.6);
  font-size: 12px;
`;

const Input = styled.input`
  background: transparent;
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-radius: 2px;
  width: calc(100% - 24px);
  padding: 6px 10px;
  outline: none;
  transition: border-color 0.2s;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;
  :focus {
    border-color: rgba(255, 255, 255, 0.3);
  }
`;

const Button = styled.button`
  cursor: pointer;
  background: hsl(221, 92%, 85%);
  color: hsl(230, 85%, 45%);
  font-weight: 700;
  display: block;
  text-align: center;
  padding: 8px;
  width: 100%;
  border: none;
  border-radius: 2px;
  transition: background 0.2s;
  outline: none;
  :hover {
    background: hsl(221, 92%, 80%);
  }
`;

const ErrorMsg = styled.div`
  color: hsl(5, 85%, 75%);
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  svg {
    margin-right: 10px;
  }
`;

const ForgotPassword = styled(Link)`
  text-decoration: none;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 600;
  font-size: 12px;
  margin-top: 10px;
  display: inline-block;
  transition: color 0.2s;
  :hover {
    color: rgba(255, 255, 255, 0.7);
  }
`;

const Login = (props) => {
  let [email, setEmail] = useState("");
  let [password, setPassword] = useState("");
  let [error, setError] = useState("");
  let [hideHeader, setHideHeader] = useGlobal("hideHeader");
  let [authState, setAuthState] = useGlobal("authState");
  let [, setSocieties] = useGlobal("societies");
  useEffect(() => {
    setHideHeader(true);
    return () => setHideHeader(false);
  }, [hideHeader, setHideHeader]);

  async function submit(e) {
    e.preventDefault();
    if (!email) return setError("Email address required!");
    if (!password) return setError("Password required!");
    if (email.length > 254) return setError("Email is too long!");
    if (password.length < 8 || password.length > 128)
      return setError("Invalid password!");
    if (
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    )
      return setError("Invalid email!");
    setError("");
    const response = await axios.post(
      apiRoot,
      {
        query: `
        mutation authenticate($email: Email!, $password: Password!) {
          authenticate(email: $email, password: $password)
        }
      `,
        variables: {
          email,
          password,
        },
      },
      {
        withCredentials: true,
      }
    );
    if (response.data.errors) return setError(response.data.errors[0].message);
    if(props.match.params.redirect) {
      if(props.match.params.redirect === "payments") window.location = "https://payments.music.ceo";
      if(props.match.params.redirect === "statements") window.location = "https://statements.music.ceo";
    }
    getAuthState(setAuthState, setSocieties);
  }

  return (
    <Container>
      <Helmet>
        <title>Log In | Music Inc. Client Portal</title>
      </Helmet>
      <LoginBox onSubmit={submit}>
        <Heading>
          <Logo
            width="140"
            height="140"
            viewBox="0 0 140 140"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M48.2626 35.9688C48.2623 34.5441 49.4156 33.2079 50.8251 33H54.2621V56.5626V90.9688C54.2969 92.6453 53.731 94.8298 52.9814 96.7813C51.5123 99.7522 48.9754 102.386 45.6376 104.313C42.2998 106.24 38.7262 107.151 35.4188 106.938C32.1116 106.724 28.8502 105.248 27.1376 102.281C25.4251 99.3151 25.7937 95.7835 27.2626 92.8127C28.7315 89.8418 31.2998 87.1772 34.6376 85.2501C37.9754 83.3231 41.5178 82.4431 44.8251 82.6564C45.9997 82.7322 47.1669 82.9756 48.2626 83.3751V35.9688Z"
              fill="white"
            />
            <path
              d="M96.5 106.5V62.6174L80.3 89.7974H72.38C72.38 89.7974 55.73 47.8974 53.5 47.7074C51.27 47.5174 52.3995 68.155 53 71.7074V96.0074V33.0074C53 33.0074 48.44 33.0074 54.47 33.0074C60.5 33.0074 76.61 69.3674 76.61 69.3674L98.21 33.0074H112.88L112.88 106.5H96.5Z"
              fill="white"
            />
          </Logo>
          <HeadingText>
            Client
            <br />
            Portal
          </HeadingText>
        </Heading>
        <InputGroup>
          <Label>Email address</Label>
          <Input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></Input>
        </InputGroup>
        <InputGroup>
          <Label>Password</Label>
          <Input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
          ></Input>
        </InputGroup>
        {error && (
          <ErrorMsg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path
                fill="hsl(5, 100%, 70%)"
                d="M12 2a10 10 0 1 1 0 20 10 10 0 0 1 0-20zm0 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16zm0 9a1 1 0 0 1-1-1V8a1 1 0 0 1 2 0v4a1 1 0 0 1-1 1zm0 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
              />
            </svg>
            {error}
          </ErrorMsg>
        )}
        {authState?.authenticated && <Redirect to="/dashboard" />}
        <Button type="submit">Sign in</Button>
        <ForgotPassword to="/resetPassword">
          Forgot your password?
        </ForgotPassword>
      </LoginBox>
    </Container>
  );
};

export default Login;
