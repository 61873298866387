import React, { useState, useEffect } from "react";
import { useGlobal } from "reactn";
import { Link } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import InfiniteScroll from "react-infinite-scroller";
import apiRoot from "../../apiRoot";
import { Helmet } from "react-helmet";

const Container = styled.div`
  display: flex;
  margin-top: 30px;
  @media screen and (max-width: 400px) {
    margin-top: 10px;
  }
`;

const ErrorMsg = styled.div`
  color: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;
  margin-top: 25px;
  svg {
    margin-right: 10px;
    min-width: 32px;
  }
`;

const LeftSide = styled.div`
  flex: 1;
`;

const Composition = styled(Link)`
  text-decoration: none;
  display: block;
  padding: 20px 0;
  border-bottom: ${(props) => (props.last ? "0" : "1px")} solid
    rgba(255, 255, 255, 0.2);
`;

const CompositionTitle = styled.h3`
  color: white;
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 24px;
  @media screen and (max-width: 800px) {
    font-size: 18px;
    margin-bottom: 10px;
  }
`;

const Writers = styled.p`
  color: rgba(255, 255, 255, 0.8);
  margin: 0;
  font-size: 14px;
  a {
    color: rgba(255, 255, 255, 0.8);
  }
  @media screen and (max-width: 400px) {
    font-size: 12px;
  }
`;

const NoCompositions = styled.div`
  text-align: center;
`;

const Requests = () => {
  const [authState] = useGlobal("authState");
  let [compositions, setCompositions] = useState({ works: [] });
  let [loading, setLoading] = useState(false);
  let [error, setError] = useState("");

  async function getCompositions(offset = 0) {
    if (!authState?.publishingAccount) return;
    if (offset < compositions.works.length && offset > 0) return;
    setLoading(true);
    const response = await axios.post(
      apiRoot,
      {
        query: `
          query getCompositions($id: Int!, $offset: Int!) {
            publishingAccount(id: $id) {
              submissionRequests(offset: $offset) {
                submissionRequests {
                  id
                  work {
                    id
                    title
                    songwriters {
                      songwriter {
                        first_name
                        middle_name
                        last_name
                      }
                    }
                  }
                }
                isNextPage
              }
            }
          }`,
        variables: { id: authState.publishingAccount.id, offset },
      },
      { withCredentials: true }
    );
    setLoading(false);
    if (response.data.errors) return setError(response.data.errors[0].message);
    if (response?.data?.data?.publishingAccount?.submissionRequests) {
      setCompositions({
        works:
          offset === 0
            ? response.data.data.publishingAccount.submissionRequests.submissionRequests.map(
                (i) => ({ ...i.work, id: i.id })
              )
            : [
                ...compositions.works,
                ...response.data.data.publishingAccount.submissionRequests.submissionRequests.map(
                  (i) => ({ ...i.work, id: i.id })
                ),
              ],
        isNextPage:
          response.data.data.publishingAccount.submissionRequests.isNextPage,
      });
    }
  }

  const pubAccId = authState?.publishingAccount?.id;
  useEffect(() => {
    getCompositions();
    // eslint-disable-next-line
  }, [pubAccId]);

  return (
    <Container>
      <Helmet>
        <title>Matched Compositions | Music Inc. Client Portal</title>
      </Helmet>
      <LeftSide>
        {error && (
          <ErrorMsg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              class="icon-important"
              width="32"
              height="32"
            >
              <path
                fill="rgba(255,255,255,0.2)"
                d="M12 2a10 10 0 1 1 0 20 10 10 0 0 1 0-20z"
              />
              <path
                fill="rgba(255,255,255,0.9)"
                d="M12 18a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm1-5.9c-.13 1.2-1.88 1.2-2 0l-.5-5a1 1 0 0 1 1-1.1h1a1 1 0 0 1 1 1.1l-.5 5z"
              />
            </svg>
            {error}
          </ErrorMsg>
        )}
        <InfiniteScroll
          pageStart={0}
          loadMore={() => {
            if (!loading) getCompositions(compositions.works.length);
          }}
          hasMore={compositions.isNextPage}
        >
          {compositions.works.map((i, index) => (
            <Composition
              key={i.id}
              to={`/request/${i.id}`}
              last={index === compositions.works.length - 1}
            >
              <CompositionTitle>{i.title}</CompositionTitle>
              <Writers>
                {i.songwriters.map(({ songwriter: writer }, index) => (
                  <span>
                    {writer.first_name && `${writer.first_name} `}
                    {writer.middle_name && `${writer.middle_name} `}
                    {writer.last_name}
                    {index < i.songwriters.length - 1 && ", "}
                  </span>
                ))}
              </Writers>
            </Composition>
          ))}
        </InfiniteScroll>
        {loading && <Writers>Loading...</Writers>}
        {compositions.works.length === 0 && !loading && !error && (
          <NoCompositions>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              class="icon-important"
              width="64"
              height="64"
            >
              <path
                fill="rgba(255,255,255,0.2)"
                d="M12 2a10 10 0 1 1 0 20 10 10 0 0 1 0-20z"
              />
              <path
                fill="rgba(255,255,255,0.9)"
                d="M12 18a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm1-5.9c-.13 1.2-1.88 1.2-2 0l-.5-5a1 1 0 0 1 1-1.1h1a1 1 0 0 1 1 1.1l-.5 5z"
              />
            </svg>
            <CompositionTitle>
              You don't have any pending matched compositions
            </CompositionTitle>
            <Writers>
              <Link to="/register">Register a composition</Link> to start
              collecting your publishing royalties.
            </Writers>
          </NoCompositions>
        )}
      </LeftSide>
    </Container>
  );
};

export default Requests;
