import React, { useState } from "react";
import { useGlobal } from "reactn";
import styled from "styled-components";
import axios from "axios";
import apiRoot from "../../apiRoot";
import Tooltip from "../../components/Tooltip";
import SocietySelect from "../../components/SocietySelect";
import ReviewCreateWriter from "./ReviewCreateWriter";

const Container = styled.div`
  padding: 10px;
  max-height: calc(100vh - 274px);
  ::-webkit-scrollbar {
    width: 14px;
    height: 18px;
  }
  ::-webkit-scrollbar-thumb {
    min-height: 30px;
    border: 4px solid transparent;
    background-clip: padding-box;
    -webkit-border-radius: 7px;
    background-color: rgba(255, 255, 255, 0.2);
  }
  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }
  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
`;

const InputGroup = styled.div`
  flex: 1;
`;

const Label = styled.label`
  font-weight: 500;
  color: rgba(255, 255, 255, 0.6);
  font-size: 12px;
`;

const Divider = styled.div`
  margin-right: 10px;
`;

const Input = styled.input`
  background: transparent;
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-radius: 2px;
  width: calc(100% - 24px);
  padding: 6px 10px;
  outline: none;
  transition: border-color 0.2s;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;
  display: block;
  :focus {
    border-color: rgba(255, 255, 255, 0.3);
  }
  ::placeholder {
    color: rgba(255, 255, 255, 0.4);
  }
`;

const InputRow = styled.div`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  margin-bottom: 10px;
`;

const Button = styled.button`
  padding: 6px 16px;
  background: linear-gradient(135deg, hsl(160, 95%, 35%), hsl(220, 92%, 45%));
  color: white;
  font-weight: 600;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  transition: filter 0.2s;
  width: 100%;
  margin-top: 20px;
  :hover {
    filter: brightness(110%);
  }
`;

const ErrorMsg = styled.div`
  color: hsl(5, 85%, 75%);
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  svg {
    margin-right: 10px;
    min-width: 24px;
  }
`;

const filterName = (name) => {
  const charArray =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789 !#$%&'()+-./?@`".split(
      ""
    );
  return name
    .split("")
    .filter((i) => charArray.includes(i))
    .join("");
};

const filterIpi = (ipi) => {
  const charArray = "0123456789".split("");
  return ipi
    .split("")
    .filter((i) => charArray.includes(i))
    .join("");
};

const CreateWriter = (props) => {
  let [firstName, setFirstName] = useState("");
  let [middleName, setMiddleName] = useState("");
  let [lastName, setLastName] = useState("");
  let [society, setSociety] = useState("");
  let [ipi, setIpi] = useState("");
  let [error, setError] = useState("");
  let [match, setMatch] = useState(null);
  let [authState] = useGlobal("authState");

  const preCreateWriter = async () => {
    if (!lastName) return setError("Last name is required!");
    if ((society && !ipi) || (ipi && !society))
      return setError(
        "If an IPI or society is specified, both must be specified"
      );
    if (ipi && ipi.length < 8)
      return setError("IPI must be at least 8 characters");
    if (middleName && middleName.length + firstName.length > 29)
      return setError(
        "The combined length of the writer's middle and first name must be 30 characters or less"
      );
    let writerInfo = { last_name: lastName };
    if (firstName) writerInfo.first_name = firstName;
    if (middleName) writerInfo.middle_name = middleName;
    if (ipi) writerInfo.ipi = ipi;
    if (society) writerInfo.society = society;
    const response = await axios.post(
      apiRoot,
      {
        query: `mutation preCreateSongwriter($writer: SongwriterInput!) {
          preCreateSongwriter(writer: $writer) {
            match_type
            matches {
              id
              ipi
              society
              first_name
              middle_name
              last_name
              exampleWorks
            }
          }
        }`,
        variables: {
          writer: writerInfo,
        },
      },
      { withCredentials: true }
    );
    if (response.data.errors) return setError(response.data.errors[0].message);
    setError("");
    if (
      response.data.data.preCreateSongwriter &&
      response.data.data.preCreateSongwriter.match_type !== "auto"
    ) {
      setMatch(response.data.data.preCreateSongwriter);
    } else if (response.data.data.preCreateSongwriter?.match_type === "auto") {
      const matched = response.data.data.preCreateSongwriter.matches[0];
      if (props.writers.includes(matched.id))
        return setError("This writer is already listed on the composition!");
      else props.submitWriter(matched);
    } else {
      await createWriter();
    }
  };

  const submitMatch = async (matched) => {
    if (ipi && society && !matched.ipi && !matched.society) {
      await axios.post(
        apiRoot,
        {
          query: `mutation addSocietyInfo($writerId: Int!, $society: PerformanceSociety!, $ipi: IPI!, $middleName: Name) {
            addSocietyInfo(writerId: $writerId, society: $society, ipi: $ipi, middleName: $middleName)
          }`,
          variables: {
            writerId: matched.id,
            society,
            ipi,
            middleName: middleName || null,
          },
        },
        { withCredentials: true }
      );
      props.submitWriter({ ...matched, ipi, society, middle_name: middleName });
    } else props.submitWriter(matched);
  };

  const createWriter = async () => {
    let writerInfo = { last_name: lastName };
    if (firstName) writerInfo.first_name = firstName;
    if (middleName) writerInfo.middle_name = middleName;
    if (ipi) writerInfo.ipi = ipi;
    if (society) writerInfo.society = society;
    const response = await axios.post(
      apiRoot,
      {
        query: `mutation createSongwriter($pubAcc: Int!, $writer: SongwriterInput!) {
          publishingAccount(id: $pubAcc) {
            createSongwriter(writer: $writer) {
              id
              ipi
              society
              first_name
              middle_name
              last_name
            }
          }
        }`,
        variables: {
          writer: writerInfo,
          pubAcc: authState.publishingAccount.id,
        },
      },
      { withCredentials: true }
    );
    if (response.data.errors) return setError(response.data.errors[0].message);
    props.submitWriter(response.data.data.publishingAccount?.createSongwriter);
  };

  if (match)
    return (
      <Container>
        <ReviewCreateWriter
          match={match}
          createWriter={createWriter}
          submitMatch={submitMatch}
          writers={props.writers}
          back={() => setMatch(null)}
          metadata={{ firstName, middleName, lastName, ipi, society }}
        />
      </Container>
    );

  return (
    <Container>
      <InputRow>
        <InputGroup>
          <Label>First name</Label>
          <Input
            autoComplete="none"
            value={firstName}
            onChange={(e) =>
              setFirstName(filterName(e.target.value).substr(0, 45))
            }
          />
        </InputGroup>
        <Divider />
        <InputGroup>
          <Label>Middle name</Label>
          <Input
            autoComplete="none"
            value={middleName}
            onChange={(e) =>
              setMiddleName(filterName(e.target.value).substr(0, 30))
            }
            placeholder="(optional)"
          />
        </InputGroup>
        <Divider />
        <InputGroup>
          <Label>Last name</Label>
          <Input
            autoComplete="none"
            value={lastName}
            onChange={(e) =>
              setLastName(filterName(e.target.value).substr(0, 30))
            }
          />
        </InputGroup>
      </InputRow>
      <InputRow>
        <InputGroup>
          <Label>
            PRO/CMO affiliation
            <Tooltip>
              If this songwriter is affiliated with a performing rights
              organization, select the relevant PRO to ensure their shares are
              matched properly.
            </Tooltip>
          </Label>
          <SocietySelect
            value={society}
            onChange={(society) => setSociety(society)}
          />
        </InputGroup>
      </InputRow>
      <InputRow>
        <InputGroup>
          <Label>
            IPI name number
            <Tooltip>
              The Interested Party Identifier is a number used to uniquely
              identify songwriters and publishers. If the writer is affiliated
              with a PRO, you'll need to add this. Ask your co-writers for their
              IPI and society if you don't know!
            </Tooltip>
          </Label>
          <Input
            autoComplete="none"
            value={ipi}
            onChange={(e) => setIpi(filterIpi(e.target.value).substr(0, 11))}
          />
        </InputGroup>
      </InputRow>
      {error && (
        <ErrorMsg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path
              fill="hsl(5, 100%, 70%)"
              d="M12 2a10 10 0 1 1 0 20 10 10 0 0 1 0-20zm0 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16zm0 9a1 1 0 0 1-1-1V8a1 1 0 0 1 2 0v4a1 1 0 0 1-1 1zm0 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
            />
          </svg>
          {error}
        </ErrorMsg>
      )}
      <Button onClick={preCreateWriter}>Create songwriter</Button>
    </Container>
  );
};

export default CreateWriter;
