import React from "react";
import { Redirect, Link } from "react-router-dom";
import { useGlobal } from "reactn";
import styled from "styled-components";
import UserSettings from "./UserSettings";
import PubSettings from "./PubSettings";
import { Helmet } from "react-helmet";

const MainComponent = styled.div`
  background: rgba(255, 255, 255, 0.05);
  max-width: 1240px;
  width: 100%;
  padding: 20px;
  box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  min-height: calc(100vh - 120px);
  display: flex;
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

const LeftSide = styled.div`
  max-width: 200px;
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.2);
  width: 100%;
  margin-left: -20px;
  margin-top: -20px;
  height: calc(100% - 20px);
  padding: 20px;
  padding-top: 40px;
  @media screen and (max-width: 600px) {
    max-width: 100%;
    flex: 0;
  }
`;

const RightSide = styled.div`
  padding: 20px 40px;
  h1 {
    color: white;
    margin: 0;
  }
  @media screen and (max-width: 600px) {
    padding: 20px 10px;
  }
`;

const SettingsTab = styled(Link)`
  display: block;
  border-radius: 2px;
  background: ${(props) =>
    props.active ? "rgba(255,255,255,0.2)" : "transparent"};
  color: ${(props) => (props.active ? "white" : "rgba(255,255,255,0.7)")};
  padding: 4px 10px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 5px;
  font-weight: 500;
  text-decoration: none;
  transition: background 0.15s;
  :hover {
    background: ${(props) =>
      props.active ? "rgba(255,255,255,0.2)" : "rgba(255,255,255,0.1)"};
  }
`;

const SmallHeading = styled.h5`
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.3px;
  color: rgba(255, 255, 255, 0.6);
  padding-left: 10px;
  margin-bottom: 10px;
`;

const Settings = (props) => {
  const [authState] = useGlobal("authState");
  if (authState && !authState.loading && !authState.authenticated)
    return <Redirect to="/login" />;
  if (
    authState &&
    !authState.loading &&
    authState.authenticated &&
    authState.setupStatus !== "done"
  )
    return <Redirect to="/dashboard" />;
  return (
    <MainComponent>
      <Helmet>
        <title>Settings | Music Inc. Client Portal</title>
      </Helmet>
      <LeftSide>
        <SettingsTab active={!props.match.params.accountId} to="/settings">
          Profile & Account
        </SettingsTab>
        {authState?.publishingAccounts?.length > 0 && (
          <SmallHeading>PUBLISHING ACCOUNTS</SmallHeading>
        )}
        {authState?.publishingAccounts
          ?.sort((a, b) => a.id - b.id)
          .map((account) => (
            <SettingsTab
              active={props.match.params.accountId === String(account.id)}
              to={`/settings/publishing/${account.id}`}
            >
              {account.name}
            </SettingsTab>
          ))}
      </LeftSide>
      <RightSide>
        {!props.match.params.accountId && <UserSettings />}
        {props.match.params.accountId && (
          <PubSettings id={Number(props.match.params.accountId)} />
        )}
      </RightSide>
    </MainComponent>
  );
};

export default Settings;
