import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { useGlobal } from "reactn";
import styled from "styled-components";
import InputMask from "react-input-mask";
import AddSongwriterModal from "./AddSongwriterModal";
import Tooltip from "../../components/Tooltip";
import Checkbox from "../../components/Checkbox";
import ReviewComposition from "./ReviewComposition";
import { Helmet } from "react-helmet";
import TagArray from "../../components/TagArray";

const MainComponent = styled.div`
  background: rgba(255, 255, 255, 0.05);
  max-width: 1200px;
  width: 100%;
  padding: 40px;
  box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  min-height: calc(100vh - 160px);
  display: flex;
  @media screen and (max-width: 600px) {
    padding: 20px;
  }
`;

const Underline = styled.span`
  display: inline-block;
  position: relative;
  color: white;
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  :after {
    background: linear-gradient(90deg, hsl(160, 95%, 40%), hsl(220, 92%, 45%));
    bottom: 0.6rem;
    left: -0.3rem;
    content: "";
    height: 0.4rem;
    opacity: 1;
    padding: 0 0.25rem;
    position: absolute;
    width: 100%;
    z-index: -1;
  }
  @media screen and (max-width: 600px) {
    font-size: 24px;
    :after {
      bottom: 0.4rem;
    }
  }
`;

const Details = styled.p`
  max-width: 700px;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;
  font-size: 14px;
  @media screen and (max-width: 600px) {
    font-size: 12px;
  }
`;

const MainPanel = styled.div`
  flex: 1;
  padding-right: 40px;
  color: white;
  @media screen and (max-width: 800px) {
    padding-right: 0;
  }
`;

const MainPanelInner = styled.div`
  max-width: 700px;
  h3 {
    margin-top: 40px;
  }
`;

const InfoPanel = styled.div`
  background: rgba(255, 255, 255, 0.05);
  max-width: 320px;
  margin-top: -40px;
  margin-right: -40px;
  padding: 40px 20px;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  @media screen and (max-width: 800px) {
    display: none;
  }
`;

const Question = styled.div`
  h4 {
    color: rgba(255, 255, 255, 0.8);
    font-size: 15px;
    font-weight: 600;
  }
  p {
    color: rgba(255, 255, 255, 0.6);
    font-size: 14px;
  }
`;

const InputGroup = styled.div`
  flex: 1;
  ${(props) =>
    !props.altTitle &&
    `@media screen and (max-width: 600px) {
    width: 100%;
  }`}
`;

const Label = styled.label`
  font-weight: 500;
  color: rgba(255, 255, 255, 0.6);
  font-size: 12px;
`;

const Input = styled.input`
  background: transparent;
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-radius: 2px;
  width: calc(100% - 24px);
  padding: 6px 10px;
  outline: none;
  height: 36px;
  box-sizing: border-box;
  -webkit-appearance: none;
  transition: border-color 0.2s;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;
  display: block;
  :focus {
    border-color: rgba(255, 255, 255, 0.3);
  }
  ::placeholder {
    color: rgba(255, 255, 255, 0.4);
  }
`;

const InputRow = styled.div`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  margin-bottom: 15px;
  @media screen and (max-width: 600px) {
    ${(props) =>
      !props.altTitle && props.rev
        ? "flex-direction: column-reverse;"
        : "flex-direction: column;"}
    margin-bottom: 10px;
  }
`;

const Divider = styled.div`
  margin-right: 0px;
  @media screen and (max-width: 600px) {
    margin-bottom: 10px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

const Button = styled.button`
  padding: 6px 16px;
  background: linear-gradient(135deg, hsl(160, 95%, 35%), hsl(220, 92%, 45%));
  color: white;
  font-weight: 600;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  transition: filter 0.2s;
  :hover {
    filter: brightness(110%);
  }
  svg {
    margin-left: 5px;
  }
`;

const AddAltTitle = styled.div`
  cursor: pointer;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: center;
  font-size: 14px;
  svg {
    margin-right: 15px;
    margin-bottom: 2px;
  }
`;

const VerticalDivider = styled.div`
  margin-bottom: 10px;
`;

const Remove = styled.div`
  margin-left: 10px;
  cursor: pointer;
  display: flex;
`;

const WriterContainer = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr 1fr;
  max-width: 100%;
  @media screen and (max-width: 900px) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media screen and (max-width: 670px) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

const Songwriter = styled.div`
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  background: rgba(255, 255, 255, 0.1);
  min-width: 180px;
  box-sizing: border-box;
  flex: 1;
  @media screen and (max-width: 600px) {
    width: 100%;
    max-width: 100%;
    margin-right: 0;
  }
`;

const WriterName = styled.h4`
  color: white;
  margin: 0;
`;

const WriterSociety = styled.h5`
  font-weight: 500;
  color: rgba(255, 255, 255, 0.5);
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: 12px;
`;

const You = styled.span`
  margin-left: 10px;
  background: rgba(255, 255, 255, 0.1);
  padding: 2px 10px;
  border-radius: 10px;
  letter-spacing: 0.5px;
  font-weight: 500;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
`;

const RemoveButton = styled.div`
  cursor: pointer;
  text-align: center;
  width: 100%;
  margin-top: 5px;
  padding: 10px 0 0 0;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 500;
  transition: color 0.2s;
  font-size: 12px;
  :hover {
    color: rgba(255, 255, 255, 0.6);
  }
`;

const AddWriter = styled.div`
  padding: 20px;
  border-radius: 4px;
  flex: 1;
  max-width: 100%;
  min-width: 180px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
  border: 2px dashed rgba(255, 255, 255, 0.1);
  transition: background 0.2s;
  :hover {
    background: rgba(255, 255, 255, 0.02);
  }
  @media screen and (max-width: 600px) {
    width: 100%;
    max-width: 100%;
    margin-right: 0;
  }
`;

const PerformerRow = styled.div`
  max-width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: -10px;
  margin-left: -10px;
  margin-right: -10px;
  @media screen and (max-width: 900px) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media screen and (max-width: 670px) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

const Performer = styled.div`
  box-sizing: border-box;
  flex: 1;
  margin: 10px;
  display: flex;
  align-items: center;
  @media screen and (max-width: 600px) {
    width: 100%;
    max-width: 100%;
  }
`;

const AddPerformer = styled(AddAltTitle)`
  margin: 10px;
  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

const SplitInput = styled.div`
  display: flex;
`;

const SplitInputInput = styled(Input)`
  flex: 1;
  border-radius: 2px 0 0 2px;
  border-right: none;
  :focus {
    + div {
      border-color: rgba(255, 255, 255, 0.3);
    }
  }
`;

const SplitInputLabel = styled.div`
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-left: none;
  border-radius: 0 2px 2px 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.5);
  transition: border-color 0.2s;
  cursor: default;
`;

const ErrorMsg = styled.div`
  color: hsl(5, 85%, 75%);
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  svg {
    margin-right: 10px;
    min-width: 24px;
  }
  @media screen and (max-width: 600px) {
    margin-bottom: 10px;
  }
`;

const Recording = styled.div`
  box-sizing: border-box;
  margin-bottom: 10px;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  background: rgba(255, 255, 255, 0.1);
`;

const RemoveRecording = styled.div`
  padding: 0 10px;
  margin-top: 20px;
  margin-left: 10px;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 500;
  font-size: 14px;
  transition: color 0.2s;
  display: flex;
  align-items: center;
  cursor: pointer;
  svg {
    margin-right: 5px;
  }
  :hover {
    color: rgba(255, 255, 255, 0.6);
  }
  @media screen and (max-width: 600px) {
    margin: 0;
    padding-right: 0;
    align-self: flex-end;
  }
`;

const filterTitle = (title) => {
  const charArray =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789 !\"#$%&'()*+,-./:;<=>?@[]\\^_`{}~£€".split(
      ""
    );
  return title
    .split("")
    .filter((i) => charArray.includes(i))
    .join("");
};

const filterShare = (share) => {
  const charArray = "0123456789.";
  let temp = share
    .split("")
    .filter((i) => charArray.includes(i))
    .join("");
  if (!Number(temp)) {
    let firstDot = temp.split("").indexOf(".");
    temp = temp
      .split("")
      .filter((i, n) => i !== "." || n === firstDot)
      .join("");
  }
  if (Number(temp) > 100) return "100";
  if (temp === "100.") return "100";
  if (temp.length === 0) return "";
  if (String(Number(temp)) === temp.substr(0, temp.length - 1)) return temp;
  if (temp.split(".")[1]?.length > 2)
    temp = temp.split(".")[0] + "." + temp.split(".")[1].substr(0, 2);
  return String(Number(temp));
};

const filterName = (name) => {
  const charArray =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789 !#$%&'()+-./?@`".split(
      ""
    );
  return name
    .split("")
    .filter((i) => charArray.includes(i))
    .join("");
};

const CreateComposition = () => {
  let [title, setTitle] = useState("");
  let [duration, setDuration] = useState("");
  let [iswc, setIswc] = useState("");
  let [altTitles, setAltTitles] = useState([]);
  let [writers, setWriters] = useState([]);
  let [recordings, setRecordings] = useState([{ isrc: "", performers: [""] }]);
  let [loaded, setLoaded] = useState(false);
  let [addingWriter, setAddingWriter] = useState(false);
  let [reviewing, setReviewing] = useState(false);
  let [error, setError] = useState("");
  let [tags, setTags] = useState([]);
  let [authState] = useGlobal("authState");

  useEffect(() => {
    if (!loaded && authState?.publishingAccount?.songwriter) {
      const writer = authState.publishingAccount.songwriter;
      setWriters([
        {
          ...writer,
          share: "",
          controlled: true,
        },
      ]);
      setLoaded(true);
    }
    if (
      !loaded &&
      authState?.publishingAccount &&
      !authState.publishingAccount.songwriter
    ) {
      setLoaded(true);
    }
  }, [authState?.publishingAccount, loaded]);

  const checkAndReview = () => {
    // run the validation checks
    // then set reviewing to true
    if (!title) return setError("Title required!");
    if (duration && duration.length < 4) return setError("Invalid duration!");
    if (iswc && iswc.length < 13) return setError("Invalid ISWC!");
    try {
      altTitles.forEach((title) => {
        if (!title) throw new Error("Alternate titles must not be empty!");
      });
      for (const i of altTitles) {
        // Confirm not the same as title
        if (title.toLowerCase().trim() === i.toLowerCase().trim())
          throw new Error(
            "Alternate titles must not be the same as the title!"
          );
        // Confirm no other alt titles match
        if (altTitles.filter((x) => x === i).length > 1)
          throw new Error("Alternate titles must not be duplicated!");
      }
      recordings.forEach((rec) => {
        if (rec.isrc && rec.isrc.length < 15) throw new Error("Invalid ISRC!");
        if (recordings.filter((i) => i.isrc === rec.isrc).length > 1)
          throw new Error("Multiple recordings cannot have the same ISRC!");
      });
    } catch (err) {
      return setError(err.message);
    }
    if (writers.length === 0) return setError("At least one writer required!");
    if (writers.filter((i) => i.controlled).length === 0)
      return setError("At least one controlled writer required!");
    let totalShare =
      Math.round(
        writers.reduce((prev, cur) => prev + Number(cur.share) * 100, 0) * 10
      ) / 1000;
    if (totalShare > 100.001 || totalShare < 99.999)
      return setError(
        `Your writer shares currently total ${totalShare}%, but should total 100%!`
      );
    if (writers.filter((i) => !i.share || Number(i.share) === 0).length > 0)
      return setError("All writers must have nonzero shares!");
    setRecordings(
      recordings
        .filter((i) => i.isrc || i.performers.filter((x) => x).length > 0)
        .map((i) => ({ ...i, performers: i.performers.filter((x) => x) }))
    );
    setError("");
    setReviewing(true);
    window.scrollTo(0, 0);
  };

  if (authState && !authState.loading && !authState.authenticated)
    return <Redirect to="/login" />;
  if (
    authState &&
    !authState.loading &&
    authState.authenticated &&
    authState.setupStatus !== "done"
  ) {
    return <Redirect to="/dashboard" />;
  }
  if (
    authState &&
    !authState.loading &&
    authState.authenticated &&
    authState.publishingAccounts.length === 0
  )
    return <Redirect to="/settings" />;

  if (reviewing)
    return (
      <MainComponent>
        <ReviewComposition
          composition={{
            title,
            duration,
            iswc,
            altTitles,
            writers,
            recordings,
            tags,
          }}
          back={() => setReviewing(false)}
        />
      </MainComponent>
    );

  return (
    <MainComponent>
      <Helmet>
        <title>Register Composition | Music Inc. Client Portal</title>
      </Helmet>
      {addingWriter && (
        <AddSongwriterModal
          close={() => setAddingWriter(false)}
          writers={writers.map((i) => i.id)}
          addWriter={(writer) =>
            setWriters([
              ...writers,
              { ...writer, share: "", controlled: false },
            ])
          }
        />
      )}
      <MainPanel>
        <MainPanelInner>
          <h1>
            <Underline>Register composition</Underline>
          </h1>
          <Details>
            All you need to do to start collecting your publishing royalties is
            add the details in this form about each of your works. If you have
            any questions, don't hesitate to reach out - we're here to help!
          </Details>
          <InputRow>
            <InputGroup>
              <Label>Title</Label>
              <Input
                value={title}
                onChange={(e) =>
                  setTitle(filterTitle(e.target.value).substr(0, 60))
                }
              ></Input>
            </InputGroup>
          </InputRow>
          <InputRow>
            <InputGroup>
              <Label>Duration</Label>
              <InputMask
                mask="99:99"
                maskChar=""
                value={duration}
                onChange={(e) => setDuration(e.target.value)}
                beforeMaskedValueChange={(newState, _, i) => {
                  if (i === ":" && !newState.value.split("").includes(":")) {
                    if (newState.value.length === 1)
                      return {
                        value: "0" + newState.value + ":",
                        selection: { start: 3, end: 3 },
                      };
                    return {
                      value: newState.value + ":",
                      selection: { start: 3, end: 3 },
                    };
                  }
                  return newState;
                }}
              >
                {(inputProps) => (
                  <Input {...inputProps} placeholder="(optional)"></Input>
                )}
              </InputMask>
            </InputGroup>
            <Divider />
            <InputGroup>
              <Label>
                ISWC
                <Tooltip>
                  The International Standard Musical Work Code is a unique
                  identifier for a composition. You might have one if your
                  composition has already been registered with a PRO, whether by
                  you or someone else. If you don't have one, that's fine - you
                  don't need to enter this.
                </Tooltip>
              </Label>
              <InputMask
                mask="T-999999999-9"
                maskChar=""
                value={iswc}
                onChange={(e) => setIswc(e.target.value)}
              >
                {(inputProps) => (
                  <Input
                    {...inputProps}
                    placeholder="(optional - only if assigned!)"
                  />
                )}
              </InputMask>
            </InputGroup>
          </InputRow>
          <Label>
            Tags
            <Tooltip>
              Tags can help you to organize your works. You can filter your
              catalog by assigned tags, as well as use them for royalty
              analysis. For example, you can use tags for albums, labels, or
              other attributes you might find useful to associate with your
              songs.
            </Tooltip>
          </Label>
          <VerticalDivider />
          <TagArray
            tags={tags}
            addTag={(tag) => setTags([...tags, tag])}
            removeTag={(tag) =>
              setTags(tags.filter((i) => i.tag !== tag.tag && i.id !== tag.id))
            }
          />
          <VerticalDivider />
          <Label>
            Alternate titles
            <Tooltip>
              Add anything here that might help your composition be matched
              correctly, including parenthetical titles and different ways the
              title might be written. Replacing or removing punctuation and
              special characters are good ideas if your title has any! Don't
              worry about capitalization.
            </Tooltip>
          </Label>
          <VerticalDivider />
          {altTitles.map((altTitle, index) => (
            <InputRow key={index} altTitle={true}>
              <InputGroup altTitle={true}>
                <Input
                  value={altTitle}
                  onChange={(e) =>
                    setAltTitles(
                      altTitles.map((i, in2) =>
                        in2 === index
                          ? filterTitle(e.target.value).substr(0, 60)
                          : i
                      )
                    )
                  }
                ></Input>
              </InputGroup>
              <Remove
                onClick={() =>
                  setAltTitles(altTitles.filter((_, i) => i !== index))
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path
                    fill="rgba(255,255,255,0.6)"
                    fill-rule="evenodd"
                    d="M15.78 14.36a1 1 0 0 1-1.42 1.42l-2.82-2.83-2.83 2.83a1 1 0 1 1-1.42-1.42l2.83-2.82L7.3 8.7a1 1 0 0 1 1.42-1.42l2.83 2.83 2.82-2.83a1 1 0 0 1 1.42 1.42l-2.83 2.83 2.83 2.82z"
                  />
                </svg>
              </Remove>
            </InputRow>
          ))}
          <AddAltTitle onClick={() => setAltTitles([...altTitles, ""])}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="2 2 20 20"
              width="22"
              height="22"
            >
              <path
                fill="rgba(255,255,255,0.6)"
                d="M13 11h4a1 1 0 0 1 0 2h-4v4a1 1 0 0 1-2 0v-4H7a1 1 0 0 1 0-2h4V7a1 1 0 0 1 2 0v4z"
              />
            </svg>
            Add alternate title
          </AddAltTitle>
          <h3>Songwriters</h3>
          <WriterContainer>
            {writers.map((i) => (
              <Songwriter key={i.id}>
                <WriterName>
                  {i.first_name ? `${i.first_name} ` : ""}
                  {i.middle_name ? `${i.middle_name} ` : ""}
                  {i.last_name}
                  {i.id === authState?.publishingAccount?.songwriter?.id && (
                    <You>YOU</You>
                  )}
                </WriterName>
                <WriterSociety>
                  {i.ipi && i.society
                    ? `${i.society} — IPI: ${i.ipi}`
                    : "No society affiliation"}
                </WriterSociety>
                <InputGroup>
                  <Label>Collection share</Label>
                  <SplitInput>
                    <SplitInputInput
                      value={i.share}
                      onChange={(e) =>
                        setWriters(
                          writers.map((w) =>
                            w.id === i.id
                              ? { ...w, share: filterShare(e.target.value) }
                              : w
                          )
                        )
                      }
                    ></SplitInputInput>
                    <SplitInputLabel>%</SplitInputLabel>
                  </SplitInput>
                </InputGroup>
                {authState?.publishingAccount?.type !== "songwriter" && (
                  <VerticalDivider />
                )}
                {authState?.publishingAccount?.type !== "songwriter" && (
                  <Checkbox
                    active={i.controlled}
                    setActive={(controlled) =>
                      setWriters(
                        writers.map((w) =>
                          w.id === i.id ? { ...w, controlled } : w
                        )
                      )
                    }
                    small={true}
                  >
                    Controlled
                  </Checkbox>
                )}
                {i.id !== authState?.publishingAccount?.songwriter?.id && (
                  <RemoveButton
                    onClick={() =>
                      setWriters(writers.filter((w) => w.id !== i.id))
                    }
                  >
                    Remove
                  </RemoveButton>
                )}
              </Songwriter>
            ))}
            <AddWriter onClick={() => setAddingWriter(true)}>
              Add writer
            </AddWriter>
          </WriterContainer>
          <h3>Recordings</h3>
          <Details>
            Adding this information about recordings of your song, if
            applicable, helps match your royalties properly. If you don't have
            an ISRC yet but your track will be released on streaming platforms,
            we suggest waiting until you have an ISRC to register your
            composition.
          </Details>
          {recordings.map((recording, index) => (
            <Recording key={index}>
              <InputRow rev>
                <InputGroup>
                  <Label>
                    ISRC
                    <Tooltip>
                      The International Standard Recording Code is a unique
                      identifier for a recording. This is usually assigned by
                      your distributor or record label.
                    </Tooltip>
                  </Label>
                  <InputMask
                    mask="aa-***-99-99999"
                    maskChar=""
                    value={recording.isrc}
                    onChange={(e) =>
                      setRecordings(
                        recordings.map((r, i) =>
                          i === index
                            ? { ...r, isrc: e.target.value.toUpperCase() }
                            : r
                        )
                      )
                    }
                  >
                    {(inputProps) => <Input {...inputProps} placeholder="" />}
                  </InputMask>
                </InputGroup>
                <RemoveRecording
                  onClick={() =>
                    setRecordings(recordings.filter((r, i) => i !== index))
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path
                      fill="rgba(255,255,255,0.4)"
                      fill-rule="evenodd"
                      d="M15.78 14.36a1 1 0 0 1-1.42 1.42l-2.82-2.83-2.83 2.83a1 1 0 1 1-1.42-1.42l2.83-2.82L7.3 8.7a1 1 0 0 1 1.42-1.42l2.83 2.83 2.82-2.83a1 1 0 0 1 1.42 1.42l-2.83 2.83 2.83 2.82z"
                    />
                  </svg>{" "}
                  Remove recording
                </RemoveRecording>
              </InputRow>
              <Label>
                Performing artists
                <Tooltip>
                  Use the artist names or aliases as they appear on streaming
                  platforms, if different from the artist's full name
                </Tooltip>
              </Label>
              <VerticalDivider />
              <PerformerRow>
                {recording.performers.map((performer, per_index) => (
                  <Performer key={per_index}>
                    <InputGroup>
                      <Input
                        value={performer}
                        onChange={(e) =>
                          setRecordings(
                            recordings.map((r, i) =>
                              i === index
                                ? {
                                    ...r,
                                    performers: r.performers.map((p, in2) =>
                                      in2 === per_index
                                        ? filterName(e.target.value).substr(
                                            0,
                                            45
                                          )
                                        : p
                                    ),
                                  }
                                : r
                            )
                          )
                        }
                      ></Input>
                    </InputGroup>
                    <Remove
                      onClick={() =>
                        setRecordings(
                          recordings.map((r, i) =>
                            i === index
                              ? {
                                  ...r,
                                  performers: r.performers.filter(
                                    (_, in2) => in2 !== per_index
                                  ),
                                }
                              : r
                          )
                        )
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                      >
                        <path
                          fill="rgba(255,255,255,0.4)"
                          fill-rule="evenodd"
                          d="M15.78 14.36a1 1 0 0 1-1.42 1.42l-2.82-2.83-2.83 2.83a1 1 0 1 1-1.42-1.42l2.83-2.82L7.3 8.7a1 1 0 0 1 1.42-1.42l2.83 2.83 2.82-2.83a1 1 0 0 1 1.42 1.42l-2.83 2.83 2.83 2.82z"
                        />
                      </svg>
                    </Remove>
                  </Performer>
                ))}
                <AddPerformer
                  onClick={() =>
                    setRecordings(
                      recordings.map((r, i) =>
                        i === index
                          ? { ...r, performers: [...r.performers, ""] }
                          : r
                      )
                    )
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="2 2 20 20"
                    width="22"
                    height="22"
                  >
                    <path
                      fill="rgba(255,255,255,0.5)"
                      d="M13 11h4a1 1 0 0 1 0 2h-4v4a1 1 0 0 1-2 0v-4H7a1 1 0 0 1 0-2h4V7a1 1 0 0 1 2 0v4z"
                    />
                  </svg>
                  Add artist
                </AddPerformer>
              </PerformerRow>
            </Recording>
          ))}
          <AddAltTitle
            style={{ marginTop: "20px" }}
            onClick={() =>
              setRecordings([...recordings, { isrc: "", performers: [""] }])
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="2 2 20 20"
              width="22"
              height="22"
            >
              <path
                fill="rgba(255,255,255,0.5)"
                d="M13 11h4a1 1 0 0 1 0 2h-4v4a1 1 0 0 1-2 0v-4H7a1 1 0 0 1 0-2h4V7a1 1 0 0 1 2 0v4z"
              />
            </svg>
            Add recording
          </AddAltTitle>
          <ButtonContainer>
            {error ? (
              <ErrorMsg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path
                    fill="hsl(5, 100%, 70%)"
                    d="M12 2a10 10 0 1 1 0 20 10 10 0 0 1 0-20zm0 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16zm0 9a1 1 0 0 1-1-1V8a1 1 0 0 1 2 0v4a1 1 0 0 1-1 1zm0 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
                  />
                </svg>
                {error}
              </ErrorMsg>
            ) : (
              <div />
            )}
            <Button onClick={checkAndReview}>
              Review composition
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                height="24"
                width="24"
              >
                <path
                  fill="white"
                  d="M14.59 13H7a1 1 0 0 1 0-2h7.59l-2.3-2.3a1 1 0 1 1 1.42-1.4l4 4a1 1 0 0 1 0 1.4l-4 4a1 1 0 0 1-1.42-1.4l2.3-2.3z"
                />
              </svg>
            </Button>
          </ButtonContainer>
        </MainPanelInner>
      </MainPanel>
      <InfoPanel>
        <Question>
          <h4>
            Why are some characters prohibited?
            <br />
            Why can't I type beyond a certain length?
          </h4>
          <p>
            The formats we use to deliver musical work data to our collection
            society partners require certain character sets and maximum lengths.
            We pass these requirements along to you to be sure that we transmit
            the most accurate possible data. This helps societies to match data
            better and ensures you're paid everything you're owed.
          </p>
        </Question>
      </InfoPanel>
    </MainComponent>
  );
};

export default CreateComposition;
