import React, { useState } from "react";
import { useEffect, useGlobal } from "reactn";
import axios from "axios";
import styled from "styled-components";
import apiRoot from "../../apiRoot";
import Checkbox from "../../components/Checkbox";
import Tooltip from "../../components/Tooltip";

const Container = styled.div`
  max-width: 400px;
`;

const Divider = styled.div`
  width: 90%;
  margin: 30px auto;
  height: 1px;
  background: rgba(255, 255, 255, 0.15);
`;

const InputGroup = styled.div`
  h3 {
    color: white;
    margin-top: 0;
    margin-bottom: 10px;
  }
`;

const Label = styled.label`
  font-weight: 500;
  color: rgba(255, 255, 255, 0.6);
  font-size: 12px;
`;

const Input = styled.input`
  background: transparent;
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-radius: 2px;
  width: calc(100% - 24px);
  padding: 6px 10px;
  outline: none;
  transition: border-color 0.2s;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;
  margin-bottom: 10px;
  :focus {
    border-color: rgba(255, 255, 255, 0.3);
  }
`;

const Button = styled.button`
  cursor: pointer;
  background: rgba(255, 255, 255, 0.2);
  color: hsl(221, 92%, 75%);
  font-weight: 600;
  display: block;
  text-align: center;
  padding: 8px;
  width: 100%;
  border: none;
  border-radius: 2px;
  transition: background 0.2s;
  outline: none;
  margin-top: 10px;
  :hover {
    background: rgba(255, 255, 255, 0.25);
  }
  &:disabled {
    background: rgba(255, 255, 255, 0.1);
    cursor: default;
  }
`;

const ErrorMsg = styled.div`
  color: hsl(5, 85%, 75%);
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  svg {
    margin-right: 10px;
  }
`;

const SuccessMsg = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: white;
  margin-bottom: 10px;
`;

const Info = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 20px;
`;

const Divider2 = styled.div`
  margin-bottom: 15px;
`;

const Text = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 10px;
`;

const UserSettings = () => {
  let [authState, setAuthState] = useGlobal("authState");
  let [loaded, setLoaded] = useState(authState?.user ? true : false);
  let [name, setName] = useState(authState?.user?.name);
  let [newEmail, setNewEmail] = useState("");
  let [confirmPassEmail, setConfirmPassEmail] = useState("");
  let [currentPass, setCurrentPass] = useState("");
  let [newPass, setNewPass] = useState("");
  let [confirmNewPass, setConfirmNewPass] = useState("");
  let [working1, setWorking1] = useState(false);
  let [working2, setWorking2] = useState(false);
  let [working3, setWorking3] = useState(false);
  let [working4, setWorking4] = useState(false);
  let [error1, setError1] = useState("");
  let [error2, setError2] = useState("");
  let [error3, setError3] = useState("");
  let [error4, setError4] = useState("");
  let [emailSettings, setEmailSettings] = useState(null);
  let [success2, setSuccess2] = useState(false);
  if (authState?.user && !loaded) {
    setName(authState.user.name);
    setLoaded(true);
  }

  useEffect(() => {
    async function getEmailSettings() {
      const res = await axios.post(
        apiRoot,
        {
          query: `
        query getEmailSettings {
          me {
            emailSettings {
              submission_request
              royalty_statement
              other_deposit
              payment_send
              label_statement
            }
          }
        }
        `,
        },
        { withCredentials: true }
      );
      setEmailSettings(res.data.data?.me?.emailSettings);
    }
    getEmailSettings();
  }, [setEmailSettings]);

  async function mutate(details, password) {
    const response = await axios.post(
      apiRoot,
      {
        query: `mutation changeInfo($details: UserDetails!${
          password ? ", $password: Password" : ""
        }) {
        changeInfo(details: $details${password ? ", password: $password" : ""})
      }`,
        variables: password ? { details, password } : { details },
      },
      { withCredentials: true }
    );
    if (response.data.errors) return response.data.errors[0].message;
    return "";
  }

  async function saveProfileChanges() {
    if (!name) return setError1("Name required!");
    if (name.length > 50)
      return setError1("Name must be 50 characters or less!");
    setWorking1(true);
    setError1(await mutate({ name }));
    setWorking1(false);
    setAuthState({ ...authState, user: { ...authState.user, name } });
  }

  async function changeEmail() {
    if (!newEmail) return setError2("Email address required!");
    if (!confirmPassEmail) return setError2("Password required!");
    if (newEmail.length > 254) return setError2("Email is too long!");
    if (confirmPassEmail.length < 8 || confirmPassEmail.length > 128)
      return setError2("Invalid password!");
    if (
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        newEmail
      )
    )
      return setError2("Invalid email!");
    setWorking2(true);
    const e = await mutate({ email: newEmail }, confirmPassEmail);
    setError2(e);
    if (!e) {
      setSuccess2(true);
      setNewEmail("");
      setConfirmPassEmail("");
    }
    setWorking2(false);
  }

  async function changePassword() {
    if (currentPass.length < 8 || currentPass.length > 128)
      return setError3("Invalid password!");
    if (newPass.length < 8)
      return setError3("Password must be at least 8 characters long!");
    if (newPass.length > 128)
      return setError3("Password must be 128 characters or less!");
    if (newPass !== confirmNewPass)
      return setError3("Password and confirmation must match!");
    setWorking3(true);
    const e = await mutate({ password: newPass }, currentPass);
    setError3(e);
    if (!e) {
      setCurrentPass("");
      setNewPass("");
      setConfirmNewPass("");
    }
    setWorking3(false);
  }

  async function saveEmailNotifications() {
    setWorking4(true);
    // mutate, set error
    const response = await axios.post(
      apiRoot,
      {
        query: `mutation changeInfo($submission_request: Boolean!, $royalty_statement: Boolean!, $other_deposit: Boolean!, $payment_send: Boolean!, $label_statement: Boolean!) {
          changeEmailSettings(submission_request:$submission_request,royalty_statement:$royalty_statement,other_deposit:$other_deposit,payment_send:$payment_send,label_statement:$label_statement)
        }`,
        variables: emailSettings,
      },
      { withCredentials: true }
    );
    if (response.data.errors) setError4(response.data.errors[0].message);
    setWorking4(false);
  }

  return (
    <Container>
      <InputGroup>
        <h3>Profile</h3>
        <Label>Account name</Label>
        <Input value={name} onChange={(e) => setName(e.target.value)}></Input>
        <Label>Email address</Label>
        <Text>{authState?.user?.email}</Text>
        <Divider2 />
        {error1 && (
          <ErrorMsg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path
                fill="hsl(5, 100%, 70%)"
                d="M12 2a10 10 0 1 1 0 20 10 10 0 0 1 0-20zm0 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16zm0 9a1 1 0 0 1-1-1V8a1 1 0 0 1 2 0v4a1 1 0 0 1-1 1zm0 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
              />
            </svg>
            {error1}
          </ErrorMsg>
        )}
        <Button disabled={working1} onClick={saveProfileChanges}>
          Save changes
        </Button>
      </InputGroup>
      <Divider />
      <InputGroup>
        <h3>Change Email</h3>
        <Label>New email address</Label>
        <Input
          value={newEmail}
          onChange={(e) => setNewEmail(e.target.value)}
        ></Input>
        <Label>Confirm password</Label>
        <Input
          value={confirmPassEmail}
          onChange={(e) => setConfirmPassEmail(e.target.value)}
          type="password"
        ></Input>
        {error2 && (
          <ErrorMsg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path
                fill="hsl(5, 100%, 70%)"
                d="M12 2a10 10 0 1 1 0 20 10 10 0 0 1 0-20zm0 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16zm0 9a1 1 0 0 1-1-1V8a1 1 0 0 1 2 0v4a1 1 0 0 1-1 1zm0 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
              />
            </svg>
            {error2}
          </ErrorMsg>
        )}
        {success2 && (
          <SuccessMsg>
            To confirm your email change, check your new email address for a
            confirmation link.
          </SuccessMsg>
        )}
        <Button disabled={working2} onClick={changeEmail}>
          Change email
        </Button>
      </InputGroup>
      <Divider />
      <InputGroup>
        <h3>Change Password</h3>
        <Label>Current password</Label>
        <Input
          value={currentPass}
          onChange={(e) => setCurrentPass(e.target.value)}
          type="password"
        ></Input>
        <Label>New password</Label>
        <Input
          value={newPass}
          onChange={(e) => setNewPass(e.target.value)}
          type="password"
        ></Input>
        <Label>Confirm new password</Label>
        <Input
          value={confirmNewPass}
          onChange={(e) => setConfirmNewPass(e.target.value)}
          type="password"
        ></Input>
        {error3 && (
          <ErrorMsg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path
                fill="hsl(5, 100%, 70%)"
                d="M12 2a10 10 0 1 1 0 20 10 10 0 0 1 0-20zm0 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16zm0 9a1 1 0 0 1-1-1V8a1 1 0 0 1 2 0v4a1 1 0 0 1-1 1zm0 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
              />
            </svg>
            {error3}
          </ErrorMsg>
        )}
        <Button disabled={working3} onClick={changePassword}>
          Change password
        </Button>
      </InputGroup>
      <Divider />
      <InputGroup>
        <h3>Email Notifications</h3>
        <Info>
          Select any events below about which you would like to be notified by
          email.
        </Info>
        {!emailSettings && <Info>Loading...</Info>}
        {emailSettings && (
          <div>
            {authState.publishingAccounts?.length > 0 && (
              <Checkbox
                active={emailSettings.submission_request}
                setActive={() =>
                  setEmailSettings({
                    ...emailSettings,
                    submission_request: !emailSettings.submission_request,
                  })
                }
              >
                New submission requests
                <Tooltip>
                  Submission requests allow other users submitting compositions
                  on which you are listed to invite you to add the composition
                  on Music Inc. in a single click. You can disable them entirely
                  in publishing account settings.
                </Tooltip>
              </Checkbox>
            )}
            {authState.publishingAccounts?.length > 0 && <Divider2 />}
            {authState.publishingAccounts?.length > 0 && (
              <Checkbox
                active={emailSettings.royalty_statement}
                setActive={() =>
                  setEmailSettings({
                    ...emailSettings,
                    royalty_statement: !emailSettings.royalty_statement,
                  })
                }
              >
                Publishing administration royalty statements
              </Checkbox>
            )}
            {authState.publishingAccounts?.length > 0 && <Divider2 />}
            <Checkbox
              active={emailSettings.other_deposit}
              setActive={() =>
                setEmailSettings({
                  ...emailSettings,
                  other_deposit: !emailSettings.other_deposit,
                })
              }
            >
              Money received in Music Inc. Payments
            </Checkbox>
            <Divider2 />
            <Checkbox
              active={emailSettings.payment_send}
              setActive={() =>
                setEmailSettings({
                  ...emailSettings,
                  payment_send: !emailSettings.payment_send,
                })
              }
            >
              Payouts sent
            </Checkbox>
            <Divider2 />
            {authState.user?.hasLabelArtists && (
              <Checkbox
                active={emailSettings.label_statement}
                setActive={() =>
                  setEmailSettings({
                    ...emailSettings,
                    label_statement: !emailSettings.label_statement,
                  })
                }
              >
                Label royalty statements
              </Checkbox>
            )}
            {authState.user?.hasLabelArtists && <Divider2 />}
          </div>
        )}
        {error4 && (
          <ErrorMsg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path
                fill="hsl(5, 100%, 70%)"
                d="M12 2a10 10 0 1 1 0 20 10 10 0 0 1 0-20zm0 2a8 8 0 1 0 0 16 8 8 0 0 0 0-16zm0 9a1 1 0 0 1-1-1V8a1 1 0 0 1 2 0v4a1 1 0 0 1-1 1zm0 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
              />
            </svg>
            {error4}
          </ErrorMsg>
        )}
        <Button disabled={working4} onClick={saveEmailNotifications}>
          Save changes
        </Button>
      </InputGroup>
    </Container>
  );
};

export default UserSettings;
