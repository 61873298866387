import React, { useState, useEffect } from "react";
import { useGlobal } from "reactn";
import { Link } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import InfiniteScroll from "react-infinite-scroller";
import apiRoot from "../../apiRoot";
import { Helmet } from "react-helmet";
import FilterSort from "./FilterSort";
import { format } from "date-fns";

const Container = styled.div`
  display: flex;
  margin-top: 30px;
  @media screen and (max-width: 400px) {
    margin-top: 10px;
  }
`;

const ErrorMsg = styled.div`
  color: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;
  margin-top: 25px;
  svg {
    margin-right: 10px;
    min-width: 32px;
  }
`;

const LeftSide = styled.div`
  flex: 1;
`;

const Composition = styled(Link)`
  text-decoration: none;
  display: block;
  padding: 20px 0;
  border-bottom: ${(props) => (props.last ? "0" : "1px")} solid
    rgba(255, 255, 255, 0.2);
`;

const CompositionTitle = styled.h3`
  color: white;
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 24px;
  @media screen and (max-width: 800px) {
    font-size: 18px;
    margin-bottom: 10px;
  }
`;

const Writers = styled.p`
  color: rgba(255, 255, 255, 0.8);
  margin: 0;
  font-size: 14px;
  a {
    color: rgba(255, 255, 255, 0.8);
  }
  @media screen and (max-width: 400px) {
    font-size: 12px;
  }
`;

const Created = styled.p`
  color: rgba(255, 255, 255, 0.6);
  font-weight: 500;
  margin: 4px 0 0 0;
  font-size: 12px;
`;

const NoCompositions = styled.div`
  text-align: center;
  margin-top: 20px;
`;

const Input = styled.input`
  background: rgba(255, 255, 255, 0.05);
  border: none;
  border-radius: 2px;
  width: calc(100% - 24px);
  padding: 6px 10px;
  outline: none;
  transition: background 0.2s;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;
  display: block;
  :focus {
    background: rgba(255, 255, 255, 0.1);
  }
  ::placeholder {
    color: rgba(255, 255, 255, 0.4);
  }
`;

const FlexContainer = styled.div`
  margin: 10px 0;
  display: flex;
  align-items: center;
`;

const SortOpen = styled.div`
  display: flex;
  cursor: pointer;
  margin-left: 15px;
  color: rgba(255, 255, 255, 0.6);
  transition: color 0.2s;
  @media screen and (min-width: 801px) {
    display: none;
  }
  :hover {
    color: rgba(255, 255, 255, 0.8);
  }
  svg {
    width: 24px;
    height: 24px;
  }
`;

const Compositions = () => {
  const [authState] = useGlobal("authState");
  let [compositions, setCompositions] = useState({ works: [] });
  let [tags, setTags] = useState([]);
  let [sortDirection, setSortDirection] = useState("asc");
  let [sortBy, setSortBy] = useState("title");
  let [selectedTags, setSelectedTags] = useState([]);
  let [sortOpen, setSortOpen] = useState(false);
  let [loading, setLoading] = useState(false);
  let [error, setError] = useState("");
  let [filter, setFilter] = useState("");

  async function getTags() {
    if (!authState?.publishingAccount?.id) return;
    const response = await axios.post(
      apiRoot,
      {
        query: `
          query getTags($id: Int!) {
            publishingAccount(id: $id) {
              tags {
                id
                tag
              }
            }
          }`,
        variables: { id: authState.publishingAccount.id },
      },
      { withCredentials: true }
    );
    setTags(response.data.data?.publishingAccount?.tags);
  }

  async function getCompositions(offset = 0) {
    if (!authState?.publishingAccount) return;
    if (offset < compositions.works.length && offset > 0) return;
    setLoading(true);
    const response = await axios.post(
      apiRoot,
      {
        query: `
          query getCompositions($id: Int!, $offset: Int!,
            $sortBy: WorkSortBy, $sortOrder: SortOrder, $filterTags: [Int!]) {
            publishingAccount(id: $id) {
              works(offset: $offset, sortBy: $sortBy, sortOrder: $sortOrder, filterTags: $filterTags) {
                works {
                  id
                  title
                  created_at
                  songwriters {
                    songwriter {
                      id
                      first_name
                      middle_name
                      last_name
                    }
                  }
                }
                isNextPage
              }
            }
          }`,
        variables: {
          id: authState.publishingAccount.id,
          offset,
          sortBy,
          sortOrder: sortDirection,
          filterTags: selectedTags,
        },
      },
      { withCredentials: true }
    );
    setLoading(false);
    if (response.data.errors) return setError(response.data.errors[0].message);
    if (response?.data?.data?.publishingAccount?.works) {
      setCompositions({
        works:
          offset === 0
            ? response.data.data.publishingAccount.works.works
            : [
                ...compositions.works,
                ...response.data.data.publishingAccount.works.works,
              ],
        isNextPage: response.data.data.publishingAccount.works.isNextPage,
      });
    }
  }

  const pubAccId = authState?.publishingAccount?.id;
  useEffect(() => {
    getCompositions();
    // eslint-disable-next-line
  }, [pubAccId, sortDirection, sortBy, selectedTags]);

  useEffect(() => {
    getTags();
    // eslint-disable-next-line
  }, [pubAccId]);

  const filterCompositions = compositions.works.filter((i) => {
    return i.title.toLowerCase().includes(filter.toLowerCase());
  });

  return (
    <Container>
      <Helmet>
        <title>Your Compositions | Music Inc. Client Portal</title>
      </Helmet>
      <LeftSide>
        {error && (
          <ErrorMsg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              class="icon-important"
              width="32"
              height="32"
            >
              <path
                fill="rgba(255,255,255,0.2)"
                d="M12 2a10 10 0 1 1 0 20 10 10 0 0 1 0-20z"
              />
              <path
                fill="rgba(255,255,255,0.9)"
                d="M12 18a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm1-5.9c-.13 1.2-1.88 1.2-2 0l-.5-5a1 1 0 0 1 1-1.1h1a1 1 0 0 1 1 1.1l-.5 5z"
              />
            </svg>
            {error}
          </ErrorMsg>
        )}
        <FlexContainer>
          <Input
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            placeholder="Filter compositions (by title)"
          />
          <SortOpen onClick={() => setSortOpen(true)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M3 7.5L7.5 3m0 0L12 7.5M7.5 3v13.5m13.5 0L16.5 21m0 0L12 16.5m4.5 4.5V7.5"
              />
            </svg>
          </SortOpen>
        </FlexContainer>
        <InfiniteScroll
          pageStart={0}
          loadMore={() => {
            if (!loading) getCompositions(compositions.works.length);
          }}
          hasMore={compositions.isNextPage}
        >
          {filterCompositions.map((i, index) => (
            <Composition
              key={i.id}
              to={`/composition/${i.id}`}
              last={index === filterCompositions.length - 1}
            >
              <CompositionTitle>{i.title}</CompositionTitle>
              <Writers>
                {i.songwriters.map(({ songwriter: writer }, index) => (
                  <span key={writer.id}>
                    {writer.first_name && `${writer.first_name} `}
                    {writer.middle_name && `${writer.middle_name} `}
                    {writer.last_name}
                    {index < i.songwriters.length - 1 && ", "}
                  </span>
                ))}
              </Writers>
              <Created>
                Registered{" "}
                {format(new Date(i.created_at), "MMMM d, yyyy, h:mm a")}
              </Created>
            </Composition>
          ))}
        </InfiniteScroll>
        {loading && <Writers>Loading...</Writers>}
        {(compositions.works.length > 0 &&
          !loading &&
          !error &&
          filterCompositions.length === 0) ||
          (compositions.works.length === 0 && selectedTags.length > 0 && (
            <NoCompositions>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="64"
                height="64"
              >
                <path
                  fill="rgba(255,255,255,0.2)"
                  d="M12 2a10 10 0 1 1 0 20 10 10 0 0 1 0-20z"
                />
                <path
                  fill="rgba(255,255,255,0.9)"
                  d="M12 18a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm1-5.9c-.13 1.2-1.88 1.2-2 0l-.5-5a1 1 0 0 1 1-1.1h1a1 1 0 0 1 1 1.1l-.5 5z"
                />
              </svg>
              <CompositionTitle>
                You don't have any compositions matching this filter!
              </CompositionTitle>
              <Writers>
                <Link to="/register">Register a composition</Link> or change
                your filters to see more compositions.
              </Writers>
            </NoCompositions>
          ))}
        {compositions.works.length === 0 &&
          selectedTags.length === 0 &&
          !loading &&
          !error && (
            <NoCompositions>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="64"
                height="64"
              >
                <path
                  fill="rgba(255,255,255,0.2)"
                  d="M12 2a10 10 0 1 1 0 20 10 10 0 0 1 0-20z"
                />
                <path
                  fill="rgba(255,255,255,0.9)"
                  d="M12 18a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm1-5.9c-.13 1.2-1.88 1.2-2 0l-.5-5a1 1 0 0 1 1-1.1h1a1 1 0 0 1 1 1.1l-.5 5z"
                />
              </svg>
              <CompositionTitle>
                You don't have any compositions!
              </CompositionTitle>
              <Writers>
                <Link to="/register">Register a composition</Link> to start
                collecting your publishing royalties.
              </Writers>
            </NoCompositions>
          )}
      </LeftSide>
      <FilterSort
        tags={tags}
        sortDirection={sortDirection}
        setSortDirection={setSortDirection}
        sortBy={sortBy}
        setSortBy={setSortBy}
        selectedTags={selectedTags}
        toggleSelectTag={(id) => {
          if (selectedTags.includes(id))
            setSelectedTags(selectedTags.filter((i) => i !== id));
          else setSelectedTags([...selectedTags, id]);
        }}
        open={sortOpen}
        close={() => setSortOpen(false)}
      />
    </Container>
  );
};

export default Compositions;
